<template>
   <div
     class="flex items-center bg-opacity-25 "
     :class="['bg-' + color, 'border-' + color, {'mb-4': !open}]"
     
   >
     <div
       class="grow flex justify-start items-center h-12 pl-5 text-sm text-left"
       :class="['text-' + textcolor, 'border-' + color]"
     >
       <div
         v-if="icon"
         class="mr-2 rounded-full flex justify-center items-center"
         :class="'text-' + iconcolor"
       >
         <component :is="icon" class="absolute w-16 ml-6 opacity-30" />
       </div>
       <div class="grow  ml-10 flex items-center justify-between" :class="['text-' + textcolor]">
         {{ label }} 
         <div v-if="badge > 0" class="mx-5 rounded-full h-10 w-10 inline-flex justify-center items-center  bg-drc-marker-incomplete">{{ badge }}</div>
       </div>
       <slot name="add"></slot>
       <div
         class="flex items-center justify-center h-12 w-12 dark:text-white focus:outline-none hover:cursor-pointer border-l-2 border-opacity-20"
         :class="['text-' + textcolor, 'border-' + color]"
         @click="open = !open;"
       >
         <svg-chevron-right
           xmlns="http://www.w3.org/2000/svg"
           class="w-6 h-8 transition-all duration-300 ease-in-out"
           :class="{ 'rotate-90': open }"
         />
       </div>
     </div>
   </div>
   <transition
     enter-active-class="enter-active"
     leave-active-class="leave-active"
     @before-enter="beforeEnter"
     @enter="enter"
     @after-enter="afterEnter"
     @before-leave="beforeLeave"
     @leave="leave"
     @after-leave="afterLeave"
   >
     <div v-if="open" class="bg-white p-5">

       <slot></slot>
     </div>
   </transition>
 </template>
 <script setup>
 import { ref, computed } from "vue";
 import { storeToRefs } from "pinia";
 import { useLayoutStore } from "@/stores/layout";
 
 const layout = useLayoutStore();
 const { contentsize } = storeToRefs(layout);
 
 const open = defineModel('open',  { type: [Boolean,Number], default: false});
 
 const emits = defineEmits(["update:open", "close"]);
 const props = defineProps({
 //   open: {
 //     type: [Boolean, Number],
 //     default: false,
 //   },
   label: {
     type: String,
     default: "",
   },
   color: {
     type: String,
     default: "drc-gruen-300",
   },
   textcolor: {
     type: String,
     default: "black",
   },
   icon: {
     type: String,
   },
   iconcolor: {
     type: String,
     default: "drc-grau-300",
   },
    badge: {
       type: [String, Number],
       default: "",
    },
 
 });
 
 // const open = ref(false);
 
 function beforeEnter(element) {
   requestAnimationFrame(() => {
     if (!element.style.height) {
       element.style.height = "0px";
     }
 
     element.style.display = null;
   });
 }
 
 function enter(element) {
   requestAnimationFrame(() => {
     requestAnimationFrame(() => {
       element.style.height = `${element.scrollHeight}px`;
     });
   });
 }
 
 function afterEnter(element) {
   element.style.height = null;
 }
 
 function beforeLeave(element) {
   requestAnimationFrame(() => {
     if (!element.style.height) {
       element.style.height = `${element.offsetHeight}px`;
     }
   });
 }
 
 function leave(element) {
   requestAnimationFrame(() => {
     requestAnimationFrame(() => {
       element.style.height = "0px";
     });
   });
 }
 
 function afterLeave(element) {
   element.style.height = null;
 }
 </script>
 
 <style scoped lang="scss">
 .enter-active,
 .leave-active {
   overflow: hidden;
   transition: height 0.5s ease;
 }
 </style>
 