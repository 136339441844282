<template>
  <div class="">
    <legend
      class="block mx-5 mb-4 text-left text-drc-gruen-300 desktop:mx-10"
      v-if="legend"
    >
      {{ legend }}
    </legend>
    <div
      class="flex items-start justify-start mx-5 mb-4 desktop:mx-10"
      :class="{ 'flex-row-reverse': boxposition === 'right' }"
    >
      <input
        @change="(event) => $emit('update:value', newValue(event.target.checked))"
        type="checkbox"
        :checked="value"
        :id="id"
        :name="id"
        :disabled="disabled"
        class="absolute w-6 h-6 bg-white opacity-0 appearance-none"
      />
      <div
        class="flex items-center justify-center flex-shrink-0 w-6 h-6 bg-white border border-drc-grau-400"
        :class="{
          'ml-4': boxposition === 'right',
          'mr-4': boxposition === 'left',
          'bg-drc-grau-400': disabled,
        }"
      >
        <svg
          class="hidden w-6 h-6 text-white fill-current"
          version="1.1"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <label
        :for="id"
        class="text-left"
        :class="{
          'text-drc-grau-200': disabled,
          'hover:text-drc-grau-100 cursor-pointer': !disabled,
        }"
        ><span v-html="label"></span>
        <b-tooltip :tooltip="tooltip" />
      </label>
    </div>
    <template v-if="hinweise">
      <p
        v-for="hinweis in hinweise"
        class="mx-5 mb-4 -mt-2 text-xs italic text-left"
        :class="{
          'text-red-500': hinweis.type == 'error',
          'text-drc-gruen-300': hinweis.type == 'warning',
          'text-black': hinweis.type == 'info',
        }"
      >
        {{ hinweis.text }}
      </p>
    </template>
  </div>
</template>

<script>
import BTooltip from "@/components/global/BTooltip.vue";

export default {
  components: {
    BTooltip,
  },
  props: {
    value: {
      type: [Boolean, Number],
    },
    boxposition: {
      type: String,
      default: "left",
    },
    label: {
      type: String,
      default: "",
    },
    legend: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    hinweise: {
      type: [String, Array],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: {
        title: "",
        text: "",
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    newValue(e) {
      switch (e) {
        case "true":
        case "yes":
          return true;

        case "false":
        case "no":
        case null:
          return false;

        default:
          return e;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="checkbox"]:checked + div {
  @apply bg-drc-gruen-300 border-drc-gruen-300;
}

input[type="checkbox"]:checked:disabled + div {
  @apply bg-drc-grau-300 border-drc-grau-300;
}

input[type="checkbox"]:checked + div svg {
  @apply block;
}

input[type="checkbox"]:not(:disabled):hover + div {
  @apply ring-2 ring-drc-orange border-0 cursor-pointer;
}

input[type="checkbox"]:focus + div {
  @apply ring-1 ring-drc-orange border-drc-orange;
}
</style>
