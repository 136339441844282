<template>
   <!-- <div class="grid !grid-cols-tabbar-icon w-full !gap-2 text-sm  text-drc-text" role="tablist"> -->
   <div v-if="contentsize < 1480" class="flex w-full px-5 desktop:px-10">
      <div class="flex flex-col !min-w-50 w-50 !gap-2 text-sm  text-drc-text" role="tablist">
         <div v-for="(itab, i) in itabs_visible" :key="i">
            <a role="itab" :aria-controls="itab.hash" :aria-selected="itab.isActive" :href="itab.hash" @click="selectiTab(itab.hash, $event)">
               <div v-if="itab.isActive" role="presentation" class="h-12 flex items-center  cursor-pointer rounded-sm hover:bg-drc-orange  transition-all transition-ease-in" :class="{ '!bg-drc-white': itab.isActive }">
                  <div class="grow flex justify-start items-center h-12 pl-4 text-sm text-left bg-opacity-20" :class="['bg-' + itab.color, 'border-' + itab.color]">
                     <div v-if="itab.icon" class="mr-2 flex justify-center items-center">
                        <component :is="itab.icon" class="absolute rounded-full w-9 ml-6 text-white " :class="['bg-'+ itab.color]" />
                     </div>
                     <div class="grow font-semibold ml-8 flex items-center justify-between " :class="['text-' + itab.textcolor]">
                        <span :class=" {'border-b-2 border-drc-orange': itab.isActive }">{{ itab.title }} </span>
                        <div v-if="itab.badge" class="mx-2 rounded-full h-8 w-8 inline-flex justify-center  items-center  bg-drc-marker-incomplete">{{ itab.badge }}</div>
                     </div>
                  </div>
               </div>
               <div v-else role="presentation" class="h-12 flex items-center bg-white cursor-pointer rounded-sm hover:bg-drc-orange  transition-all transition-ease-in" :class="{ '!bg-drc-white': itab.isActive }">
                  <div class="grow flex justify-start items-center h-12 pl-4 text-sm text-left " :class="['text-' + itab.textcolor, 'border-' + itab.color]">
                     <div v-if="itab.icon" class="mr-2 flex justify-center items-center">
                        <component v-if="itab.isActive" :is="itab.icon" class="absolute rounded-full w-9 ml-6 text-white " :class="['bg-'+ itab.color]" />
                        <component v-else :is="itab.icon" class="absolute rounded-full w-9 ml-6 text-white bg-drc-grau-300" />
                     </div>
                     <div class="grow font-semibold ml-8 flex items-center justify-between " :class="['text-' + itab.textcolor]">
                        <span :class=" {'border-b-2 border-drc-orange': itab.isActive }">{{ itab.title }} </span>
                        <div v-if="itab.badge" class="mx-2 rounded-full h-8 w-8 inline-flex justify-center  items-center  bg-drc-marker-incomplete">{{ itab.badge }}</div>
                     </div>
                  </div>
               </div>
            </a>
         </div>
         <!-- <template v-for="(itab, i) in itabs_visible" :key="i">
            <div v-if="itab.isActive" class="flex items-end justify-end p-2 flex-grow border-r-4 text-xs uppercase mb-10 border-opacity-20" 
            :class="['text-' + itab.color, 'border-' + itab.color]"></div>
         </template> -->
    
      </div>

      <div class="flex-grow ml-5">
         <keep-alive>
            <slot />
         </keep-alive>
      </div>
   </div>
<template v-else>
    <div class="grid !grid-cols-tabbar-icon w-full !gap-2 text-sm  text-drc-text px-10" role="tablist"> 

         <div v-for="(itab, i) in itabs_visible" :key="i">
            <a role="itab" :aria-controls="itab.hash" :aria-selected="itab.isActive" :href="itab.hash" @click="selectiTab(itab.hash, $event)">
               <div v-if="itab.isActive" role="presentation" class="h-12 flex items-center  cursor-pointer rounded-sm hover:bg-drc-orange  transition-all transition-ease-in" :class="{ '!bg-drc-white': itab.isActive }">
                  <div class="grow flex justify-start items-center h-12 pl-4 text-sm text-left bg-opacity-20" :class="['bg-' + itab.color, 'border-' + itab.color]">
                     <div v-if="itab.icon" class="mr-2 flex justify-center items-center">
                        <component :is="itab.icon" class="absolute rounded-full w-9 ml-6 text-white " :class="['bg-'+ itab.color]" />
                     </div>
                     <div class="grow font-semibold ml-8 flex items-center justify-between " :class="['text-' + itab.textcolor]">
                        <span :class=" {'border-b-2 border-drc-orange': itab.isActive }">{{ itab.title }} </span>
                        <div v-if="itab.badge" class="mx-2 rounded-full h-8 w-8 inline-flex justify-center  items-center  bg-drc-marker-incomplete">{{ itab.badge }}</div>
                     </div>
                  </div>
               </div>
               <div v-else role="presentation" class="h-12 flex items-center bg-drc-grau-400 cursor-pointer rounded-sm hover:bg-drc-orange  transition-all transition-ease-in" :class="{ '!bg-drc-white': itab.isActive }">
                  <div class="grow flex justify-start items-center h-12 pl-4 text-sm text-left " :class="['text-' + itab.textcolor, 'border-' + itab.color]">
                     <div v-if="itab.icon" class="mr-2 flex justify-center items-center">
                        <component v-if="itab.isActive" :is="itab.icon" class="absolute rounded-full w-9 ml-6 text-white " :class="['bg-'+ itab.color]" />
                        <component v-else :is="itab.icon" class="absolute rounded-full w-9 ml-6 text-white bg-drc-grau-300" />
                     </div>
                     <div class="grow font-semibold ml-8 flex items-center justify-between " :class="['text-' + itab.textcolor]">
                        <span :class=" {'border-b-2 border-drc-orange': itab.isActive }">{{ itab.title }} </span>
                        <div v-if="itab.badge" class="mx-2 rounded-full h-8 w-8 inline-flex justify-center  items-center  bg-drc-marker-incomplete">{{ itab.badge }}</div>
                     </div>
                  </div>
               </div>
            </a>
         </div>
         <!-- <template v-for="(itab, i) in itabs_visible" :key="i">
            <div v-if="itab.isActive" class="flex items-end justify-end p-2 flex-grow border-r-4 text-xs uppercase mb-10 border-opacity-20" 
            :class="['text-' + itab.color, 'border-' + itab.color]"></div>
         </template> -->
    
    </div>

      <div class="px-8">
         <keep-alive>
            <slot />
         </keep-alive>
      </div>
</template>

</template>
<script setup>
import expiringStorage from "./expiringStorage";
import { reactive, provide, onMounted, onBeforeUnmount, toRefs, computed } from "vue";
import { storeToRefs } from "pinia";
import { useGlobalStore } from "@/stores/global";
import { useLayoutStore } from "@/stores/layout";

const props = defineProps({
   cacheLifetime: {
      type: Number,
      default: 5,
   },
   options: {
      type: Object,
      required: false,
      default: () => ({
         useUrlFragment: true,
         defaultTabHash: null,
         disableScrollBehavior: false,
      }),
   },
   wrapperClass: {
      type: String,
      default: "itabs-component",
   },
   panelsWrapperClass: {
      type: String,
      default: "itabs-component-panels",
   },
});

const emit = defineEmits(["changed", "clicked"]);

const state = reactive({
   activeTabHash: "",
   activeScrollPos: null,
   lastActiveTabHash: "",
   itabs: [],
});

const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

const storageKey = `vue-itabs-component.cache.${window.location.host}${window.location.pathname}`;

const globalStore = useGlobalStore();
const { breadcrumb, back_link, current_list } = storeToRefs(globalStore);


provide("itabsProvider", state);

provide("addiTab", (itab) => {
   state.itabs.push(itab);
});

provide("updateiTab", (computedId, data) => {
   let itabIndex = state.itabs.findIndex((itab) => itab.computedId === computedId);

   data.isActive = state.itabs[itabIndex].isActive;
   state.itabs[itabIndex] = data;
});

provide("deleteiTab", (computedId) => {
   let itabIndex = state.itabs.findIndex((itab) => itab.computedId === computedId);

   state.itabs.splice(itabIndex, 1);
});

const itabs_visible = computed(() => {
   return state.itabs.filter((itab) => {
      return itab.hidden == false;
   });
});

const selectiTab = (selectediTabHash, event) => {
   if (
      event &&
      (!props.options.useUrlFragment || props.options.disableScrollBehavior)
   ) {
      event.preventDefault();
   }

   const selectediTab = findiTab(selectediTabHash);
   const activeTab = findiTab(state.activeTabHash);

   if (!selectediTab) {
      return;
   }

   if (event && selectediTab.isDisabled) {
      event.preventDefault();
      return;
   }

   if (state.activeTabHash === selectediTab.hash) {
      emit("clicked", { itab: selectediTab });
      breadcrumb.value[2] = selectediTab.header;
      return;
   }

   state.itabs.forEach((itab) => {
      itab.isActive = itab.hash === selectediTab.hash;
   });

   emit("changed", { itab: selectediTab });

   if (activeTab) activeTab.scrollPos = window.top.scrollY;
   state.activeScrollPos = selectediTab.scrollPos;
   state.lastActiveTabHash = state.activeTabHash;
   state.activeTabHash = selectediTab.hash;

   breadcrumb.value[2] = selectediTab.header;

   expiringStorage.set(storageKey, state, props.cacheLifetime);
};

const findiTab = (hash) => {
   return state.itabs.find((itab) => itab.hash === hash);
};

onBeforeUnmount(() => {
   const activeTab = findiTab(state.activeTabHash);
   if (activeTab) activeTab.scrollPos = window.top.scrollY;

   expiringStorage.set(storageKey, state, props.cacheLifetime);

   console.log("onBeforeUnounted TABS");
   const storedState = expiringStorage.get(storageKey);

   console.log(storageKey, storedState);
});

onMounted(() => {
   console.log("Mounted iTabs");
   if (!state.itabs.length) {
      return;
   }
   const storedState = expiringStorage.get(storageKey);

   console.log(storageKey, storedState);
   if (storedState)
      state.itabs.forEach((itab) => {
         var foundiTab = storedState.itabs.find(
            (storeditab) => itab.hash === storeditab.hash
         );
         if (foundiTab && foundiTab.scrollPos) itab.scrollPos = foundiTab.scrollPos;
      });

   window.addEventListener("hashchange", () => selectiTab(window.location.hash));

   if (findiTab(window.location.hash)) {
      selectiTab(window.location.hash);
      return;
   }

   const previousSelectediTabHash = storedState ? storedState.activeTabHash : null;

   if (findiTab(previousSelectediTabHash)) {
      selectiTab(previousSelectediTabHash);
      return;
   }

   if (props.options.defaultTabHash && findiTab("#" + props.options.defaultTabHash)) {
      selectiTab("#" + props.options.defaultTabHash);
      return;
   }

   selectiTab(state.itabs[0].hash);
});

const { activeTabHash, activeScrollPos, lastActiveTabHash, itabs } = toRefs(state);


</script>
<style lang="scss" scoped>
.itab {
   cursor: pointer;
   position: relative;
}

.itab::after {
   content: "";
   position: absolute;
   width: 0px;
   height: 2px;
   left: 50%;
   bottom: -4px;
   @apply bg-drc-gelb;
   transition: all ease-in-out 0.2s;
}

.active.itab::after {
   width: 105%;
   left: 0%;
}

.box {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
</style>