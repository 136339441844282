<template>
   <div class="flex mr-10 mb-3">
      <input @change="(event) => $emit('update:value', newValue(event.target.checked))" type="checkbox" :checked="value" :id="id" :name="id" :disabled="disabled" class="absolute w-6 h-6 bg-white opacity-0 appearance-none" />
      <div class="flex items-center justify-center w-6 h-6 mr-4 bg-white border border-drc-grau-400" :class="{ 'bg-drc-grau-400': disabled }">
         <svg class="hidden w-6 h-6 text-white fill-current" version="1.1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
         </svg>
      </div>
      <label :for="id" class="text-left" :class="{ 'text-drc-grau-300': disabled, 'hover:text-drc-grau-100 cursor-pointer': !disabled }">{{ label }} 
      </label>
   </div>
</template>

<script>

export default {
   components: {
   },
   props: {
      value: {
         type: Boolean,
      },
      id: {
         type: String,
         default: '',
      },
      disabled: {
         type: Boolean,
         default: false,
      },
      hinweise: {
         type: [String, Array],
         default: '',
      },
      label: {
         type: String,
         default: '',
      },
      inline: {
         type: Boolean,
         default: false,
      },
      horizontal: {
         type: Boolean,
         default: false,
      },
      disabled: {
         type: Boolean,
         default: false,
      },
      tooltip: {
         type: Object,
         default: {
            title: '',
            text: ''
         }
      },
   },
   data() {
      return {}
   },
   methods: {
      newValue(e) {
         switch (e) {
            case "true":
            case "yes": return true;

            case "false":
            case "no":
            case null: return false;

            default: return e;
         }
      }
   }
}

</script>

<style lang="scss" scoped>
input[type='checkbox']:checked+div {
   @apply bg-drc-gruen-300 border-drc-gruen-300;
}

input[type='checkbox']:checked:disabled+div {
   @apply bg-drc-grau-300 border-drc-grau-300;
}

input[type='checkbox']:checked+div svg {
   @apply block;
}

input[type='checkbox']:not(:disabled):hover+div {
   @apply ring-2 ring-drc-orange border-0 cursor-pointer;
}

input[type='checkbox']:focus+div {
   @apply ring-1 ring-drc-orange border-drc-orange;
}
</style>
