<template>
   <!-- <div class="mx-5 mt-5 mb-5 text-sm text-left uppercase border-b text-drc-grau-300 border-drc-grau-300 desktop:mx-10 sticky top-44 z-9 bg-drc-grau-600 h-10 flex items-center"> -->
   <div class="mx-5 my-5 text-sm text-left uppercase border-b text-drc-grau-300 border-drc-grau-300 desktop:mx-10">
     {{ label }}
   </div>
</template>

<script>
export default {
   data() {
      return {};
   },
   props: {
      label: {
         type: String,
         default: ''
      },
   },
};
</script>

<style lang="scss">
</style>
