<template>
   <div class="px-2">
      <div class="flex justify-start flex-wrap bg-drc-braun-mittel bg-opacity-0 pt-2">
         <b-button-setting label="Alle öffnen" bgcolor="drc-braun-mittel" icon="svg-plus" @buttonclick="openAll()" />
         <b-button-setting label="Alle schliessen" bgcolor="drc-braun-mittel" icon="svg-minus" @buttonclick="closeAll()" />
         <b-button-setting label="Alle Titel" bgcolor="drc-braun-mittel" icon="svg-checkbox-checked" @buttonclick="setAll()" />
         <b-button-setting label="Alle Titel" bgcolor="drc-braun-mittel" icon="svg-checkbox-unchecked" @buttonclick="unsetAll()" />
      </div>
   </div>
   <div class="">
   <div class="flex flex-wrap">
      <div class="flex-1">
         <b-expand-bar-suchmodul-checkboxes :daten="titels" titel="Titel" :open="expands['titel']"  color="drc-braun-mittel"/>
      </div>
   </div>
   </div>
</template>
<script setup>
import { ref, computed } from "vue";
import _ from "lodash";
import axios from "axios";
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";


import BCheckbox from "@/components/global/checkboxes/BCheckbox.vue";
import BCheckboxClear from "@/components/global/checkboxes/BCheckboxClear.vue";
import BInput from "@/components/global/inputs/BInput.vue";
import BSelectMulti from "@/components/global/select/BSelectMulti.vue";
import BNumber from "@/components/global/inputs/BNumber.vue";
import BRadiobuttons from "@/components/global/radios/BRadiobuttons.vue";
import BHinweisfeld from "@/components/global/BHinweisfeld.vue";
import BInfopanel from "@/components/global/panels/BInfopanel.vue";
import BDatepicker3 from "@/components/global/datepickers/BDatepicker3.vue";
import BThementrenner from "@/components/global/BThementrenner.vue";
import BLabel from "@/components/global/BLabel.vue";
import BRadiobutton from "@/components/global/radios/BRadiobutton.vue";
import BButtonSetting from "@/components/global/buttons/BButtonSetting.vue";

import BExpandBarSuchmodulCheckboxes from "@/components/global/bars/BExpandBarSuchmodulCheckboxes.vue";
import BExpandBarSuchmodul from "@/components/global/bars/BExpandBarSuchmodul.vue";

const { options } = useGlobalStore();
const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

const {
   titels,
   expands
} = storeToRefs(sucheHundeStore);

function openAll() {
   expands.value['titel'] = true;
}

function closeAll() {
   expands.value['titel'] = false;
}

function setAll() {
   for (const [key, value] of Object.entries(titels.value)) {
         value.checked = true;
   }
}

function unsetAll() {
   for (const [key, value] of Object.entries(titels.value)) {
         value.checked = false;
   }
}


</script>
<style></style>
