<template>
   <section v-if="isActive" :id="computedId" ref="itab" :aria-hidden="!isActive" :class="panelClass" role="itabpanel">
      <div class="pb-10" >
         
      <slot />
      </div>
   </section>
</template>
<script>
import {
   inject,
   watch,
   ref,
   onBeforeMount,
   onBeforeUnmount,
   onMounted,
   onUpdated,
   onActivated,
} from "vue";
import { useGlobalStore } from "@/stores/global";
import { icon } from "leaflet";

export default {
   name: "TabIcon",
   props: {
      panelClass: {
         type: String,
         default: "itabs-component-panel",
      },
      id: {
         type: [String, Number],
         default: null,
      },
      title: {
         type: String,
         required: true,
      },
      prefix: {
         type: String,
         default: "",
      },
      suffix: {
         type: String,
         default: "",
      },
      isDisabled: {
         type: Boolean,
         default: false,
      },
      special: {
         type: Boolean,
         default: false,
      },
      hidden: {
         type: Boolean,
         default: false,
      },
      delete: {
         type: Boolean,
         default: false,
      },
      deleteable: {
         type: Boolean,
         default: false,
      },
      label: {
         type: String,
         default: "",
      },
      color: {
         type: String,
         default: "drc-gruen-300",
      },
      textcolor: {
         type: String,
         default: "black",
      },
      icon: {
         type: String,
      },
      iconcolor: {
         type: String,
         default: "drc-grau-300",
      },
      badge: {
         type: [String, Number],
         default: "",
      },
   },
   setup(props) {
      const scrollPos = ref();
      const isActive = ref(false);
      const hidden = ref(false);
      const itabsProvider = inject("itabsProvider");
      const addiTab = inject("addiTab");
      const updateiTab = inject("updateiTab");
      const deleteiTab = inject("deleteiTab");
      const header = props.prefix + props.title + props.suffix;
      const computedId = props.id ? props.id : props.title.toLowerCase().replace(/ /g, "-");
      const hash = "#" + (!props.isDisabled ? computedId : "");
      watch(
         () => itabsProvider.activeTabHash,
         () => {
            isActive.value = hash === itabsProvider.activeTabHash;
         }
      );
      watch(
         () => Object.assign({}, props),
         () => {
            updateiTab(computedId, {
               title: props.title,
               deleteable: props.deleteable,
               header: props.prefix + props.title + props.suffix,
               isDisabled: props.isDisabled,
               hidden: props.hidden,
               hash: hash,
               index: itabsProvider.itabs.length,
               computedId: computedId,
               scrollPos: 0,
               icon: props.icon,
               iconcolor: props.iconcolor,
               color: props.color,
               textcolor: props.textcolor,
               badge: props.badge,
            });
         }
      );

      onBeforeMount(() => {
         addiTab({
            title: props.title,
            header: header,
            deleteable: props.deleteable,
            isDisabled: props.isDisabled,
            hidden: props.hidden,
            hash: hash,
            index: itabsProvider.itabs.length,
            computedId: computedId,
            scrollPos: 0,
            icon: props.icon,
            iconcolor: props.iconcolor,
            color: props.color,
            textcolor: props.textcolor,
            badge: props.badge,
         });
      });
      onBeforeUnmount(() => {
         deleteiTab(computedId);
      });
      return {
         header,
         computedId,
         hash,
         isActive,
         scrollPos,
      };
   },
};
</script>