<template>
   <div :class="wrapperClass">
      <div class="grid !grid-cols-tabbar-suche w-full !gap-1 bg-white" role="tablist">

         <div v-for="(tab, i) in tabs_visible" :key="i" >
            <a role="tab" :aria-controls="tab.hash" :aria-selected="tab.isActive" :href="tab.hash" @click="selectTab(tab.hash, $event)">
               <div class='flex items-center px-3 flex-1 h-10 text-sm text-black cursor-pointer  bg-drc-grau-400 hover:bg-drc-orange transition-all transition-ease-in ' :class="{
                  '!bg-drc-grau-600 !text-drc-gruen-300 font-medium ': tab.isActive,
                  '!bg-drc-grau-400 !cursor-not-allowed !text-drc-grau-200 italic': tab.isDisabled }" role="presentation">
                  <span class="tab leading-tight" :class="{ 'active': tab.isActive }">{{ tab.header }}</span>
                  <button v-if="tab.deleteable" class="ml-5 w-10 h-10 inline-flex justify-center items-center" @click.prevent="$emit('close', i)">
                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                     </svg>
                  </button>
               </div>
            </a>
         </div>
      </div> <div class=" py-10 bg-drc-grau-600 md:px-10 2xl:px-20">
         <keep-alive>
            <slot />
         </keep-alive>
      </div>
   </div>
</template>
 
<script>
import expiringStorage from './expiringStorage';
import { reactive, provide, onMounted, toRefs, computed } from 'vue';
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '@/stores/global'

export default {
   props: {
      cacheLifetime: {
         type: Number,
         default: 5,
      },
      options: {
         type: Object,
         required: false,
         default: () => ({
            useUrlFragment: true,
            defaultTabHash: null,
            disableScrollBehavior: false,
         }),
      },
      wrapperClass: {
         type: String,
         default: 'tabs-component'
      },
      panelsWrapperClass: {
         type: String,
         default: 'tabs-component-panels'
      },
      navClass: {
         type: String,
         default: 'tabs-component-tabs'
      },
      navItemClass: {
         type: String,
         default: 'tabs-component-tab'
      },
      navItemDisabledClass: {
         type: String,
         default: 'is-disabled'
      },
      navItemActiveClass: {
         type: String,
         default: 'is-active'
      },
      navItemLinkClass: {
         type: String,
         default: 'tabs-component-tab-a'
      },
      navItemLinkActiveClass: {
         type: String,
         default: 'is-active'
      },
      navItemLinkDisabledClass: {
         type: String,
         default: 'is-disabled'
      },
   },

   emits: ['changed', 'clicked', 'close'],

   setup(props, context) {
      const state = reactive({
         activeTabHash: '',
         lastActiveTabHash: '',
         tabs: []
      })

      const globalStore = useGlobalStore()
   const { breadcrumb, back_link, current_list } = storeToRefs(globalStore)


      provide('tabsProvider', state)

      provide('addTab', (tab) => {
         state.tabs.push(tab)
      })

      provide('updateTab', (computedId, data) => {
         let tabIndex = state.tabs.findIndex((tab) => tab.computedId === computedId)

         data.isActive = state.tabs[tabIndex].isActive;

         state.tabs[tabIndex] = data
      })

      provide('deleteTab', (computedId) => {
         let tabIndex = state.tabs.findIndex((tab) => tab.computedId === computedId)
         state.tabs.splice(tabIndex, 1)
      })

      const tabs_visible = computed(() => {
         return state.tabs.filter((tab) => {
            return tab.hidden == false
         })
      })

      function deleteTab(computedId) {
         let tabIndex = state.tabs.findIndex((tab) => tab.computedId === computedId)
         state.tabs.splice(tabIndex, 1)

      }

      const selectTab = (selectedTabHash, event) => {
         if (event && (!props.options.useUrlFragment || props.options.disableScrollBehavior)) {
            event.preventDefault();
         }
      
         const selectedTab = findTab(selectedTabHash);
   
         if (!selectedTab) {
            return;
         }

         if (event && selectedTab.isDisabled) {
            event.preventDefault();
            return;
         }

         if (state.lastActiveTabHash === selectedTab.hash) {
            context.emit('clicked', { tab: selectedTab });
            breadcrumb.value[1] = selectedTab.header
            return;
         }

         state.tabs.forEach(tab => {
            tab.isActive = (tab.hash === selectedTab.hash);
            breadcrumb.value[1] = (tab.hash === selectedTab.hash) ? tab.header: ''
         });

         context.emit('changed', { tab: selectedTab });

         state.lastActiveTabHash = state.activeTabHash = selectedTab.hash;
         
       //   breadcrumb.value[1] = selectedTab.header

         const storageKey = `vue-tabs-component.cache.${window.location.host}${window.location.pathname}`;
         expiringStorage.set(storageKey, selectedTab.hash, props.cacheLifetime);
      }


      const findTab = (hash) => {
         return state.tabs.find(tab => tab.hash === hash);
      }

      onMounted(() => {
         if (!state.tabs.length) {
            return;
         }

         window.addEventListener('hashchange', () => selectTab(window.location.hash));

         if (findTab(window.location.hash)) {
            selectTab(window.location.hash);
            return;
         }

         const storageKey = `vue-tabs-component.cache.${window.location.host}${window.location.pathname}`;
         const previousSelectedTabHash = expiringStorage.get(storageKey);

         if (findTab(previousSelectedTabHash)) {
            selectTab(previousSelectedTabHash);
            return;
         }

         if (props.options.defaultTabHash && findTab("#" + props.options.defaultTabHash)) {
            selectTab("#" + props.options.defaultTabHash);
            return;
         }

         selectTab(state.tabs[0].hash);
      })

      return {
         ...toRefs(state),
         selectTab,
         deleteTab,
         findTab,
         tabs_visible,
      }
   }
}
</script>

<style lang="scss" scoped>
.tab {
   cursor: pointer;
   position: relative;
}

.tab::after {
   content: '';
   position: absolute;
   width: 0px;
   height: 2px;
   left: 50%;
   bottom: -4px;
   @apply bg-drc-gelb;
   transition: all ease-in-out .2s;
}

.active.tab::after {
   width: 105%;
   left: 0%;
}
</style>