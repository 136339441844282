<template>
  <div
    class="flex items-start justify-start flex-col mt-5 flex-wrap"
    :class="{ 'desktop:flex-row': horizontal }"
  >
    <div class="w-full" :class="{ 'desktop:w-90': horizontal }">
      <legend
        class="block mx-5 mb-4 text-left text-drc-gruen-300 desktop:mx-10"
        v-if="legend"
      >
        {{ legend }}<b-tooltip :tooltip="tooltip" />
      </legend>
    </div>
    <div>
      <div
        class="flex items-start justify-start mx-5 mb-2 desktop:mx-10 flex-wrap"
        :class="{ 'flex-col': !inline }"
      >
        <div
          class="flex items-start justify-start mr-10 mb-3"
          :class="{
            'desktop:w-40': small,
            'desktop:w-56': middle,
            'desktop:w-60': large,
          }"
          v-for="(option, index) in options"
          :key="option.name"
        >
          <input
            @input="handleInput($event)"
            @change="emits('change', $event.target.value)"
            type="radio"
            :value="option.id"
            :disabled="option.disabled || disabled"
            :required="required"
            :checked="option.id == value"
            :id="id + index"
            :name="id"
            class="absolute w-8 h-8 outline-none opacity-0 appearance-none cursor-pointer"
          />
          <div
            @click.prevent="handleClick(value)"
            class="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-4 text-center text-white bg-white transition-all duration-150 ease-in-out border rounded-full outline-none cursor-pointer border-drc-grau-300 focus:ring-1"
          >
            <svg
              class="absolute hidden w-6 h-6 fill-current text-drc-gruen-300"
              version="1.1"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle className="" cx="15" cy="15" r="10" />
            </svg>
          </div>
          <label
            :for="id + index"
            class="text-left"
            :class="{
              'text-drc-grau-200': option.disabled || disabled,
              'hover:text-drc-grau-100 cursor-pointer': !option.disabled && !disabled,
            }"
            >{{ option.name }}</label
          >
        </div>
      </div>
      <template v-if="text">
        <p class="mb-4 -mt-2 text-left">{{ text }}</p>
      </template>
      <template v-if="hinweise">
        <p
          v-for="hinweis in hinweise"
          class="mb-4 -mt-2 text-xs italic text-left"
          :class="{
            'text-red-500': hinweis.type == 'error',
            'text-drc-gruen-300': hinweis.type == 'warning',
            'text-black': hinweis.type == 'info',
          }"
        >
          {{ hinweis.text }}
        </p>
      </template>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import BTooltip from "@/components/global/BTooltip.vue";

const emits = defineEmits(["update:value", "change"]);

const props = defineProps({
  legend: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    required: true,
    default: "",
  },
  hinweise: {
    type: [String, Array],
    default: "",
  },
  value: {
    type: [String, Number, Boolean, Object],
    default: "",
  },
  options: {
    type: [Array],
    required: true,
  },
  text: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: Object,
    default: {
      title: "",
      text: "",
    },
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  middle: {
    type: Boolean,
    default: false,
  },
  large: {
    type: Boolean,
    default: false,
  },
});



function newValue(e) {
  switch (e) {
    case "true":
    case "yes":
      return true;

    case "false":
    case "no":
    case null:
      return false;

    default:
      return e;
  }
}

function handleInput(e) {
  emits("update:value", newValue(e.target.value));
}

function handleClick(e) {
  if (e == props.value && props.clearable) emits("update:value", newValue());
}

</script>

<style lang="scss" scoped>
input:checked + div svg {
  @apply block;
}

input[type="radio"]:not(:disabled):hover + div {
  @apply ring-1 ring-drc-orange border-drc-orange;
}

input[type="radio"]:disabled {
  @apply cursor-default;

  & + div {
    @apply border-drc-grau-300;

    svg {
      @apply text-drc-grau-300;
    }
  }
}

input[type="radio"]:focus + div {
  @apply ring-1 ring-drc-orange border-drc-orange;
}
</style>
