<template>
  <div class="flex flex-col justify-start mx-5 desktop:mx-10">
    <label v-if="label" class="block font-medium text-left text-drc-gruen-300" :id="id">
      {{ label }}
      <b-tooltip :tooltip="tooltip" />
    </label>
    <input
      class="w-full h-8 text-black border rounded px-3 py-2 my-4 min-w-75 border-drc-grau-400 focus:border-none focus:bg-white focus:ring focus:ring-drc-gelb"
      :class="{
        'border-2 !border-drc-gruen-300 bg-drc-gruen-600 bg-opacity-10': special,
        'bg-drc-grau-500 text-black': disabled,
        '!mb-4 !mt-0': !label,
      }"
      :type="type"
      :value="value"
      @change="$emit('update:value', $event.target.value)"
      @blur="$emit('blur')"
      @keyup.enter="$emit('enter'); $emit('blur')"
      :placeholder="placeholder"
      :disabled="disabled"
    />
    <template v-if="hinweise">
      <p
        v-for="hinweis in hinweise"
        class="mb-4 -mt-2 text-xs italic text-left ml-3"
        :class="{
          'text-red-500': hinweis.type == 'error',
          'text-drc-gruen-300': hinweis.type == 'warning',
          'text-black': hinweis.type == 'info',
        }"
      >
        {{ hinweis.text }}
      </p>
    </template>
  </div>
</template>
<script>
import BTooltip from "@/components/global/BTooltip.vue";

export default {
  components: { BTooltip },
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number, Array],
      default: "",
    },
    hinweise: {
      type: [String, Array],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    special: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: {
        title: "",
        text: "",
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>
