<template>
            <div class="flex flex-wrap">
               <div class="flex-1">
                  <b-input label="Hundename" type="text" v-model:value="filterHunde.name" @enter="emits('suche')" />
               </div>
               <div class="flex-1">
                  <b-input label="Zuchtbuchnummer" type="text" v-model:value="filterHunde.zuchtbuchnummer" />
               </div>
            </div>
</template>
<script setup>
import { useLayoutStore } from "@/stores/layout";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { storeToRefs } from "pinia";

import BInput from "@/components/global/inputs/BInput.vue";
import BButton from "@/components/global/buttons/BButton.vue";
import BButtonSolo from "@/components/global/buttons/BButtonSolo.vue";

import BExpandBarSucheNeutral from "@/components/global/bars/BExpandBarSucheNeutral.vue";

const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

// sucheStore.$reset()
const {
   filterHunde,
   expands
} = storeToRefs(sucheHundeStore);

const emits = defineEmits(["suche" ]);


</script>
<style></style>
