<template>
   <div class="mx-2 my-2 flex items-center  group hover:bg-drc-orange transition-colors duration-150 bg-opacity-10"
   :class="[ 'bg-'+color, { '!bg-opacity-50' : gentest.open || selected }]">
      <div class="p-2 grow flex justify-start items-center pl-4 text-sm text-left  cursor-pointer " @click="gentest.open = !gentest.open;">
            <svg-plus v-if="!gentest.open" class="w-5 h-5 text-drc-grau-100" />
            <svg-minus v-else class="w-5 h-5 text-white" />
      
         <div class="text-drc-grau-100 w-20 font-semibold ml-4 group-hover:text-white leading-5 flex" :class="{ 'text-white' : (gentest['open'] || selected) }"> {{ gentest['short'] }} </div>
         <div class="text-drc-grau-100 grow font-semibold ml-4 group-hover:text-white leading-5 flex" :class="{ 'text-white' : (gentest['open'] || selected) }">
            <div>{{ gentest['name'] }}<br /><span class="text-xs text-drc-grau-300 group-hover:text-white" :class="{ 'text-white' : (gentest['open'] || selected) }">{{ gentest['titel'] }}</span></div>
            <div v-if="selected" class="mx-5 rounded-full h-8 w-8 inline-flex justify-center items-center  bg-drc-marker-incomplete">{{ selected }}</div>
         </div>
      </div>
   </div>
   <transition enter-active-class="enter-active" leave-active-class="leave-active" @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @before-leave="beforeLeave" @leave="leave" @after-leave="afterLeave">
      <div v-if="gentest['open']" class="bg-white rounded mx-2">
         <div class="pt-4 pb-2 mb-5 flex flex-wrap"
         :class="{ 'flex-col' : 0}">
            <b-checkbox :id="gentest['id']+`_frei`" label="frei" v-model:value="gentest['frei']" inline @change="createSelectedString()" />
            <b-checkbox :id="gentest['id']+`_traeger`" label="Träger" v-model:value="gentest['traeger']" inline @change="createSelectedString()" />
            <b-checkbox :id="gentest['id']+`_betroffen`" label="betroffen" v-model:value="gentest['betroffen']" inline @change="createSelectedString()" />
            <b-checkbox :id="gentest['id']+`_erbgangfrei`" label="über Erbgang frei" v-model:value="gentest['erbgangfrei']" inline @change="createSelectedString()" /> 
          
         </div>
      </div>
   </transition>
</template>
<script setup>
import { computed, ref } from 'vue';

import BCheckbox from '@/components/global/checkboxes/BCheckbox.vue'
import BLabel from '@/components/global/BLabel.vue'
import BRadiobutton from '@/components/global/radios/BRadiobutton.vue'

const gentest = defineModel('gentest')

const props = defineProps({

   titel: {
      type: String,
      default: '',
   },
   id: {
      type: String,
      default: '',
   },
   color: {
      type: String,
      default: 'drc-gruen-100',
   },
   textcolor: {
      type: String,
      default: 'white',
   },
})

const selected = computed(() => {
   let summe = 0
   if (gentest.value['frei']) summe++;
   if (gentest.value['traeger']) summe++;
   if (gentest.value['betroffen']) summe++;
   if (gentest.value['erbgangfrei']) summe++;
   return summe;
})

const selected_string = computed(() => {
   let summe = ''
   if (gentest.value['frei']) summe += 'frei, ';
   if (gentest.value['traeger']) summe += 'Träger, ';
   if (gentest.value['betroffen']) summe += 'betroffen, ';
   if (gentest.value['erbgangfrei']) summe += 'über Erbgang frei, ';
   if (summe.length > 0) summe = gentest.value['short'] + ': ' + summe.slice(0, -2);
   return summe;
})

function createSelectedString() {
   gentest.value['selected_string'] = selected_string.value;
}
// const open = ref(false);

function beforeEnter(element) {
   requestAnimationFrame(() => {
      if (!element.style.height) {
         element.style.height = '0px'
      }

      element.style.display = null
   })
}

function enter(element) {
   requestAnimationFrame(() => {
      requestAnimationFrame(() => {
         element.style.height = `${element.scrollHeight}px`
      })
   })
}

function afterEnter(element) {
   element.style.height = null
}

function beforeLeave(element) {
   requestAnimationFrame(() => {
      if (!element.style.height) {
         element.style.height = `${element.offsetHeight}px`
      }
   })
}

function leave(element) {
   requestAnimationFrame(() => {
      requestAnimationFrame(() => {
         element.style.height = '0px'
      })
   })
}

function afterLeave(element) {
   element.style.height = null
}
</script>
<style scoped lang="scss">
.enter-active,
.leave-active {
   overflow: hidden;
   transition: height 0.5s ease;
}
</style>
