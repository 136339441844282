<template>
   <div class="flex flex-col items-center justify-start w-full pt-2">
      <div class="flex flex-col items-center justify-start w-full text-xs" :class="{ '': details[id] }">
         <div class="flex flex-row items-center justify-start w-full h-10 bg-drc-grau-400" :class="{ '!bg-drc-ruede-mittel': (hund.geschlecht.id == 2 && hund.id > 0), '!bg-drc-huendin-mittel': (hund.geschlecht.id == 1 && hund.id > 0) }">
            <div class="inline-flex items-center justify-center w-10 h-10 px-2 font-medium text-white uppercase border-r"> {{ hund.gen }} </div>
            <div class="inline-flex items-center justify-center w-10 h-10 font-medium text-white uppercase border-r">
               <svg-vater v-if="hund.geschlecht.id == 2" class="w-6 h-6 border-none fill-current" viewBox="0 0 32 32" fill="currentColor" />
               <svg-mutter v-if="hund.geschlecht.id == 1" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 border-none fill-current" viewBox="0 0 32 32" fill="currentColor" />
            </div>
            <div class="flex items-center justify-center w-10 h-10 text-white grow dark:text-white focus:outline-none hover:cursor-pointer" @click="open[id] = !open[id]">
               <svg-chevron-down v-if="hund.generation != 4" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white transition-all duration-300 ease-in-out" :class="{ 'rotate-180': open[id] }" />
            </div>
            <div v-if="hund.id > 0" class="flex items-center justify-center w-10 h-10 text-white border-l dark:text-white focus:outline-none bg-drc-grau-400 hover:cursor-pointer" :class="{ '!bg-drc-ruede-dunkel': (hund.geschlecht.id == 2 && hund.id > 0), '!bg-drc-huendin-dunkel': (hund.geschlecht.id == 1 && hund.id > 0) }">
               <svg-lupe xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white transition-all duration-300 ease-in-out" />
            </div>
            <div v-if="hund.id > 0" class="flex items-center justify-center w-10 h-10 text-white border-l dark:text-white focus:outline-none bg-drc-grau-400 hover:cursor-pointer" :class="{ '!bg-drc-ruede-dunkel': (hund.geschlecht.id == 2 && hund.id > 0), '!bg-drc-huendin-dunkel': (hund.geschlecht.id == 1 && hund.id > 0) }" @click="$emit('reload', hund.id)">
               <svg-ahnentafel xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-white transition-all duration-300 ease-in-out" />
            </div>
         </div>
         <div class="flex flex-row items-center justify-start w-full h-10 bg-drc-grau-500" :class="{ '!bg-drc-ruede-hell': (hund.geschlecht.id == 2 && hund.id > 0), '!bg-drc-huendin-hell': (hund.geschlecht.id == 1 && hund.id > 0) }">
            <div class="inline-flex items-center justify-start h-10 px-2 text-xs font-medium text-black uppercase truncate grow"> {{ hund.name }} </div>
            <div v-if="hund.id > 0" class="flex items-center justify-center w-10 h-10 text-white border-l dark:text-white focus:outline-none">
               <button @click.stop="details[id] = !details[id]" class="inline-flex items-center justify-center w-6 h-6 text-white transition-colors duration-150 rounded-full bg-drc-grau-300 focus:outline-none hover:cursor-pointer">
                  <svg-plus v-if="!details[id]" class="w-4 h-4 " />
                  <svg v-if="details[id]" class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                     <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                  </svg>
               </button>
            </div>
         </div>
      </div>
      <transition enter-active-class="enter-active" leave-active-class="leave-active" @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @before-leave="beforeLeave" @leave="leave" @after-leave="afterLeave">
         <div class="w-full text-xs text-left border-t-2 border-white shadow item-start justify_start details" v-if="details[id]">
            <div class="inline-flex items-center p-2 text-black min-h-10 bg-drc-grau-400" :class="{ '!bg-drc-ruede-heller': (hund.geschlecht.id == 2 && hund.id > 0), '!bg-drc-huendin-heller': (hund.geschlecht.id == 1 && hund.id > 0) }"> {{ hund.zuchtbuchnummer }} </div>
            <div class="inline-flex items-center p-2 text-black min-h-10 bg-drc-grau-500" :class="{ '!bg-drc-ruede-hell': (hund.geschlecht.id == 2 && hund.id > 0), '!bg-drc-huendin-hell': (hund.geschlecht.id == 1 && hund.id > 0) }"> {{ hund.wurfdatum }} </div>
            <div class="inline-flex items-center p-2 text-black min-h-10 bg-drc-grau-400" :class="{ '!bg-drc-ruede-heller': (hund.geschlecht.id == 2 && hund.id > 0), '!bg-drc-huendin-heller': (hund.geschlecht.id == 1 && hund.id > 0) }"> {{ hund.titel_drc }} </div>
            <div class="inline-flex items-center p-2 text-black min-h-10 bg-drc-grau-500" :class="{ '!bg-drc-ruede-hell': (hund.geschlecht.id == 2 && hund.id > 0), '!bg-drc-huendin-hell': (hund.geschlecht.id == 1 && hund.id > 0) }"> {{ hund.ed_links }} {{ hund.ed_rechts }} </div>
            <div class="inline-flex items-center p-2 text-black min-h-10 bg-drc-grau-400" :class="{ '!bg-drc-ruede-heller': (hund.geschlecht.id == 2 && hund.id > 0), '!bg-drc-huendin-heller': (hund.geschlecht.id == 1 && hund.id > 0) }"> {{ hund.hd_links }} {{ hund.hd_rechts }} </div>
         </div>
      </transition>
      <transition enter-active-class="enter-active" leave-active-class="leave-active" @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @before-leave="beforeLeave" @leave="leave" @after-leave="afterLeave">
         <div v-if="open[id] && hund.generation < 4" class="flex flex-col items-center w-full" :class="{ 'text-black': hund.generation == 0, 'text-drc-grau-100': hund.generation == 1, 'text-drc-grau-200': hund.generation == 2, 'text-drc-grau-300': hund.generation == 3, 'text-drc-grau-400': hund.generation == 4 }">
            <div class="flex justify-center w-full h-3">
               <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="h-3 fill-current" viewBox="0 0 31 31" fill="000">
                  <line x1="16" y1="0" x2="16" y2="31" vector-effect="non-scaling-stroke" stroke-width="4" stroke="currentColor" />
               </svg>
            </div>
            <div class="flex justify-center w-1/2 h-3">
               <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="w-full h-6 fill-current" viewBox="0 0 31 31" fill="000">
                  <line x1="3" y1="0" x2="3" y2="31" vector-effect="non-scaling-stroke" stroke-width="4" stroke="currentColor" />
                  <line x1="3" y1="3" x2="28" y2="3" vector-effect="non-scaling-stroke" stroke-width="5" stroke="currentColor" />
                  <line x1="28" y1="0" x2="28" y2="31" vector-effect="non-scaling-stroke" stroke-width="4" stroke="currentColor" />
               </svg>
            </div>
            <div class="flex w-full">
               <div class="flex w-full">
                  <slot name="vater"></slot>
               </div>
               <div class="flex w-full pl-5">
                  <slot name="mutter"></slot>
               </div>
            </div>
         </div>
      </transition>
   </div>
</template>

<script>
import { ls_open, ls_details } from './localstore'

export default {
   components: {},
   props: {
      hund: {
         type: [String, Array, Object],
         default: '',
      },
      id: {
         type: [Number, String],
         default: 0,
      }
   },
   data() {
      return {

      }
   },
   setup() {

      const open = ls_open
      const details = ls_details

      return { open, details }
   },

   computed: {
      verwandschaft() {
         switch (this.hund.generation) {
            case 1:
               return this.hund.geschlecht.id == 2 ? 'Vater' : 'Mutter'
               break
            case 2:
               return this.hund.geschlecht.id == 2 ? 'Großvater' : 'Großmutter'
               break
            case 3:
               return this.hund.geschlecht.id == 2 ? 'Urgroßvater' : 'Urgroßmutter'
               break
            case 4:
               return this.hund.geschlecht.id == 2 ? 'Ururgroßvater' : 'Ururgroßmutter'
               break
         }
      },
      next_generation() {
         switch (this.hund.generation) {
            case 1:
               return 'Großeltern'
               break
            case 2:
               return 'Urgroßeltern'
               break
            case 3:
               return 'Ururgroßeltern'
               break
            default:
               return ''
               break
         }
      },
   },
   methods: {
      beforeEnter(element) {
         requestAnimationFrame(() => {
            if (!element.style.height) {
               element.style.height = '0px'
            }

            element.style.display = null
         })
      },

      /**
       * @param {HTMLElement} element
       */
      enter(element) {
         requestAnimationFrame(() => {
            requestAnimationFrame(() => {
               element.style.height = `${element.scrollHeight}px`
            })
         })
      },
      /**
       * @param {HTMLElement} element
       */
      afterEnter(element) {
         element.style.height = null
      },
      /**
       * @param {HTMLElement} element
       */
      beforeLeave(element) {
         requestAnimationFrame(() => {
            if (!element.style.height) {
               element.style.height = `${element.offsetHeight}px`
            }
         })
      },
      /**
       * @param {HTMLElement} element
       */
      leave(element) {
         requestAnimationFrame(() => {
            requestAnimationFrame(() => {
               element.style.height = '0px'
            })
         })
      },
      /**
       * @param {HTMLElement} element
       */
      afterLeave(element) {
         element.style.height = null
      }
   }
}
</script>

<style scoped lang="scss">
.enter-active,
.leave-active {
   overflow: hidden;
   transition: height 1s ease;
}

.linkerabstand {
   padding-left: 8rem;
}

.details {
   display: flex;
   flex-wrap: wrap;
   --multiplier: calc(80rem - 100%);
}

.details>* {
   min-width: 20%;
   max-width: 100%;
   flex-grow: 1;
   flex-basis: calc(var(--multiplier) * 999);
}
</style>
