<template>
   <div class="flex flex-col items-center justify-start w-full bg-white">
      <div class="fixed flex items-center justify-start w-full py-2 text-xs shadow-lg smartphone:py-1 z-100000" :class="{ 'bg-drc-ruede-mittel': hund.geschlecht.id == 2, '!bg-drc-huendin-mittel': hund.geschlecht.id == 1, '': detail }">
         <button v-if="history.length - 1" class="flex items-center justify-center w-16 h-16 text-white transition-colors duration-150 border-r hover:cursor-pointer focus:outline-none" @click="$emit('reload_prev')">
            <svg-chevron-left xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 transition-all duration-300 ease-in-out" />
         </button>
         <div class="flex flex-col items-start justify-start px-4 text-left " :class="{ 'bg-drc-ruede-mittel': hund.geschlecht.id == 2, '!bg-drc-huendin-mittel': hund.geschlecht.id == 1 }">
            <div class="inline-flex items-start justify-start h-4 text-xs font-medium text-white smartphone:h-5"> DRC-Ahnentafel </div>
            <div class="inline-flex items-center justify-start h-8 text-sm font-medium leading-4 text-white uppercase"> {{ hund.name }} </div>
            <div class="inline-flex items-center justify-start h-4 text-xs font-medium text-white uppercase smartphone:h-5"> {{ hund.zuchtbuchnummer }} </div>
         </div>
         <div class="flex flex-row items-end justify-end text-left grow" :class="{ 'bg-drc-ruede-mittel': hund.geschlecht.id == 2, '!bg-drc-huendin-mittel': hund.geschlecht.id == 1 }">
            <div class="flex items-center justify-center mr-5 text-white dark:text-white focus:outline-none hover:cursor-pointer" @click="toggle_generationen">
               <button class="inline-flex items-center justify-center w-8 h-8 mr-2 text-white transition-colors duration-150 bg-white rounded-full focus:outline-none">
                  <svg-chevron-down xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 transition-all duration-300 ease-in-out text-drc-grau-300" :class="{ 'rotate-180': allegenerationen }" />
               </button>
               <span v-if="allegenerationen" class="smartphone:hidden tablet:hidden">Alle Generationen ausblenden</span>
               <span v-else class="smartphone:hidden tablet:hidden">Alle Generationen einblenden</span>
            </div>
            <div class="flex items-center justify-center text-white dark:text-white focus:outline-none hover:cursor-pointer" @click="toggle_details">
               <button class="inline-flex items-center justify-center w-8 h-8 mr-2 transition-colors duration-150 bg-white rounded-full text-drc-grau-300 focus:outline-none">
                  <svg-plus v-if="!alledetails" class="w-4 h-4" />
                  <svg-minus v-if="alledetails" class="w-4 h-4" />
               </button>
               <span v-if="alledetails" class="smartphone:hidden tablet:hidden">Alle Details ausblenden</span>
               <span v-else class="smartphone:hidden tablet:hidden">Alle Details einblenden</span>
            </div>
         </div>
         <div class="flex items-center justify-center h-10 px-5 text-white dark:text-white focus:outline-none">
            <button @click="$emit('close')" class="inline-flex items-center justify-center w-8 h-8 text-white transition-colors duration-150 rounded-full bg-drc-orange focus:outline-none">
               <svg-x class="w-6 h-6" />
            </button>
         </div>
      </div>
      <div class="absolute flex flex-col w-full bg-white top-20 z-1000 pr-4">
         <div class="flex w-full">
            <div class="flex flex-col justify-center w-6">
               <!-- RÜDE -->
               <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="h-20 fill-current" viewBox="0 0 21 41" fill="000">
                  <line x1="10" y1="0" x2="10" y2="41" vector-effect="non-scaling-stroke" stroke-width="4" stroke="currentColor" />
                  <line x1="10" y1="20" x2="21" y2="20" vector-effect="non-scaling-stroke" stroke-width="4" stroke="currentColor" />
               </svg>
               <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="fill-current grow" viewBox="0 0 21 1" fill="000">
                  <line x1="10" y1="0" x2="10" y2="1" vector-effect="non-scaling-stroke" stroke-width="4" stroke="currentColor" />
               </svg>
            </div>
            <div class="pt-2 grow">
               <slot name="vater"></slot>
            </div>
         </div>
         <div class="flex w-full">
            <div class="flex flex-col justify-start w-6">
               <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="h-20 fill-current" viewBox="0 0 21 41" fill="000">
                  <line x1="10" y1="0" x2="10" y2="21" vector-effect="non-scaling-stroke" stroke-width="4" stroke="currentColor" />
                  <line x1="10" y1="20" x2="21" y2="20" vector-effect="non-scaling-stroke" stroke-width="4" stroke="currentColor" />
               </svg>
            </div>
            <div class="pt-2 grow">
               <slot name="mutter"></slot>
            </div>
         </div>
         <!-- HÜNDIN -->
      </div>
   </div>
</template>

<script>
import { ls_open, ls_details, at_history } from './localstore'
import { ref } from 'vue'
export default {
   components: {},
   setup() {

      const open = ls_open
      const details = ls_details
      const history = at_history
      const allegenerationen = ref(true)
      const alledetails = ref(false)

      function toggle_details() {
         alledetails.value = !alledetails.value
         details.forEach((bool, idx) => {
            details[idx] = alledetails.value
         })
      }

      function toggle_generationen() {
         allegenerationen.value = !allegenerationen.value
         open.forEach((bool, idx) => {
            open[idx] = allegenerationen.value
         });
      }

      return { open, details, alledetails, allegenerationen, toggle_generationen, toggle_details, history }
   },
   props: {
      hund: {
         type: [String, Array, Object],
         default: '',
      },
      id: {
         type: [Number, String],
         default: 100,
      }
   },
   data() {
      return {

      }
   },
   computed: {
      verwandschaft() {
         switch (this.hund.generation) {
            case 0:
               return this.hund.geschlecht.id == 2 ? 'Rüde' : 'Hündin'
               break
            case 1:
               return this.hund.geschlecht.id == 2 ? 'Vater' : 'Mutter'
               break
            case 2:
               return this.hund.geschlecht.id == 2 ? 'Großvater' : 'Großmutter'
               break
            case 3:
               return this.hund.geschlecht.id == 2 ? 'Urgroßvater' : 'Urgroßmutter'
               break
            case 4:
               return this.hund.geschlecht.id == 2 ? 'UrUrgroßvater' : 'UrUrgroßmutter'
               break
         }
      },
      next_generation() {
         switch (this.hund.generation) {
            case 0:
               return 'Eltern'
               break
            case 1:
               return 'Großeltern'
               break
            case 2:
               return 'Urgroßeltern'
               break
            case 3:
               return 'Ururgroßeltern'
               break
            default:
               return ''
               break
         }
      },
   },
   methods: {
      beforeEnter(element) {
         requestAnimationFrame(() => {
            if (!element.style.height) {
               element.style.height = '0px'
            }
            element.style.display = null
         })
      },
      /**
       * @param {HTMLElement} element
       */
      enter(element) {
         requestAnimationFrame(() => {
            requestAnimationFrame(() => {
               element.style.height = `${element.scrollHeight}px`
            })
         })
      },
      /**
       * @param {HTMLElement} element
       */
      afterEnter(element) {
         element.style.height = null
      },
      /**
       * @param {HTMLElement} element
       */
      beforeLeave(element) {
         requestAnimationFrame(() => {
            if (!element.style.height) {
               element.style.height = `${element.offsetHeight}px`
            }
         })
      },
      /**
       * @param {HTMLElement} element
       */
      leave(element) {
         requestAnimationFrame(() => {
            requestAnimationFrame(() => {
               element.style.height = '0px'
            })
         })
      },
      /**
       * @param {HTMLElement} element
       */
      afterLeave(element) {
         element.style.height = null
      },
   }
}
</script>

<style scoped lang="scss">
.enter-active,
.leave-active {
   overflow: hidden;
   transition: height 1s ease;
}
</style>
