<template>
  <section
    v-if="isActive"
    :id="computedId"
    ref="tab"
    :aria-hidden="!isActive"
    :class="panelClass"
    role="tabpanel"
  >
    <slot />
  </section>
</template>

<script>
import {
  inject,
  watch,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  onActivated,
} from "vue";
import { useGlobalStore } from "@/stores/global";

export default {
  name: "Tab",
  props: {
    panelClass: {
      type: String,
      default: "tabs-component-panel",
    },
    id: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    special: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    delete: {
      type: Boolean,
      default: false,
    },
    deleteable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const scrollPos = ref();
    const isActive = ref(false);
    const hidden = ref(false);
    const tabsProvider = inject("tabsProvider");
    const addTab = inject("addTab");
    const updateTab = inject("updateTab");
    const deleteTab = inject("deleteTab");
    const header = props.prefix + props.title + props.suffix;
    const computedId = props.id ? props.id : props.title.toLowerCase().replace(/ /g, "-");
    const hash = "#" + (!props.isDisabled ? computedId : "");
    watch(
      () => tabsProvider.activeTabHash,
      () => {
        isActive.value = hash === tabsProvider.activeTabHash;
      }
    );
    watch(
      () => Object.assign({}, props),
      () => {
        updateTab(computedId, {
          title: props.title,
          deleteable: props.deleteable,
          header: props.prefix + props.title + props.suffix,
          isDisabled: props.isDisabled,
          hidden: props.hidden,
          hash: hash,
          index: tabsProvider.tabs.length,
          computedId: computedId,
          scrollPos: 0,
        });
      }
    );

    onBeforeMount(() => {
      addTab({
        title: props.title,
        header: header,
        deleteable: props.deleteable,
        isDisabled: props.isDisabled,
        hidden: props.hidden,
        hash: hash,
        index: tabsProvider.tabs.length,
        computedId: computedId,
        scrollPos: 0,
      });
    });
    onBeforeUnmount(() => {
      deleteTab(computedId);
    });
    return {
      header,
      computedId,
      hash,
      isActive,
      scrollPos,
    };
  },
};
</script>
