<template>
   <div class="px-2">
      <div class="flex justify-start flex-wrap bg-drc-gruen-500 bg-opacity-0 pt-2">
         
         <b-button-setting label="Alle öffnen" bgcolor="drc-gruen-500" icon="svg-plus" @buttonclick="openAll()" />
         <b-button-setting label="Alle schliessen" bgcolor="drc-gruen-500" icon="svg-minus" @buttonclick="closeAll()" />
         <b-button-setting label="Jagdliche Prüfungen" bgcolor="drc-gruen-500" icon="svg-checkbox-checked" @buttonclick="setAllJadglichePruefungen()" />
         <b-button-setting label="Nicht jagdl. Prüfungen" bgcolor="drc-gruen-500" icon="svg-checkbox-checked" @buttonclick="setAllNichtJagdlichePruefungen()" />
         <b-button-setting label="Jagdliche Prüfungen" bgcolor="drc-gruen-500" icon="svg-checkbox-unchecked" @buttonclick="unsetAllJagdlichePruefungen()" />
         <b-button-setting label="Nicht jagdl. Prüfungen" bgcolor="drc-gruen-500" icon="svg-checkbox-unchecked" @buttonclick="unsetAllNichtJagdlichePruefungen()" />
         
      </div>
   </div>
   <div class="">
   <div class="flex flex-wrap">
      <div class="flex-1">
         <b-expand-bar-suchmodul-checkboxes :daten="pruefungen.jagdliche_pruefungen" titel="Jagdliche Prüfungen" :open="expands.pruefungen.jagdliche_pruefungen" color="drc-gruen-500" />
         <b-expand-bar-suchmodul-checkboxes :daten="pruefungen.nicht_jagdliche_pruefungen" titel="Nicht jagdliche Prüfungen" :open="expands.pruefungen.nicht_jagdliche_pruefungen" color="drc-gruen-500" />
      </div>
   </div>
   </div>
</template>
<script setup>
import { ref, computed } from "vue";
import _ from "lodash";
import axios from "axios";
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";


import BCheckbox from "@/components/global/checkboxes/BCheckbox.vue";
import BCheckboxClear from "@/components/global/checkboxes/BCheckboxClear.vue";
import BInput from "@/components/global/inputs/BInput.vue";
import BSelectMulti from "@/components/global/select/BSelectMulti.vue";
import BNumber from "@/components/global/inputs/BNumber.vue";
import BRadiobuttons from "@/components/global/radios/BRadiobuttons.vue";
import BHinweisfeld from "@/components/global/BHinweisfeld.vue";
import BInfopanel from "@/components/global/panels/BInfopanel.vue";
import BDatepicker3 from "@/components/global/datepickers/BDatepicker3.vue";
import BThementrenner from "@/components/global/BThementrenner.vue";
import BLabel from "@/components/global/BLabel.vue";
import BRadiobutton from "@/components/global/radios/BRadiobutton.vue";
import BButtonSetting from "@/components/global/buttons/BButtonSetting.vue";

import BExpandBarSuchmodulCheckboxes from "@/components/global/bars/BExpandBarSuchmodulCheckboxes.vue";
import BExpandBarSuchmodul from "@/components/global/bars/BExpandBarSuchmodul.vue";

const { options } = useGlobalStore();
const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

const {
   pruefungen,
   expands
} = storeToRefs(sucheHundeStore);

function openAll() {
   expands.value.pruefungen.jagdliche_pruefungen = true;
   expands.value.pruefungen.nicht_jagdliche_pruefungen = true;
}

function closeAll() {
   expands.value.pruefungen.jagdliche_pruefungen = false;
   expands.value.pruefungen.nicht_jagdliche_pruefungen = false;
}

function setAllJadglichePruefungen() {
   for (const [key, value] of Object.entries(pruefungen.value.jagdliche_pruefungen)) {
         value.checked = true;
   }
}

function setAllNichtJagdlichePruefungen() {
   for (const [key, value] of Object.entries(pruefungen.value.nicht_jagdliche_pruefungen)) {
         value.checked = true;
   }
}

function unsetAllNichtJagdlichePruefungen() {
   for (const [key, value] of Object.entries(pruefungen.value.nicht_jagdliche_pruefungen)) {
         value.checked = false;
   }
}

function unsetAllJagdlichePruefungen() {
   for (const [key, value] of Object.entries(pruefungen.value.jagdliche_pruefungen)) {
         value.checked = false;
   }
}

</script>
<style></style>
