<template>
   <div class="my-5">
      <tabs-dynamic ref="hundetabs" @close="closeTab($event)" :options="{ disableScrollBehavior: true }">
         <tab id="einfache_suche" title="Einfache Suche" left>
            <einfache-suche @suche="sucheHunde" />
            <b-button icon="svg-lupe" iconcolor="drc-orange" @buttonclick="sucheHunde" label="Suche starten" right />
            <erweiterte-suche />
            <b-button icon="svg-lupe" iconcolor="drc-orange" @buttonclick="sucheHunde" label="Suche starten" right />
            <ergebnisse />
         </tab>
         <tab id="erweiterte_suche" title="Erweiterte Suche" left>
            <!-- <b-expand-bar-suche titel="Direktsuche" open> -->
            <einfache-suche @suche="sucheHunde" />
            <b-button icon="svg-lupe" iconcolor="drc-orange" @buttonclick="sucheHunde" label="Suche starten" right />
            <erweiterte-suche />
            <b-button icon="svg-lupe" iconcolor="drc-orange" @buttonclick="sucheHunde" label="Suche starten" right />
            <b-thementrenner  />
            <detail-suche />
            <b-button icon="svg-lupe" iconcolor="drc-orange" @buttonclick="sucheHunde" label="Suche starten" right />
            <ergebnisse />
         </tab>
  
         <tab v-for="(hund, index) in selectedHunde" :title="hund.name" :id="hund.id" :key="hund.id" deleteable left>
            <favorite :hund="hund" />
         </tab>
      </tabs-dynamic>
   </div>
   <b-ahnentafel-overlay-single v-if="showAhnentafel" @close="showAhnentafel = false" :id="id_ahnentafel" />
   <b-loader :loading="isLoading" />
   <b-lightbox v-if="showLightbox" :images="lightboxHund.images" @close="showLightbox = false" :startslide="startSlide" />
</template>
<script setup>
import { ref, reactive, computed, onMounted, watch } from "vue";
import _ from "lodash";
import axios from "axios";
import { useSnackbar } from 'vue3-snackbar'
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";

import Tab from "@/components/global/tabs/Tab.vue";
import TabsDynamic from "@/components/global/tabs/TabsDynamic.vue";
import BButton from "@/components/global/buttons/BButton.vue";
import BAhnentafelOverlaySingle from "@/components/ahnentafel/BAhnentafelOverlaySingle.vue";
import BLoader from "@/components/global/BLoader.vue";
import BLightbox from "@/components/global/bslider/BLightbox.vue";
import BTrenner from "@/components/global/BTrenner.vue";
import BThementrenner from "@/components/global/BThementrenner.vue";

import Ergebnisse from "@/components/hunde/suche/Ergebnisse.vue";
import EinfacheSuche from "@/components/hunde/suche/EinfacheSuche.vue";
import ErweiterteSuche from "@/components/hunde/suche/ErweiterteSuche.vue";
import DetailSuche from "@/components/hunde/suche/DetailSuche.vue";
import Favorite from "@/components/hunde/suche/Favorite.vue";

onMounted(() => {
   if (clear.value) {
      ergebnislisteHunde.value = [];
      selectedHunde.value = [];
   }

   breadcrumb.value[0] = "Hundesuche";
   breadcrumb.value[1] = "";
   breadcrumb.value[2] = "";
   breadcrumb.value[3] = "";
});

const snackbar = useSnackbar();

const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

// sucheStore.$reset()
const {
   ergebnislisteHunde,
   ergebnisText,
   selectedHunde,
   filterHunde,
   gentests,
   titels,
   pruefungen,
   gelenke,
   augen,
   zahnstatus,
   auflagen,
} = storeToRefs(sucheHundeStore);

const showAhnentafel = ref(false);
const id_ahnentafel = ref(null);
const clear = ref(0);

const globalStore = useGlobalStore();
const { breadcrumb, back_link, current_list } = storeToRefs(globalStore);

const isLoading = ref(false);

function sucheHunde() {
   let a_min = filterHunde.value.alter_min ? filterHunde.value.alter_min : 0;
   let a_max = filterHunde.value.alter_max ? filterHunde.value.alter_max : 0;

   if (a_max && a_min && a_min > a_max) {
      let temp = a_max;
      a_max = a_min;
      a_min = temp;
   }

   generateFormdata();

   let formData = new FormData();

   formData.append("name", filterHunde.value.name);
   formData.append("zuchtbuchnummer", filterHunde.value.zuchtbuchnummer);
   formData.append("typ", filterHunde.value.hundegruppe);
   formData.append("hundegruppe", filterHunde.value.hundegruppe);
   formData.append("zuchttauglichkeit_id", filterHunde.value.zuchttauglichkeit_id);
   formData.append("rasse_id", filterHunde.value.rasse_id);
   formData.append("geschlecht_id", filterHunde.value.geschlecht_id);
   formData.append("farbe_id", filterHunde.value.fellfarbe.id);
   formData.append("alter_min", a_min);
   formData.append("alter_max", a_max);
   formData.append("plz", filterHunde.value.plz);
   // formData.append("details", JSON.stringify(formdata_clear.value));
   formData.append("gentests", JSON.stringify(formdata_clear.value.gentests));
   formData.append("titels", JSON.stringify(formdata_clear.value.titels));
   formData.append("pruefungen", JSON.stringify(formdata_clear.value.pruefungen));
   formData.append("hd", JSON.stringify(formdata_clear.value.hd_scoring));
   formData.append("ed", JSON.stringify(formdata_clear.value.ed_scoring));
   formData.append("zaehne", JSON.stringify(formdata_clear.value.zaehne));
   formData.append("gebiss", JSON.stringify(formdata_clear.value.gebiss));
   formData.append("augen", JSON.stringify(formdata_clear.value.augen));
   formData.append("auflagen", JSON.stringify(formdata_clear.value.auflagen));

   formData.append("umkreis", filterHunde.value.umkreis);

   isLoading.value = true;
   console.log("formData", formData)
   axios
      .post("api/hunde/suche", formData)
      .then((response) => {
         //  ergebnislisteHunde.value = response.data
         console.log(response.data);
         ergebnislisteHunde.value = response.data.ergebnisliste.map((hund) => {
            let index = selectedHunde.value.findIndex((target) => target.id === hund.id);
            hund.selected = index === -1 ? false : true;
            return hund;
         });
         ergebnisText.value = response.data.message.text;
         isLoading.value = false;


          snackbar.add({ type: response.data.message.type, text: response.data.message.text });
   

         window.scrollTo({ top: eliste.value.offsetTop - 150, behavior: "smooth" });
         //  eliste.value.scrollIntoView({ behavior: "smooth" })
      })
      .catch((error) => {
         isLoading.value = false;
      });
}


const formdata_clear = ref();

function generateFormdata() {

   formdata_clear.value = {
      gentests: {},
      pruefungen: [],
      hd_scoring: {},
      ed_scoring: {},
      augen: {},
      zaehne: {},
      gebiss: {},
      titels: [],
      auflagen: {},
   };

   // Gentests
   for (const [key, value] of Object.entries(gentests.value)) {

      // frei = 1, traeger = 2, betroffen = 3, erbgangfrei = 4, abstammungfrei = 5
      if (value.frei && value.traeger && value.betroffen && value.erbgangfrei) {
         formdata_clear.value.gentests[key] = [];
         formdata_clear.value.gentests[key] = [1,2,3,4];
      } else if (!value.frei && !value.traeger && !value.betroffen && !value.erbgangfrei) {
         // formdata_clear.value.gentests[key] = "keine";
      } else {
         formdata_clear.value.gentests[key] = [];
         if (value.frei) formdata_clear.value.gentests[key].push(1);
         if (value.traeger) formdata_clear.value.gentests[key].push(2);
         if (value.betroffen) formdata_clear.value.gentests[key].push(3);
         if (value.erbgangfrei) formdata_clear.value.gentests[key].push(4);
      }
   }

   // pruefungen
   for (const [key, value] of Object.entries(pruefungen.value)) {
      for (const [ikey, ivalue] of Object.entries(value)) {
         if (ivalue.checked) {
            formdata_clear.value.pruefungen.push(ikey);
         }
      }
   }

   // titel
   for (const [key, value] of Object.entries(titels.value)) {
      if (value.checked) {
         formdata_clear.value.titels.push(key);
      }
   }

   // gelenke hd_scoring
   if (gelenke.value.hd_scoring.drcfci.a || gelenke.value.hd_scoring.drcfci.b || gelenke.value.hd_scoring.drcfci.c || gelenke.value.hd_scoring.drcfci.d || gelenke.value.hd_scoring.drcfci.e) {
      formdata_clear.value.hd_scoring.fci = [];
      formdata_clear.value.hd_scoring.drc = [];
      if(gelenke.value.hd_scoring.drcfci.a) { 
         formdata_clear.value.hd_scoring.fci.push(1);
         formdata_clear.value.hd_scoring.drc.push(1);
         formdata_clear.value.hd_scoring.drc.push(2);
      }

      if(gelenke.value.hd_scoring.drcfci.b)
      {
         formdata_clear.value.hd_scoring.fci.push(2);
         formdata_clear.value.hd_scoring.drc.push(3);
         formdata_clear.value.hd_scoring.drc.push(4);
      }

      if(gelenke.value.hd_scoring.drcfci.c)
      {
         formdata_clear.value.hd_scoring.fci.push(3);
         formdata_clear.value.hd_scoring.drc.push(5);
         formdata_clear.value.hd_scoring.drc.push(6);
      }

      if(gelenke.value.hd_scoring.drcfci.d) {
         formdata_clear.value.hd_scoring.fci.push(4);
         formdata_clear.value.hd_scoring.drc.push(7);
         formdata_clear.value.hd_scoring.drc.push(8);
      }

      if(gelenke.value.hd_scoring.drcfci.e) {
         formdata_clear.value.hd_scoring.fci.push(5);
         formdata_clear.value.hd_scoring.drc.push(9);
         formdata_clear.value.hd_scoring.drc.push(10);
      }
   }

   if(gelenke.value.hd_scoring.ofa.keine) formdata_clear.value.hd_scoring.ofa = true;
   if(gelenke.value.hd_scoring.hs.keine) formdata_clear.value.hd_scoring.hs = true;
   if(gelenke.value.hd_scoring.ch.keine) formdata_clear.value.hd_scoring.ch = true;
   if(gelenke.value.hd_scoring.nl.keine) formdata_clear.value.hd_scoring.nl = true;
   if(gelenke.value.hd_scoring.sw.keine) formdata_clear.value.hd_scoring.sw = true;
   if(gelenke.value.hd_scoring.fi.keine) formdata_clear.value.hd_scoring.fi = true;
   if(gelenke.value.hd_scoring.sv.keine) formdata_clear.value.hd_scoring.sv = true;


   // gelenke ed_scoring
   if (gelenke.value.ed_scoring.drc.frei || gelenke.value.ed_scoring.drc.grenzfall || gelenke.value.ed_scoring.drc.grad1 || gelenke.value.ed_scoring.drc.grad2 || gelenke.value.ed_scoring.drc.grad3) {

      formdata_clear.value.ed_scoring.drc = [];
      formdata_clear.value.ed_scoring.fci = [];

      if(gelenke.value.ed_scoring.drc.frei) { 
         formdata_clear.value.ed_scoring.drc.push(1);
         formdata_clear.value.ed_scoring.fci.push(1);
      }
      if(gelenke.value.ed_scoring.drc.grenzfall) formdata_clear.value.ed_scoring.drc.push(2);

      if(gelenke.value.ed_scoring.drc.grad1) {
         formdata_clear.value.ed_scoring.drc.push(3);
         formdata_clear.value.ed_scoring.fci.push(2);
      }
      if(gelenke.value.ed_scoring.drc.grad2) {
         formdata_clear.value.ed_scoring.drc.push(4);
         formdata_clear.value.ed_scoring.fci.push(3);
      }
      if(gelenke.value.ed_scoring.drc.grad3) {
         formdata_clear.value.ed_scoring.drc.push(5);
         formdata_clear.value.ed_scoring.fci.push(4);
      }
   }

   if(gelenke.value.ed_scoring.ofel.keine) formdata_clear.value.ed_scoring.ofel = true;
   if(gelenke.value.ed_scoring.lang.keine) formdata_clear.value.ed_scoring.lang = true;


   // Zähne
   for (const [key, value] of Object.entries(zahnstatus.value.zaehne)) {
      if (value.checked) {
         formdata_clear.value.zaehne[key] = true;
      }
   }

   // Gebiss
   for (const [key, value] of Object.entries(zahnstatus.value.gebiss)) {
      if (value.checked) {
         formdata_clear.value.gebiss[key] = true;
      }
   }

   // Augen
   for (const [key, value] of Object.entries(augen.value)) {
      if (value.frei && value.nichtfrei && value.zweifelhaft) {
         formdata_clear.value.augen[key+`_id`] = [];
         formdata_clear.value.augen[key+`_id`] = [1,2,3,4];
      } else if (!value.frei && !value.nichtfrei && !value.zweifelhaft) {
         // formdata_clear.value.gentests[key] = "keine";
      } else {
         // frei = 1, zweifelhaft/vorläufig = 2, nichtfrei = 3, 
         formdata_clear.value.augen[key+`_id`] = [];
         if (value.frei) formdata_clear.value.augen[key+`_id`].push(1);
         if (value.zweifelhaft) formdata_clear.value.augen[key+`_id`].push(2);
         if (value.nichtfrei) formdata_clear.value.augen[key+`_id`].push(3);
      }
   
      if ( augen.value.mpp.nichtfrei || augen.value.mpp.zweifelhaft) {
        
         if ( augen.value.mpp.iris) formdata_clear.value.augen.mpp_iris = true;
         if ( augen.value.mpp.linse) formdata_clear.value.augen.mpp_linse = true;
         if ( augen.value.mpp.kornea) formdata_clear.value.augen.mpp_kornea = true;
         if ( augen.value.mpp.vorderkammer) formdata_clear.value.augen.mpp_vorderkammer = true;
      }

      if ( augen.value.phtvlphpv.nichtfrei || augen.value.phtvlphpv.zweifelhaft) {
         formdata_clear.value.augen.phtvlphpv_nfrei_id = [];
         if ( augen.value.phtvlphpv.grad1) formdata_clear.value.augen.phtvlphpv_nfrei_id.push(1);
         if ( augen.value.phtvlphpv.grad2) formdata_clear.value.augen.phtvlphpv_nfrei_id.push(2);
         if ( augen.value.phtvlphpv.grad3) formdata_clear.value.augen.phtvlphpv_nfrei_id.push(3);
         if ( augen.value.phtvlphpv.grad4) formdata_clear.value.augen.phtvlphpv_nfrei_id.push(4);
         if ( augen.value.phtvlphpv.grad5) formdata_clear.value.augen.phtvlphpv_nfrei_id.push(5);
         if ( augen.value.phtvlphpv.grad6) formdata_clear.value.augen.phtvlphpv_nfrei_id.push(6);
      }

      if ( augen.value.rd.nichtfrei || augen.value.rd.zweifelhaft) {
         // formdata_clear.value.augen.rd_form = [];
         if ( augen.value.rd.multifokal) formdata_clear.value.augen.rd_multifokal = true;
         if ( augen.value.rd.geo) formdata_clear.value.augen.rd_geo = true;
         if ( augen.value.rd.total) formdata_clear.value.augen.rd_total = true;
      }

      if ( augen.value.cea.nichtfrei || augen.value.cea.zweifelhaft) {
         // formdata_clear.value.augen.cea_art = [];
         if ( augen.value.cea.choroidhypo) formdata_clear.value.augen.cea_choroidhypo = true;
         if ( augen.value.cea.kolobom) formdata_clear.value.augen.cea_kolobom = true;
         if ( augen.value.cea.sonstige) formdata_clear.value.augen.cea_sonstige = true;

      }

      if ( augen.value.icaa.nichtfrei || augen.value.icaa.zweifelhaft) {
         formdata_clear.value.augen.icaa_grad = [];
         if ( augen.value.icaa.kurztrabekel) formdata_clear.value.augen.icaa_grad.push("kurztrabekel");
         if ( augen.value.icaa.gewebebruecken) formdata_clear.value.augen.icaa_grad.push("gewebebruecken");
         if ( augen.value.icaa.totaldyspl) formdata_clear.value.augen.icaa_grad.push("totaldyspl");
         if ( augen.value.icaa.kurztrabekel) formdata_clear.value.augen.dyslpectabnorm_kurztrabekel = true;
         if ( augen.value.icaa.gewebebruecken) formdata_clear.value.augen.dyslpectabnorm_gewebebruecken = true;
         if ( augen.value.icaa.totaldyspl) formdata_clear.value.augen.dyslpectabnorm_totaldyspl = true;

      }

      if ( augen.value.katarakt_nonkon.nichtfrei || augen.value.katarakt_nonkon.zweifelhaft) {
         // formdata_clear.value.augen.katarakt_nonkon_lokation = [];
         if ( augen.value.katarakt_nonkon.cortikalis) formdata_clear.value.augen.katarakt_cortikalis = true;
         if ( augen.value.katarakt_nonkon.polpost) formdata_clear.value.augen.katarakt_polpost = true;
         if ( augen.value.katarakt_nonkon.sutura_ant) formdata_clear.value.augen.katarakt_sutura_ant = true;
         if ( augen.value.katarakt_nonkon.punctata) formdata_clear.value.augen.katarakt_punctata = true;
         if ( augen.value.katarakt_nonkon.nuklearis) formdata_clear.value.augen.katarakt_nuklearis = true;
         if ( augen.value.katarakt_nonkon.sonstige) formdata_clear.value.augen.katarakt_sonstige = true;
      }

   }

   // Auflagen
   for (const [key, value] of Object.entries(auflagen.value)) {
      for (const [ikey, ivalue] of Object.entries(value)) {
         if (ivalue.checked) {
            formdata_clear.value.auflagen[key + `_` + ikey] = true;
         }
      }
   }


   console.log("formdata_clear", formdata_clear.value);
}




function closeTab(index) {
   console.log("Delete", index);
   selectedHunde.value[index - 2].selected = false;
   selectedHunde.value.splice(index - 2, 1);
   if (selectedHunde.value.length == 0) hundetabs.value.selectTab("#suche");
}
</script>
<style></style>
