<template>
   <div class="mx-5 desktop:mx-10 my-5">

   <template v-if="ergebnisText != null">
      <div ref="eliste" class="w-full bg-drc-grau-400  h-12 inline-flex justify-center items-center  font-medium"> {{ ergebnisText }} </div>
      <b-button v-if="!open" @buttonclick="open = !open" label="Erweiterte Ansicht" right />
      <b-button v-else @buttonclick="open = !open" label="Nur Kopfzeilen" right />
   </template>
   <div class="py-1 bg-white" v-for="(hund, index) in ergebnislisteHunde" key="hunde.id">
      <b-expand-bar-hund :open="open" :index="index" :hund="hund" @selectHund="selectHund(hund)" @showAhnentafel="
      showAhnentafel = true;
      id_ahnentafel = hund.id;
   " @startLightbox="
      startSlide = 0;
      lightboxHund = hund;
      showLightbox = true;
   ">
         <div class="bg-drc-grau-600 pb-4">
            <div class="flex text-left min-w-45">
               <div class="w-10 p-2 text-drc-grau-200">
                  <svg-gesundheit class="w-10 h-10" />
               </div>
               <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                  <span class="font-bold">Gesundheit</span>
                  <template v-if="hund.hdeduntersuchung">
                     <span class="mt-2 font-bold">Gelenke</span>
                     <span v-if="hund.hd_score">HD: {{ hund.hd_score }}</span>
                     <span v-if="hund.hd_l_score">HD (links): {{ hund.hd_l_score }}</span>
                     <span v-if="hund.hd_r_score">HD (rechts): {{ hund.hd_r_score }}</span>
                     <span v-if="hund.ed_score">ED: {{ hund.ed_score }} </span>
                     <span v-if="hund.ed_l_score">ED (links): {{ hund.ed_l_score }} </span>
                     <span v-if="hund.ed_r_score">ED (rechts): {{ hund.ed_r_score }} </span>
                  </template>
                  <template v-if="hund.zahnstatus">
                     <span class="mt-2 font-bold">Zahnstatus</span>
                     <span v-if="hund.zahnstatus_gebiss">Gebiss: {{ hund.zahnstatus_gebiss }} </span>
                     <span v-if="
               hund.zahnstatus_befund_schema ||
               hund.zahnstatus_befund_anmerkungen
               ">Zahnstatus: {{ hund.zahnstatus_befund_schema }} {{ hund.zahnstatus_befund_anmerkungen }}</span>
                  </template>
                  <template v-if="hund.augenuntersuchung">
                     <span class="mt-2 font-bold">Augenuntersuchung</span>
                     <span v-if="hund.au_datum">Datum: {{ hund.au_datum }}</span>
                     <span v-if="hund.au_rd">RD: {{ hund.au_rd }}</span>
                     <span v-if="hund.au_pra_rd">PRA-RD: {{ hund.au_pra_rd }}</span>
                     <span v-if="hund.au_katarakt_kon">Katarakt (kongential): {{ hund.au_katarakt_kon }}</span>
                     <span v-if="hund.au_katarakt_nonkon">Katarakt (nicht kongential): {{ hund.au_katarakt_nonkon }} <span v-if="hund.au_katarakt_nonkon_details">({{ hund.au_katarakt_nonkon_details }})</span></span>
                  </template>
                  <template v-if="hund.goniountersuchung">
                     <span class="mt-2 font-bold">Goniountersuchung</span>
                  </template>
                  <template v-if="hund.gentests">
                     <span class="mt-2 font-bold">Gentests</span>
                     <span>{{ hund.gentests }}</span>
                  </template>
               </div>
            </div>
         </div>
         <div class="bg-drc-grau-600 pb-4">
            <div class="my-1">
               <div class="flex text-left min-w-45">
                  <div class="w-10 p-2 text-drc-grau-200">
                     <svg-das-leistungswesen class="w-10 h-10" />
                  </div>
                  <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                     <span class="font-bold mb-4">Prüfungen</span>
                     <span v-for="pruefung in hund.pruefungstitel" class="text-sm">{{ pruefung.name }}<br />
                     </span>
                  </div>
               </div>
            </div>
         </div>
         <div class="bg-drc-grau-600 pb-4">
            <div class="my-1">
               <div class="flex text-left min-w-45">
                  <div class="w-10 p-2 text-drc-grau-200">
                     <svg-award class="w-10 h-10" />
                  </div>
                  <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                     <span class="font-bold mb-4">Titel</span>
                     <span v-for="pruefung in hund.pruefungstitel" class="text-sm">{{ pruefung.name }}<br />
                     </span>
                  </div>
               </div>
            </div>
         </div>
         <div v-if="hund.wesenstest" class="bg-drc-grau-600 pb-4">
            <div class="my-1">
               <div class="flex text-left min-w-45 max-w-200">
                  <div class="w-10 p-2 text-drc-grau-200">
                     <svg-das-leistungswesen class="w-10 h-10" />
                  </div>
                  <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                     <span class="font-bold">Wesenstest</span>
                     <!-- <div class="my-2">{{ hund.wesenstest[0].status }}</div> -->
                     <div v-if="hund.wesenstest_richter" class="my-2">
                        <b>Richter:</b> {{ hund.wesenstest_richter }}
                     </div>
                     <template v-for="kommentar in hund.wesenstest_zusammenfassung" :key="kommentar.titel">
                        <div v-if="kommentar.text != ''" class="my-2">
                           <b>{{ kommentar.titel }}:</b><br />{{ kommentar.text }}
                        </div>
                     </template>
                  </div>
               </div>
            </div>
         </div>
         <div v-if="hund.formwert" class="bg-drc-grau-600 pb-4">
            <div class="my-1">
               <div class="flex text-left min-w-45 max-w-200">
                  <div class="w-10 p-2 text-drc-grau-200">
                     <svg-das-leistungswesen class="w-10 h-10" />
                  </div>
                  <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                     <span class="font-bold">Formwert</span>
                     <div v-if="hund.formwert_richter" class="my-2">
                        <b>Richter:</b> {{ hund.formwert_richter }}
                     </div>
                     <div v-if="hund.formwert_formwert" class="my-2">
                        <b>Formwert:</b> {{ hund.formwert_formwert }}
                     </div>
                     <div v-if="hund.formwert_risthoehe" class="my-2">
                        <b>Risthöhe:</b> {{ hund.formwert_risthoehe }}
                     </div>
                     <div v-if="hund.formwert_beurteilung" class="my-2">
                        <b>Beurteilung:</b><br />{{ hund.formwert_beurteilung }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="bg-drc-grau-600 pb-4">
            <div class="my-1">
               <div class="flex text-left min-w-45 max-w-200">
                  <div class="w-10 p-2 text-drc-grau-200">
                     <svg-das-leistungswesen class="w-10 h-10" />
                  </div>
                  <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                     <span class="font-bold">Zucht</span>
                     <!-- <div class="my-2">{{ hund.wesenstest[0].status }}</div> -->
                  </div>
               </div>
            </div>
         </div>
         <div class="grid w-full my-2 gap-2 !grid-cols-1" :class="{
         '!grid-cols-2': contentsize >= 800 && contentsize < 1200,
         '!grid-cols-3': contentsize >= 1200 && contentsize < 1600,
         '!grid-cols-4': contentsize >= 1600,
      }">
            <div v-for="image in hund.images" class="bg-drc-grau-600 max-w-58" :class="{ '!row-span-2': contentsize >= 1200 && contentsize < 1600 }">
               <div class="flex flex-col flex-1 bg-drc-grau-600 min-w-45">
                  <div class="relative flex items-center justify-center w-full h-full p-3">
                     <img :src="storagepath + image.path" :alt="hund.name" class="object-cover" />
                     <button v-if="hund.images.length > 0" @click="
               startSlide = index;
               lightboxHund = hund;
               showLightbox = true;
               " class="absolute z-10 p-0 bg-white rounded-full opacity-75 bottom-4 right-4">
                        <svg-spaltenansicht class="w-10 h-10 text-drc-grau-200" />
                     </button>
                  </div>
                  <div class="flex pb-4 text-left min-w-45">
                     <div class="w-10 px-1 mx-1 text-drc-grau-200">
                        <svg-info />
                     </div>
                     <div class="flex flex-col pt-1 pr-2 text-sm text-left text-drc-grau-200">
                        <span class="font-bold">Bildunterschrift</span>
                        <span v-if="image.bu" class="text-sm"> {{ image.bu }} </span>
                        <span v-else class="text-sm italic text-drc-grau-300">kein Eintrag </span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </b-expand-bar-hund>
   </div>
        
   </div>
   <b-ahnentafel-overlay-single v-if="showAhnentafel" @close="showAhnentafel = false" :id="id_ahnentafel" />
   <b-loader :loading="isLoading" />
   <b-lightbox v-if="showLightbox" :images="lightboxHund.images" @close="showLightbox = false" :startslide="startSlide" />
</template>
<script setup>
import { ref, reactive, computed, onMounted, watch } from "vue";
import _ from "lodash";
import axios from "axios";
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";

import Tab from "@/components/global/tabs/Tab.vue";
import TabsDynamic from "@/components/global/tabs/TabsDynamic.vue";
import TabsIcon from "@/components/global/tabs/TabsIcon.vue";
import TabIcon from "@/components/global/tabs/TabIcon.vue";
import BExpandBarHund from "@/components/global/bars/BExpandBarHund.vue";
import BExpandBarSuche from "@/components/global/bars/BExpandBarSuche.vue";

import BButton from "@/components/global/buttons/BButton.vue";

import BAhnentafelOverlaySingle from "@/components/ahnentafel/BAhnentafelOverlaySingle.vue";
import BLoader from "@/components/global/BLoader.vue";
import BLightbox from "@/components/global/bslider/BLightbox.vue";


onMounted(() => {
   if (clear.value) {
      ergebnislisteHunde.value = [];
      selectedHunde.value = [];
   }
});

const storagepath = import.meta.env.VITE_APP_STORAGE;

const open = ref(false);
const { options } = useGlobalStore();
const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

// sucheStore.$reset()
const {
   ergebnislisteHunde,
   ergebnisText,
   selectedHunde,
   filterHunde,
} = storeToRefs(sucheHundeStore);

const showAhnentafel = ref(false);
const id_ahnentafel = ref(null);
const clear = ref(0);

const globalStore = useGlobalStore();
const { breadcrumb, back_link, current_list } = storeToRefs(globalStore);

const isLoading = ref(false);

const hundetabs = ref(null);


const eliste = ref(null);



const lightboxHund = ref(null);
const showLightbox = ref(false);
const startSlide = ref(null);

function startLightbox(hund) {
   lightboxHund.value = hund;
   showLightbox.value = true;
}



function selectHund(hund) {
   // loadDetail(hund);
   let index = selectedHunde.value.findIndex((target) => target.id === hund.id);

   if (index === -1) {
      selectedHunde.value.push(hund);
      // setTimeout(function () {
      //    hundetabs.value.selectTab('#' + hund.id)
      // }, 100)
      hund.selected = true;
   } else {
      selectedHunde.value[index].selected = false;
      selectedHunde.value.splice(index, 1);

      // hundetabs.value.selectTab('#' + hund.id)
   }

   // window.scrollTo({ top: 0 , behavior: 'smooth' });
}

function loadDetail(hund) {
   if (!hund.details) {
      isLoading.value = true;

      axios
         .get("api/hund/detail/" + hund.id)
         .then((response) => {
            //  ergebnislisteZuchtwarte.value = response.data
            console.log(response.data);
            let index = ergebnislisteHunde.value.findIndex((h) => h.id === hund.id);
            console.log("ALT:", ergebnislisteHunde.value[index]);
            ergebnislisteHunde.value[index] = response.data.data;
            ergebnislisteHunde.value[index]["details"] = true;
            console.log("neu", ergebnislisteHunde.value[index]);
            isLoading.value = false;
            //  eliste.value.scrollIntoView({ behavior: "smooth" })
         })
         .catch((error) => {
            isLoading.value = false;
         });
   }
}


</script>
<style></style>
