<template>
   <div class="my-5 mx-10 border-b border-drc-grau-300"
      :class="{ '!mx-5': outline == true}, { '!mx-15': inline == true}">
   </div>
</template>

<script>
export default {
   props: {
      inline: {
         type: Boolean,
         default: false,
      },
      outline: {
         type: Boolean,
         default: false,
      }
   },
   data() {
      return {};
   }
};
</script>

<style lang="scss">
</style>
