<template>
   <div class="px-2">
      <div class="flex justify-start flex-wrap bg-drc-gruen-100 bg-opacity-0 pt-2">
         <b-button-setting label="Alle öffnen" bgcolor="drc-gruen-100" icon="svg-plus" @buttonclick="openAll()" />
         <b-button-setting label="Alle schliessen" bgcolor="drc-gruen-100" icon="svg-minus" @buttonclick="closeAll()" />
         <b-button-setting label="frei" bgcolor="drc-gruen-100" icon="svg-checkbox-checked" @buttonclick="setAllFrei()" />
         <b-button-setting label="Träger" bgcolor="drc-gruen-100" icon="svg-checkbox-checked" @buttonclick="setAllTraeger()" />
         <b-button-setting label="betroffen" bgcolor="drc-gruen-100" icon="svg-checkbox-checked" @buttonclick="setAllBetroffen()" />
         <b-button-setting label="Erbgang frei" bgcolor="drc-gruen-100" icon="svg-checkbox-checked" @buttonclick="setAllErbgangfrei()" />
         <b-button-setting label="frei" bgcolor="drc-gruen-100" icon="svg-checkbox-unchecked" @buttonclick="unsetAllFrei()" />
         <b-button-setting label="Träger" bgcolor="drc-gruen-100" icon="svg-checkbox-unchecked" @buttonclick="unsetAllTraeger()" />
         <b-button-setting label="betroffen" bgcolor="drc-gruen-100" icon="svg-checkbox-unchecked" @buttonclick="unsetAllBetroffen()" />
         <b-button-setting label="Erbgang frei" bgcolor="drc-gruen-100" icon="svg-checkbox-unchecked" @buttonclick="unsetAllErbgangfrei()" />
      </div>
   </div>
   <div class="">
   <div class="flex flex-wrap">
      <div class="flex-1">
         <b-expand-bar-suchmodul-gentest v-for="gentest in gentests" :key="gentest.id" :titel="gentest['titel']" :id="gentest['id']" :gentest="gentest" />
      </div>
   </div>
   </div>
</template>
<script setup>
import { ref, computed } from "vue";
import _ from "lodash";
import axios from "axios";
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";


import BCheckbox from "@/components/global/checkboxes/BCheckbox.vue";
import BCheckboxClear from "@/components/global/checkboxes/BCheckboxClear.vue";
import BButtonSolo from "@/components/global/buttons/BButtonSolo.vue";
import BButton from "@/components/global/buttons/BButton.vue";
import BInput from "@/components/global/inputs/BInput.vue";
import BSelectMulti from "@/components/global/select/BSelectMulti.vue";
import BNumber from "@/components/global/inputs/BNumber.vue";
import BRadiobuttons from "@/components/global/radios/BRadiobuttons.vue";
import BHinweisfeld from "@/components/global/BHinweisfeld.vue";
import BInfopanel from "@/components/global/panels/BInfopanel.vue";
import BDatepicker3 from "@/components/global/datepickers/BDatepicker3.vue";
import BThementrenner from "@/components/global/BThementrenner.vue";
import BLabel from "@/components/global/BLabel.vue";
import BRadiobutton from "@/components/global/radios/BRadiobutton.vue";
import BButtonSetting from "@/components/global/buttons/BButtonSetting.vue";

import BExpandBarSuchmodulGentest from "@/components/global/bars/BExpandBarSuchmodulGentest.vue";
import BExpandBarSuchmodulCheckboxes from "@/components/global/bars/BExpandBarSuchmodulCheckboxes.vue";
import BExpandBarSuchmodul from "@/components/global/bars/BExpandBarSuchmodul.vue";

const { options } = useGlobalStore();
const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

const {
   gentests
} = storeToRefs(sucheHundeStore);

function openAll() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.open = true;
   }
}

function closeAll() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.open = false;
   }
}

function setAllFrei() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.frei = true;
   }
}

function setAllTraeger() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.traeger = true;
   }
}

function setAllBetroffen() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.betroffen = true;
   }
}

function setAllErbgangfrei() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.erbgangfrei = true;
   }
}

function unsetAll() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.frei = false;
      value.traeger = false;
      value.betroffen = false;
      value.erbgangfrei = false;
   }
}

function unsetAllFrei() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.frei = false;
   }
}

function unsetAllTraeger() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.traeger = false;
   }
}

function unsetAllBetroffen() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.betroffen = false;
   }
}

function unsetAllErbgangfrei() {
   for (const [key, value] of Object.entries(gentests.value)) {
      value.erbgangfrei = false;
   }
}

</script>
<style></style>
