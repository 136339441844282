// import axios from 'axios'
import { defineStore } from 'pinia'
import { useGlobalStore } from './global'
import { useUserDataStore } from './userdata'

export const useAuthStore = defineStore({
   id: 'auth',
   state: () => ({
      authenticated: false,
      user: null,
      name: null


   }),
   actions: {
      async signIn( credentials) {
         console.log("SignIn")
         const userDataStore = useUserDataStore()
         const globalStore = useGlobalStore();
         globalStore.loadingMeineDaten = true;

         if (navigator.onLine) {
         console.log("Pinia: signIn")
         await axios.get('/sanctum/csrf-cookie').then((response) => {
            window.csrf_token = response.config.headers['X-XSRF-TOKEN'];
         })
         await axios.post('/login', credentials).then((response) => {
            console.log("Pinia: loggedIn")
         }).catch(() => {
            console.log("Pinia: notLoggedIn")
         })
         } 

         return axios.get('/api/user').then((response) => {
            console.log("Pinia: userDate")
    
            this.authenticated = true
            this.user = response.data
            this.name = this.user.name
            globalStore.getMainMenu()
            globalStore.getUserMenu()
            globalStore.getDoktree()
            globalStore.getOrgatree()
            userDataStore.getUserData()

            // HIER MUSS BEIM DEPLOYEN DIE ENTSPRECHENDE DOMAIN EINGETRAGEN WERDEN .atbloom.de oder localhost
            // localStorage.setItem("drc", response.data.email);
            if (navigator.onLine) {
               document.cookie = "drc=" + response.data.email + "; expires=Fri, 31 Dec 2100 12:00:00 UTC; domain=.atbloom.de; SameSite=Lax; path=/;"
               document.cookie = "drc=" + response.data.email + "; expires=Fri, 31 Dec 2100 12:00:00 UTC; SameSite=Lax; path=/;"
               console.log("Pinia: userDate")
            }


         }).catch(() => {
            this.authenticated = false
            this.user = null
            globalStore.loadingMeineDaten = false;
            globalStore.loadingOptions = false;
            // localStorage.removeItem("drc");
            document.cookie = "drc=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.atbloom.de; path=/;"
            document.cookie = "drc=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
         })
      },

      signOut() {
         console.log("Pinia: signOut")
         axios.post('/logout')
         // localStorage.removeItem("drc");
         // HIER MUSS BEIM DEPLOYEN DIE ENTSPRECHENDE DOMAIN EINGETRAGEN WERDEN .atbloom.de oder localhost
         document.cookie = "drc=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.atbloom.de; path=/;"
         document.cookie = "drc=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
         console.log("Pinia: deleteCookie")
         this.authenticated = false
         this.user = null
         this.name = null
         console.log("Pinia: Logout")


      },

      async me() {
          console.log("me")
            const globalStore  = useGlobalStore()
            const userDataStore = useUserDataStore()
            globalStore.loadingMeineDaten = true;
    
            return await axios.get('/api/user').then((response) => {
               console.log("getuser")
               this.authenticated = true
               this.user = response.data
               this.name = this.user.name
             // globalStore.getOptions() 
            globalStore.getMainMenu();
            globalStore.getUserMenu()
            globalStore.getDoktree()
            globalStore.getOrgatree()
            
            userDataStore.getUserData()
   
            // HIER MUSS BEIM DEPLOYEN DIE ENTSPRECHENDE DOMAIN EINGETRAGEN WERDEN .atbloom.de oder localhost
            // localStorage.setItem("drc", response.data.email);
            if (navigator.onLine) {
            document.cookie = "drc=" + response.data.email + "; expires=Fri, 31 Dec 2100 12:00:00 UTC; domain=.atbloom.de; SameSite=Lax; path=/;"
            document.cookie = "drc=" + response.data.email + "; expires=Fri, 31 Dec 2100 12:00:00 UTC; SameSite=Lax; path=/;"
            }

         }).catch(() => {
            this.authenticated = false
            this.user = null
            globalStore.loadingMeineDaten = false;
            globalStore.loadingOptions = false;
            // localStorage.removeItem("drc");
            document.cookie = "drc=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.atbloom.de; path=/;"
            document.cookie = "drc=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
         })
      }
   },
   persist: true,
})