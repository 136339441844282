<template>
  <div class="my-0">
   <div class="grid !grid-cols-bar-sm-smsmsmsmsm w-full !gap-0.5 text-sm bg-white text-drc-text" >
         <div
            class="flex items-center justify-center text-white min-h-12   col-start-1 row-start-1 relative font-medium"
            :class="{
                'row-span-2 ': contentsize < 900,
              'bg-drc-ruede-dunkel': hund.geschlecht.id == 2,
              'bg-drc-huendin-dunkel': hund.geschlecht.id == 1,

            }"
         >
            <svg-vater class="w-10 h-10" v-if="hund.geschlecht.id == 2" />
            <svg-mutter class="w-10 h-10" v-if="hund.geschlecht.id == 1" />
            <svg-h-r class="w-10 h-10" v-if="hund.geschlecht.id != 1 && hund.geschlecht.id != 2" />
         </div>
         <div
            class="inline-flex items-center justify-start text-drc-text text-center px-5 pt-4 pb-2 min-h-12 leading-tight relative"
            :class="{
               '!col-span-12': contentsize >= 900,
               'col-span-32 row-start-1 col-start-2': contentsize < 900,
               'bg-drc-grau-500': index % 2,
               'bg-drc-grau-400': !(index % 2),
               '!bg-drc-gruen-500 text-white': hund.open,
               'bg-drc-ruede-dunkel text-white': hund.geschlecht.id == 2 && contentsize < 900
            }"
         >
            <span class="text-xs pb-2 opacity-50 absolute top-0 left-2">Name</span>
            {{ hund.name }}
         </div>
         <div
            class="inline-flex flex-col items-start justify-center  text-drc-text px-5 pt-4 pb-2 min-h-12 leading-tight relative"
            :class="{
               '!col-span-8': contentsize >= 900,
               'col-span-24 row-start-2': contentsize < 900,
               'bg-drc-grau-500': index % 2,
               'bg-drc-grau-400': !(index % 2),
               '!bg-drc-gruen-500 text-white': hund.open,
            }"
         >
            <span class="text-xs pb-2 opacity-50 absolute top-0 left-2">ZB-Nr.</span>
            {{ hund.zuchtbuchnummer }}
         </div>

         <div
            class="inline-flex  items-center justify-center text-center text-drc-text px-2 pt-4 pb-2 min-h-12 leading-tight relative"
            :class="{
               '!col-span-12': contentsize >= 900,
               'col-span-13 row-start-2  !justify-start': contentsize < 900,
               'bg-drc-grau-500': index % 2,
               'bg-drc-grau-400': !(index % 2),
               '!bg-drc-gruen-500 text-white': hund.open,
            }"
         >
            <span class="text-xs pb-2 opacity-50 absolute top-0 left-2">Rasse</span>
            {{ hund.rasse.name }}
         </div>

         <div
            class="flex items-center justify-center focus:outline-none hover:cursor-pointer col-start-34 row-start-1"

            :class="{
               'bg-drc-grau-500': index % 2,
               'bg-drc-grau-400': !(index % 2),
               '!bg-drc-gruen-500 text-white': hund.open,
            }"
         >
         <svg-welpen class="w-12 p-1" />
         </div>
         <div
            class="flex items-center justify-center focus:outline-none hover:cursor-pointer col-start-35 row-start-1"
            @click="showAhnentafel()"
            :class="{
               'bg-drc-grau-500': index % 2,
               'bg-drc-grau-400': !(index % 2),
               '!bg-drc-gruen-500 text-white': hund.open,
            }"
      >
        <svg-ahnentafel class="w-12 p-1" />
         </div>
         <div
            class="flex items-center justify-center focus:outline-none hover:cursor-pointer col-start-36 row-start-1"
           @click="router.push({ name: 'hundedit', params: { id: hund.id } })"
            :class="{
               'bg-drc-grau-500': index % 2,
               'bg-drc-grau-400': !(index % 2),
               '!bg-drc-gruen-500 text-white': hund.open,
            }"
         >
         <svg-hundeakte class="w-12 p-1 hover:cursor-pointer" />
         </div>
         <div
            class="flex items-center justify-center focus:outline-none hover:cursor-pointer col-start-37 row-start-1"
            @click="selectHund()"
            :class="{
               'bg-drc-grau-500': index % 2,
               'bg-drc-grau-400': !(index % 2),
               '!bg-drc-gruen-500 text-white': hund.open,
            }"
         >
            <svg-favorite-solid v-if="hund.selected" class="w-10 p-1 hover:cursor-pointer text-drc-orange" />
            <svg-favorite v-else class="w-10 p-1 hover:cursor-pointer" />
         </div>
         <div
            class="flex items-center justify-center focus:outline-none hover:cursor-pointer col-start-38 row-start-1"
            :class="{
               'bg-drc-grau-500': index % 2,
               'bg-drc-grau-400': !(index % 2),
               '!bg-drc-gruen-500 text-white': hund.open,
            }"
            @click="hund.open = !hund.open"
         >
            <svg-chevron-right xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 transition-all duration-300 ease-in-out" :class="{ 'rotate-90': hund.open }" />
         </div>
   </div>
  </div>
  <!-- :class="{'bg-drc-ruede-mittel': hund.geschlecht.id == 2, 'bg-drc-huendin-mittel': hund.geschlecht.id == 1 }" -->
  <transition
    enter-active-class="enter-active"
    leave-active-class="leave-active"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <div v-if="hund.open">
      <div class="">
        <div
          class="grid w-full my-2 gap-1 !grid-cols-1"
          :class="{
            '!grid-cols-2': contentsize >= 800 && contentsize < 900,
            '!grid-cols-3': contentsize >= 900 && contentsize < 1600,
            '!grid-cols-hund4bild': contentsize >= 1600,
          }"
        >
          <div
            v-if="hund.images && !(hund.images.length == 0 && contentsize < 800)"
            class="col-start-1 row-start-1 bg-drc-grau-600 max-w-58"
            :class="{ '!row-span-2': contentsize >= 900 && contentsize < 1600 }"
          >
            <div class="flex flex-col flex-1 bg-drc-grau-500 min-w-45">
              <div
                class="relative flex items-center justify-center w-full h-full p-3"
                :class="{ 'p-10': hund.images.length == 0 }"
              >
                <img
                  v-if="hund.images.length > 0"
                  :src="storagepath + hund.images[0].thumb"
                  :alt="hund.name"
                  class="object-cover"
                />
                <svg
                  class="text-white"
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 32 20"
                >
                  <path
                    d="M25.7,3.6c-0.5-0.1-0.9-0.3-1.4-0.3c-0.7-0.1-1.3-0.2-2-0.3c-0.6-0.1-1.2-0.4-1.5-1c0-0.1-0.1-0.1-0.1-0.2
                                    c-0.6-1-1.7-1.6-2.8-1.6c-1.7-0.1-3.4,0.4-4.8,1.3c-1.1,0.6-1.6,1.8-1.3,3c0.1,0.3,0.3,0.6,0.5,0.9c1,1.2,2.1,2.3,3.3,3.3
                                    c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.3,0.7,0.1,0.8-0.3c0.1-0.3,0.2-0.7,0.2-1.1c0.1-1.7-0.2-3.4-0.8-5C16.1,2.3,16,2.2,15.9,2
                                    c-0.1-0.3,0-0.7,0.3-0.9c0.3-0.2,0.7-0.1,0.9,0.2c0.1,0.2,0.2,0.4,0.3,0.6c0.6,1.3,0.8,2.8,0.8,4.2c0,0.7,0,1.5-0.1,2.2
                                    c-0.1,0.7-0.5,1.4-1.1,1.7c-0.4,0.3-1,0.3-1.5,0.1c-0.5-0.3-1-0.6-1.4-1c-1-0.9-1.9-1.9-2.8-2.9c-0.6-0.6-0.9-1.3-1-2.1
                                    c-0.5,0.7-1,1.4-1.6,2c-0.7,0.8-2.1,2-2.1,2c3,9.5,8.9,11.4,8.9,11.4c0.4-1.2,1.1-3.3,1.5-4.5c0.6-1.8,1.2-3.5,1.9-5.3
                                    c0.1-0.2,0.4-0.4,0.6-0.4c0.7-0.1,1.4-0.2,2.1-0.3C22.5,9,23.3,8.8,24,8.5c0.9-0.5,1.6-1.3,1.8-2.4c0.2-0.6,0.2-1.3,0.4-1.9
                                    C26.2,4,26,3.7,25.7,3.6C25.7,3.6,25.7,3.6,25.7,3.6z"
                  />
                </svg>
                <button
                  v-if="hund.images.length > 0"
                  @click="startLightbox()"
                  class="absolute z-10 p-0 bg-white rounded-full opacity-75 bottom-4 right-4"
                >
                  <svg-spaltenansicht class="w-10 h-10 text-drc-grau-200" />
                </button>
              </div>
              <!-- <div v-if="hund.images[0]" class="flex pb-4 text-left min-w-45">
           
                        <div class="flex flex-col pt-1 pr-2 text-sm text-left text-drc-grau-200">
                           
                           <span v-if="hund.images[0].bu" class="text-sm"> {{ hund.images[0].bu }} </span>
                           <span v-else class="text-sm italic text-drc-grau-300">kein Eintrag </span>
                        </div>
                     </div> -->

              <template v-if="hund.images.length > 0">
                <div v-if="hund.images[0].bu" class="flex pb-4 text-left bg-white">
                  <div
                    class="flex flex-col py-2 px-4 text-sm text-left text-drc-grau-200"
                  >
                    <span class="text-sm italic"> {{ hund.images[0].bu }} </span>
                    <!-- <span v-else class="text-sm italic text-drc-grau-300">kein Eintrag </span> -->
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            class="bg-drc-grau-600 pb-4"
            :class="{ '!col-span-2': contentsize >= 900 && contentsize < 1600 }"
          >
            <div class="flex text-left min-w-45">
              <div class="w-10 p-1 mx-1 my-1 text-drc-grau-200">
                <svg-hund />
              </div>
              <div class="flex flex-col pt-4 pr-2 text-base text-left text-drc-grau-200">
                <span class="font-bold">{{ hund.name }}</span>
                <span class="font-bold">ZB-Nr.: {{ hund.zuchtbuchnummer }}</span>
                <div class="flex text-sm">
                  <div class="w-24 font-bold">Rasse:</div>
                  {{ hund.rasse.name }}
                </div>
                <div class="flex text-sm">
                  <div class="w-24 font-bold">Geschlecht:</div>
                  {{ hund.geschlecht.name}}
                </div>
                <div v-if="hund.rasse_id != 4" class="flex text-sm mb-2">
                  <div class="w-24 font-bold">Fellfarbe:</div>
                  {{ hund.farbe.name }}
                </div>
                <div v-if="hund.wurfdatum" class="flex text-sm">
                  <div class="w-24 font-bold">Wurfdatum:</div>
                  {{ hund.wurfdatum }}
                </div>
                <div v-if="hund.wurfart" class="flex text-sm mb-2">
                  <div class="w-24 font-bold">Zucht-Art:</div>
                  {{ hund.wurfart }}
                </div>
                <div v-if="hund.verstorben" class="flex text-sm mb-2">
                  <div class="w-24 font-bold">Verstorben:</div>
                  {{ hund.verstorben }}
                </div>
                <div
                  v-if="hund.zuchtbuchnummern.length > 1"
                  class="flex font-bold text-sm mt-2"
                >
                  Ehemalige Zuchtbuchnummern
                </div>
                <div v-if="hund.zuchtbuchnummern.length > 1" class="flex text-sm mb-2">
                  <div class="w-24 font-bold">ZB-Nrn.:</div>
                  <div class="flex flex-col">
                    <div
                      v-for="(zuchtbuchnummer, index) in hund.zuchtbuchnummern.slice(
                        1,
                        hund.zuchtbuchnummern.length
                      )"
                      :key="index"
                    >
                      {{ zuchtbuchnummer }}
                    </div>
                  </div>
                </div>
                <div v-if="hund.gstb_nr" class="flex text-sm mb-2">
                  <div class="w-24 font-bold">Stammbuchnummer (JGHV):</div>
                  {{ hund.gstb_nr }}
                </div>
                <div v-if="hund.drc_gstb_nr" class="flex text-sm mb-2">
                  <div class="w-24 font-bold">Stammbuchnummer (DRC):</div>
                  {{ hund.drc_gstb_nr }}
                </div>
                <div v-if="hund.zuchthund" class="flex text-sm mb-2">
                  <div class="w-24 font-bold">Zuchtzulassung seit:</div>
                  {{ hund.zzl_datum }}
                </div>
                <div v-if="hund.zuchtart" class="flex text-sm mb-2">
                  <div class="w-24 font-bold">Zuchtprädikat:</div>
                  {{ hund.zuchtart.name }}
                </div>
                <div v-if="hund.auflagen" class="flex text-sm mt-5 mb-2">
                  <div class="w-24 font-bold">Zucht-Auflagen:</div>
                  <div v-for="(auflage, index) in hund.auflagen" :key="index">
                    {{ auflage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-drc-grau-600 pb-4">
            <div class="flex text-left min-w-45">
              <div class="w-10 p-1 mx-1 my-1 text-drc-grau-200">
                <svg-meine-hunde />
              </div>
              <div class="flex flex-col pt-2 pr-2 text-base text-left text-drc-grau-200">
                <div class="font-bold pt-2">Züchter</div>
                <div class="flex flex-col my-2">
                  <div
                    v-if="hund.zuechter_nachname != '' || hund.herkunftszuchtstaette_name != ''"
                    class="text-sm"
                  >
                    <div>{{ hund.zuechter_vorname }} {{ hund.zuechter_nachname }}</div>
                    <div>{{ hund.herkunftszuchtstaette_name }}</div>
                    <div>{{ hund.herkunftszuchtstaette_plz }} {{ hund.herkunftszuchtstaette_ort }}</div>
                    <div>{{ hund.herkunftszuchtstaette_website }}</div>
                  </div>
                  <div v-else class="text-sm italic">unbekannt</div>
                </div>
                <div class="font-bold pt-2">Eltern</div>
                <div class="flex text-sm mt-2">
                  <div class="w-24 font-bold">Mutter:</div>
                  {{ hund.mutter_name }}
                </div>
                <div class="flex text-sm mb-2">
                  <div class="w-24 font-bold">ZB-Nr.:</div>
                  {{ hund.mutter_zuchtbuchnummer }}
                </div>
                <div class="flex text-sm mt-2">
                  <div class="w-24 font-bold">Vater:</div>
                  {{ hund.vater_name }}
                </div>
                <div class="flex text-sm mb-2">
                  <div class="w-24 font-bold">ZB-Nr.:</div>
                  {{ hund.vater_zuchtbuchnummer }}
                </div>
                <div class="flex text-sm mb-2">
                  <div v-if="hund.abstammungsnachweis" class="w-24 font-bold">
                    (Abstammungsnachweis)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="relative bg-drc-grau-600 pb-4">
            <div class="flex text-left min-w-45">
              <div class="w-10 p-1 mx-1 my-1 text-drc-grau-200">
                <svg-personen />
              </div>
              <div class="flex flex-col pt-2 pr-2 text-base text-left text-drc-grau-200">
                <div class="font-bold pt-2">Eigentümer</div>
                <template v-if="hund.eigentuemer">
                  <div
                    v-for="(eigentuemer, index) in hund.eigentuemer"
                    :key="index"
                    class="flex flex-col my-2"
                  >
                    <div class="text-sm">
                      {{ eigentuemer.vorname }} {{ eigentuemer.nachname }}
                    </div>
                    <div class="text-sm">
                      {{ eigentuemer.postleitzahl }} {{ eigentuemer.ort }}
                    </div>
                    <template v-if="hund.zuchthund">
                      <div v-if="eigentuemer.telefon_1" class="text-sm">
                        Telefon: {{ eigentuemer.telefon_1 }}
                      </div>
                      <div v-if="eigentuemer.telefon_2" class="text-sm">
                        Telefon: {{ eigentuemer.telefon_2 }}
                      </div>
                      <div v-if="eigentuemer.email_1" class="text-sm">
                        E-Mail:
                        <a :href="`mailto:` + eigentuemer.email_1">
                          {{ eigentuemer.email_1 }}</a
                        >
                      </div>
                      <div v-if="eigentuemer.email_2" class="text-sm">
                        E-Mail:
                        <a :href="`mailto:` + eigentuemer.email_2">
                          {{ eigentuemer.email_2 }}</a
                        >
                      </div>
                      <div v-if="eigentuemer.website_1" class="text-sm">
                        <a :href="'//' + eigentuemer.website_1" target="_blank">
                          {{ eigentuemer.website_1 }}</a
                        >
                      </div>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div class="flex flex-col my-2">
                    <div class="text-sm italic">unbekannt</div>
                    <div class="text-sm"></div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup>
import { ref , onMounted } from "vue";
import BButton from "@/components/global/buttons/BButton.vue";
import { date2german } from "@/helpers/date2german";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";

const storagepath = import.meta.env.VITE_APP_STORAGE;

const router = useRouter();
const route = useRoute();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

const emits = defineEmits(["selectHund", "showAhnentafel", "startLightbox"]);
// const details = computed(() => {
//    return hund.zucht || hund.hdeduntersuchung || hund.zahnstatus || hund.augenuntersuchung || hund.gentests || hund.pruefungen || hund.titel || hund.wesenstest || hund.formwert
// })

onMounted(() => {
  props.hund.open = props.open;
});

const props = defineProps({
  hund: {
    type: [Array, Object],
    default: null,
  },
  index: {
    type: [Number, String],
  },
  open: {
    type: Boolean,
    default: false,
  },
});

// const open = ref(false)

function selectHund() {
  emits("selectHund");
}

function showAhnentafel() {
  emits("showAhnentafel");
}

function startLightbox() {
  emits("startLightbox");
}

function beforeEnter(element) {
  requestAnimationFrame(() => {
    if (!element.style.height) {
      element.style.height = "0px";
    }

    element.style.display = null;
  });
}

function enter(element) {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      element.style.height = `${element.scrollHeight}px`;
    });
  });
}

function afterEnter(element) {
  element.style.height = null;
}

function beforeLeave(element) {
  requestAnimationFrame(() => {
    if (!element.style.height) {
      element.style.height = `${element.offsetHeight}px`;
    }
  });
}

function leave(element) {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      element.style.height = "0px";
    });
  });
}

function afterLeave(element) {
  element.style.height = null;
}
</script>

<style scoped lang="scss">
.enter-active,
.leave-active {
  overflow: hidden;
  transition: height 0.5s ease;
}
</style>
