<script>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination, FreeMode, Thumbs } from "swiper";
SwiperCore.use([Navigation, Pagination, FreeMode, Thumbs]);
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const storagepath = import.meta.env.VITE_APP_STORAGE;

    const lightbox = ref(false);
    const slide = ref(0);
    const { startslide } = props;
    let thumbslider = null;

    const onThumbSwiper = (swiper) => {
      thumbslider = swiper;
    };

    const mainslider = ref(null);

    const onMainSwiper = (swiper) => {
      mainslider.value = swiper;
      mainslider.value.slideTo(startslide);
    };

    const pagination = {
      clickable: true,
    };

    return {
      lightbox,
      slide,
      onThumbSwiper,
      onMainSwiper,
      pagination,
      thumbslider,
      mainslider,
      storagepath,
    };
  },
  props: {
    images: {
      type: Array,
      default: [],
    },
    startslide: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<template>
  <teleport to="body">
    <div class="fixed top-0 left-0 right-0 bottom-0 flex-col bg-black z-10000">
      <button
        title="Close"
        @click="$emit('close')"
        class="absolute p-2 rounded-full bg-drc-orange right-4 top-4 z-10000"
      >
        <svg-x
          class="w-10 h-10 fill-current"
          :class="{ 'text-drc-gruen-300': false, 'text-white': true }"
        />
      </button>

      <div
        class="relative flex text-white items-center justify-center w-full h-5/6 z-100"
      >
        <swiper
          @swiper="onMainSwiper"
          :slides-per-view="1"
          :space-between="50"
          :thumbs="{ swiper: thumbslider }"
          :pagination="pagination"
          navigation
          grab-cursor
          class="!w-full !h-full pb-10"
        >
          <swiper-slide
            class="flex col items-center justify-center w-full h-full"
            v-for="(image, index) in images"
            key="image.id"
          >
            <img
              :src="storagepath + image.path"
              :alt="image.bu"
              class="object-fit mx-auto"
            />
            <div
              v-if="image.bu"
              class="absolute px-4 py-2 text-sm text-white bg-black opacity-75 left-10 bottom-2"
            >
              {{ image.bu }}
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="relative flex items-center justify-center w-full h-1/6 z-100">
        <Swiper
          @swiper="onThumbSwiper"
          :spaceBetween="20"
          :slidesPerView="5"
          :freeMode="true"
          :watchSlidesProgress="true"
          class="relative !w-full"
        >
          <swiper-slide
            class="block w-full h-full bg-white"
            v-for="(image, index) in images"
            :key="images.id"
          >
            <img
              :src="storagepath + image.path"
              style="object-fit"
              @click.stop="mainslider.slideTo(index)"
            />
          </swiper-slide>
        </Swiper>
      </div>
    </div>
  </teleport>
</template>

<style>
/* The grid/column system */
.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  grid-auto-rows: max-content;
  grid-auto-flow: dense;
  gap: 10px;
}

.gridItem {
  width: auto;
}

.gridItem img {
  width: 100%;
  height: 100%;
  min-height: 180px;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.375);
  cursor: pointer;
}

.swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 30px;
}
.swiper-button-prev,
.swiper-button-next {
  color: #fff;
  background-color: #ffb100;
  padding: 10px;
  border-radius: 100%;
  width: 50px;
  height: 50px;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none;
}

.swiper-button-prev {
  left: 1rem;
}
.swiper-button-next {
  right: 1rem;
}

.swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  background: #ffb100;
}
</style>
