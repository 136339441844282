<template>
   <div class="mx-2 my-2 flex items-center group hover:bg-drc-orange transition-colors duration-150 bg-opacity-10" 
      :class="[ 'bg-'+color, { '!bg-opacity-50' : open || selected }]">
      <div class="p-2 grow flex justify-start items-center pl-4 text-sm text-left  cursor-pointer min-h-12" @click="open = !open;">
            <svg-plus v-if="!open" class="w-5 h-5 text-drc-grau-100" />
            <svg-minus v-else class="w-5 h-5 text-drc-grau-100" />
      
         <div v-if="daten.short" class="text-drc-grau-100 w-20 font-semibold ml-4 group-hover:text-white leading-5 flex" :class="{ '!text-drc-grau-100' : open || selected }"> {{ daten.short }} </div>
         <div class="text-drc-grau-100 grow font-semibold ml-4 group-hover:text-white leading-5 flex" :class="{ '!text-drc-grau-100' : open || selected }">
            <div v-if="daten.name">{{ daten.name }}
               <template v-if="daten.titel">
                  <br />
                  <span class="text-xs text-drc-grau-300" :class="{ '!text-drc-grau-100' : (open || selected) }">{{ daten.titel }}</span>
               </template>
            </div>
            <div v-if="selected" class="mx-5 rounded-full h-8 w-8 inline-flex justify-center items-center  bg-drc-marker-incomplete">{{ selected }}</div>
         </div>
      </div>
   </div> 
   <transition
      enter-active-class="enter-active"
      leave-active-class="leave-active"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
   >
      <div v-if="open" class="bg-white rounded mx-2">
      <div class="pt-4 pb-2 mb-5">
 <slot></slot>
         </div>
      </div>
   </transition>
</template>
<script setup>
import { ref , computed } from 'vue';

const open = defineModel('open', { type: [Boolean, Number], default: false })

const props = defineProps({

   daten: {
      type: Object,
      default: () => ({titel: '', name: '', short: '', color: 'drc-grau-200', textcolor: 'white', selected: '', open: false}),
   },

   titel: {
      type: String,
      default: '',
   },
   name: {
      type: String,
      default: '',
   },
   short: {
      type: String,
      default: '',
   },
   color: {
      type: String,
      default: 'drc-gruen-300',
   },
   textcolor: {
      type: String,
      default: 'white',
   },
   selected: {
      type: [Number, String],
      default: '',
   },
})



function beforeEnter(element) {
   requestAnimationFrame(() => {
      if (!element.style.height) {
         element.style.height = '0px'
      }

      element.style.display = null
   })
}

function enter(element) {
   requestAnimationFrame(() => {
      requestAnimationFrame(() => {
         element.style.height = `${element.scrollHeight}px`
      })
   })
}

function afterEnter(element) {
   element.style.height = null
}

function beforeLeave(element) {
   requestAnimationFrame(() => {
      if (!element.style.height) {
         element.style.height = `${element.offsetHeight}px`
      }
   })
}

function leave(element) {
   requestAnimationFrame(() => {
      requestAnimationFrame(() => {
         element.style.height = '0px'
      })
   })
}

function afterLeave(element) {
   element.style.height = null
}
</script>

<style scoped lang="scss">
.enter-active,
.leave-active {
   overflow: hidden;
   transition: height 0.5s ease;
}
</style>
