<template>

   <div class="mx-2 my-2 flex items-center  group hover:bg-drc-orange transition-colors duration-150 bg-opacity-10"
   :class="[ 'bg-'+color, { '!bg-opacity-50' : open || selected }]">
      <div class="p-2 grow flex justify-start items-center pl-4 text-sm text-left  cursor-pointer min-h-12"  @click="open = !open;">
     
               <svg-plus v-if="!open" class="w-5 h-5 text-drc-grau-100" />
               <svg-minus v-else class="w-5 h-5 text-drc-grau-100" />
       
 
            <div class="text-drc-grau-100 grow font-semibold ml-4 "
            :class="{ 'text-drc-grau-100' : open || selected }">
               {{ titel  }}
               <div v-if="selected" class="mx-5 rounded-full h-8 w-8 inline-flex justify-center items-center  bg-drc-marker-incomplete">{{ selected }}</div>
            </div>
      </div>
   </div>
   <transition
      enter-active-class="enter-active"
      leave-active-class="leave-active"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
   >
      <div v-if="open" class="bg-white rounded mx-2">
      <div class="pt-4 pb-2 mb-5">
         <b-checkbox v-for="datum in daten" :key="datum.id"
         @change="createSelectedString()"
               :label="datum.titel"
               :id="datum.id"
               inline
               v-model:value="datum.checked" />
         </div>
      </div>
   </transition>
</template>
<script setup>
import { ref , computed } from 'vue';

import BCheckbox from '@/components/global/checkboxes/BCheckbox.vue'
import BLabel from '@/components/global/BLabel.vue'
import BRadiobutton from '@/components/global/radios/BRadiobutton.vue'

const daten = defineModel('daten')
const open = defineModel('open', { type: [Boolean, Number], default: false })

const props = defineProps({

   titel: {
      type: String,
      default: '',
   },
   id: {
      type: String,
      default: '',
   },
   color: {
      type: String,
      default: 'drc-gruen-300',
   },
   textcolor: {
      type: String,
      default: 'white',
   },
})

// const open = ref(false);
const selected = computed(() => {
   let summe = 0
   for (const [key, value] of Object.entries(daten.value)) {
         summe += value.checked
   }
   return summe;
})

const selected_string = computed(() => {
   let summe = ''
   for (const [key, value] of Object.entries(daten.value)) {
      if (value.checked) summe += value.titel + ', ';
   }
   if (summe.length > 0) summe = summe.slice(0, -2);
   return summe;
})

function createSelectedString() {
  
}

function beforeEnter(element) {
   requestAnimationFrame(() => {
      if (!element.style.height) {
         element.style.height = '0px'
      }

      element.style.display = null
   })
}

function enter(element) {
   requestAnimationFrame(() => {
      requestAnimationFrame(() => {
         element.style.height = `${element.scrollHeight}px`
      })
   })
}

function afterEnter(element) {
   element.style.height = null
}

function beforeLeave(element) {
   requestAnimationFrame(() => {
      if (!element.style.height) {
         element.style.height = `${element.offsetHeight}px`
      }
   })
}

function leave(element) {
   requestAnimationFrame(() => {
      requestAnimationFrame(() => {
         element.style.height = '0px'
      })
   })
}

function afterLeave(element) {
   element.style.height = null
}
</script>

<style scoped lang="scss">
.enter-active,
.leave-active {
   overflow: hidden;
   transition: height 0.5s ease;
}
</style>
