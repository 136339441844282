<template>
  <div class="px-2">
   <div class="flex justify-start flex-wrap bg-drc-blau-300 bg-opacity-0 pt-2">  
         <b-button-setting label="Alle öffnen" bgcolor="drc-blau-300" icon="svg-plus" @buttonclick="openAll()" />
         <b-button-setting label="Alle schliessen" bgcolor="drc-blau-300" icon="svg-minus" @buttonclick="closeAll()" />
         <b-button-setting label="frei" bgcolor="drc-blau-300" icon="svg-checkbox-checked" @buttonclick="setAllFrei()" />
         <b-button-setting label="zweifelhaft" bgcolor="drc-blau-300" icon="svg-checkbox-checked" @buttonclick="setAllZweifelhaft()" />
         <b-button-setting label="nicht frei" bgcolor="drc-blau-300" icon="svg-checkbox-checked" @buttonclick="setAllNichtFrei()" />      
         <b-button-setting label="frei" bgcolor="drc-blau-300" icon="svg-checkbox-unchecked" @buttonclick="unsetAllFrei()" />
         <b-button-setting label="zweifelhaft" bgcolor="drc-blau-300" icon="svg-checkbox-unchecked" @buttonclick="unsetAllZweifelhaft()" />
         <b-button-setting label="nicht frei" bgcolor="drc-blau-300" icon="svg-checkbox-unchecked" @buttonclick="unsetAllNichtFrei()" />
      </div>
   </div>
   <div class="">
      <b-expand-bar-suchmodul :daten="augen.mpp" :selected="augen.mpp.selected" color="drc-blau-300" :open="augen.mpp.open">
         <div class="flex px-10">
                  <b-checkbox-clear class="w-32" id="mpp_frei" label="frei" v-model:value="augen.mpp.frei" inline @change="createSelectedString()" />
                  <b-checkbox-clear class="w-32" id="mpp_zweifelhaft" label="zweifelhaft" v-model:value="augen.mpp.zweifelhaft" inline @change="createSelectedString()" />
                  <b-checkbox-clear class="w-32" id="mpp_nicht_frei" label="nicht frei" v-model:value="augen.mpp.nichtfrei" inline @change="createSelectedString()" />
               </div>
               <div v-if="augen.mpp.nichtfrei || augen.mpp.zweifelhaft" class="">
                  <b-label label="Lokation" />
                  <div class="flex px-10">
                     <b-checkbox-clear class="w-32" id="mpp_iris" label="Iris" v-model:value="augen.mpp.iris" />
                     <b-checkbox-clear class="w-32" id="mpp_linse" label="Linse" v-model:value="augen.mpp.linse" />
                     <b-checkbox-clear class="w-32" id="mpp_kornea" label="kornea" v-model:value="augen.mpp.kornea" />
                     <b-checkbox-clear class="w-32" id="mpp_vorderkammer" label="Vorderkammer" v-model:value="augen.mpp.vorderkammer" />
                  </div>
               </div>  
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.phtvlphpv" :selected="augen.phtvlphpv.selected" color="drc-blau-300" :open="augen.phtvlphpv.open" >
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="phtvlphpv_frei" label="frei" v-model:value="augen.phtvlphpv.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="phtvlphpv_zweifelhaft" label="zweifelhaft" v-model:value="augen.phtvlphpv.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="phtvlphpv_nicht_frei" label="nicht frei" v-model:value="augen.phtvlphpv.nichtfrei" inline @change="createSelectedString()" />
         </div>
         <div v-if="augen.phtvlphpv.nichtfrei || augen.phtvlphpv.zweifelhaft" class="">
         <b-label label="Grad" />
         <div class="flex px-10">
            <b-checkbox-clear class="w-32" id="phtvlphpv_grad1" label="Grad 1" v-model:value="augen.phtvlphpv.grad1" />
            <b-checkbox-clear class="w-32" id="phtvlphpv_grad2" label="Grad 2" v-model:value="augen.phtvlphpv.grad2" />
            <b-checkbox-clear class="w-32" id="phtvlphpv_grad3" label="Grad 3" v-model:value="augen.phtvlphpv.grad3" />
            <b-checkbox-clear class="w-32" id="phtvlphpv_grad4" label="Grad 4" v-model:value="augen.phtvlphpv.grad4" />
            <b-checkbox-clear class="w-32" id="phtvlphpv_grad5" label="Grad 5" v-model:value="augen.phtvlphpv.grad5" />
            <b-checkbox-clear class="w-32" id="phtvlphpv_grad6" label="Grad 6" v-model:value="augen.phtvlphpv.grad6" />
         </div>
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.katarakt_kon" :selected="augen.katarakt_kon.selected" color="drc-blau-300" :open="augen.katarakt_kon.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="katarakt_kon_frei" label="frei" v-model:value="augen.katarakt_kon.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="katarakt_kon_zweifelhaft" label="zweifelhaft" v-model:value="augen.katarakt_kon.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="katarakt_kon_nicht_frei" label="nicht frei" v-model:value="augen.katarakt_kon.nichtfrei" inline @change="createSelectedString()" />
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.rd" :selected="augen.rd.selected" color="drc-blau-300" :open="augen.rd.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="rd_frei" label="frei" v-model:value="augen.rd.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="rd_zweifelhaft" label="zweifelhaft" v-model:value="augen.rd.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="rd_nicht_frei" label="nicht frei" v-model:value="augen.rd.nichtfrei" inline @change="createSelectedString()" />
         </div>
         <div v-if="augen.rd.nichtfrei || augen.rd.zweifelhaft" class="">
         <b-label label="Form" />
         <div class="flex px-10">
            <b-checkbox-clear class="w-32" id="rd_multifokal" label="(multi)fokal" v-model:value="augen.rd.multifokal" />
            <b-checkbox-clear class="w-32" id="rd_geo" label="geografisch" v-model:value="augen.rd.geo" />
            <b-checkbox-clear class="w-32" id="rd_total" label="total" v-model:value="augen.rd.total" />
         </div>
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.hypoplasie_mikropapille" :selected="augen.hypoplasie_mikropapille.selected" color="drc-blau-300" :open="augen.mpp.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="hypoplasie_mikropapille_frei" label="frei" v-model:value="augen.hypoplasie_mikropapille.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="hypoplasie_mikropapille_zweifelhaft" label="zweifelhaft" v-model:value="augen.hypoplasie_mikropapille.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="hypoplasie_mikropapille_nicht_frei" label="nicht frei" v-model:value="augen.hypoplasie_mikropapille.nichtfrei" inline @change="createSelectedString()" />
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.cea" :selected="augen.cea.selected" color="drc-blau-300"  :open="augen.cea.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="cea_frei" label="frei" v-model:value="augen.cea.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="cea_zweifelhaft" label="zweifelhaft" v-model:value="augen.cea.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="cea_nicht_frei" label="nicht frei" v-model:value="augen.cea.nichtfrei" inline @change="createSelectedString()" />
         </div>
         <div v-if="augen.cea.nichtfrei || augen.cea.zweifelhaft" class="">
         <b-label label="Art" />
         <div class="flex px-10">
            <b-checkbox-clear class="w-32" id="cea_choroidhypo" label="choroidhypo" v-model:value="augen.cea.choroidhypo" />
            <b-checkbox-clear class="w-32" id="cea_kolobom" label="kolobom" v-model:value="augen.cea.kolobom" />
            <b-checkbox-clear class="w-32" id="cea_sonstige" label="sonstige" v-model:value="augen.cea.sonstige" />
         </div>
         <b-input v-if="augen.cea.sonstige" label="Art Sonstige" v-model:value="augen.cea.sonstige_angaben" />
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.icaa" :selected="augen.icaa.selected" color="drc-blau-300" :open="augen.icaa.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="icaa_frei" label="frei" v-model:value="augen.icaa.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="icaa_zweifelhaft" label="zweifelhaft" v-model:value="augen.icaa.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="icaa_nicht_frei" label="nicht frei" v-model:value="augen.icaa.nichtfrei" inline @change="createSelectedString()" />
         </div>
         <div v-if="augen.icaa.nichtfrei || augen.icaa.zweifelhaft " class="">
         <b-label label="Grad" />
         <div class="flex px-10">
            <b-checkbox-clear class="w-32" id="icaa_kurztrabekel" label="kurztrabekel" v-model:value="augen.icaa.kurztrabekel" />
            <b-checkbox-clear class="w-32" id="icaa_gewebebruecken" label="gewebebruecken" v-model:value="augen.icaa.gewebebruecken" />
            <b-checkbox-clear class="w-32" id="icaa_totaldyspl" label="totaldyspl" v-model:value="augen.icaa.totaldyspl" />
         </div>
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.entropium" :selected="augen.entropium.selected" color="drc-blau-300" :open="augen.entropium.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="entropium_frei" label="frei" v-model:value="augen.entropium.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="entropium_zweifelhaft" label="zweifelhaft" v-model:value="augen.entropium.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="entropium_nicht_frei" label="nicht frei" v-model:value="augen.entropium.nichtfrei" inline @change="createSelectedString()" />
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.ektropium" :selected="augen.ektropium.selected" color="drc-blau-300" :open="augen.ektropium.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="ektropium_frei" label="frei" v-model:value="augen.ektropium.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="ektropium_zweifelhaft" label="zweifelhaft" v-model:value="augen.ektropium.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="ektropium_nicht_frei" label="nicht frei" v-model:value="augen.ektropium.nichtfrei" inline @change="createSelectedString()" />
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.distichiasis" :selected="augen.distichiasis.selected" color="drc-blau-300" :open="augen.distichiasis.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="distichiasis_frei" label="frei" v-model:value="augen.distichiasis.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="distichiasis_zweifelhaft" label="zweifelhaft" v-model:value="augen.distichiasis.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="distichiasis_nicht_frei" label="nicht frei" v-model:value="augen.distichiasis.nichtfrei" inline @change="createSelectedString()" />
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.korneadystrophie" :selected="augen.korneadystrophie.selected" color="drc-blau-300" :open="augen.korneadystrophie.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="korneadystrophie_frei" label="frei" v-model:value="augen.korneadystrophie.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="korneadystrophie_zweifelhaft" label="zweifelhaft" v-model:value="augen.korneadystrophie.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="korneadystrophie_nicht_frei" label="nicht frei" v-model:value="augen.korneadystrophie.nichtfrei" inline @change="createSelectedString()" />
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.katarakt_nonkon" :selected="augen.katarakt_nonkon.selected" color="drc-blau-300" :open="augen.katarakt_nonkon.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="katarakt_nonkon_frei" label="frei" v-model:value="augen.katarakt_nonkon.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="katarakt_nonkon_zweifelhaft" label="zweifelhaft" v-model:value="augen.katarakt_nonkon.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="katarakt_nonkon_nicht_frei" label="nicht frei" v-model:value="augen.katarakt_nonkon.nichtfrei" inline @change="createSelectedString()" />
         </div>
         <div v-if="augen.katarakt_nonkon.nichtfrei || augen.katarakt_nonkon.zweifelhaft" class="" >
         <b-label label="Lokation" />
         <div class="flex px-10">
            <b-checkbox-clear class="w-32" id="katarakt_cortikalis" label="cortikalis" v-model:value="augen.katarakt_nonkon.cortikalis" />
            <b-checkbox-clear class="w-32" id="katarakt_polpost" label="pol. post." v-model:value="augen.katarakt_nonkon.polpost" />
            <b-checkbox-clear class="w-32" id="katarakt_sutura_ant" label="sutura ant." v-model:value="augen.katarakt_nonkon.sutura_ant" />
            <b-checkbox-clear class="w-32" id="katarakt_punctata" label="punctata" v-model:value="augen.katarakt_nonkon.punctata" />
            <b-checkbox-clear class="w-32" id="katarakt_nuklearis" label="nuklearis" v-model:value="augen.katarakt_nonkon.nuklearis" />
            <b-checkbox-clear class="w-32" id="katarakt_sonstige" label="sonstige" v-model:value="augen.katarakt_nonkon.sonstige" />
         </div>
         <b-input v-if="augen.katarakt_nonkon.sonstige" label="Lokation Sonstige" v-model:value="augen.katarakt_nonkon.sonstige_angaben" />
         </div> 
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.linsenluxation" :selected="augen.linsenluxation.selected" color="drc-blau-300" :open="augen.linsenluxation.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="linsenluxation_frei" label="frei" v-model:value="augen.linsenluxation.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="linsenluxation_zweifelhaft" label="zweifelhaft" v-model:value="augen.linsenluxation.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="linsenluxation_nicht_frei" label="nicht frei" v-model:value="augen.linsenluxation.nichtfrei" inline @change="createSelectedString()" />
         </div>
      </b-expand-bar-suchmodul>

      <b-expand-bar-suchmodul :daten="augen.pra_rd" :selected="augen.pra_rd.selected" color="drc-blau-300" :open="augen.pra_rd.open">
         <div class="flex px-10">
         <b-checkbox-clear class="w-32" id="pra_frei" label="frei" v-model:value="augen.pra_rd.frei" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="pra_zweifelhaft" label="zweifelhaft" v-model:value="augen.pra_rd.zweifelhaft" inline @change="createSelectedString()" />
         <b-checkbox-clear class="w-32" id="pra_nicht_frei" label="nicht frei" v-model:value="augen.pra_rd.nichtfrei" inline @change="createSelectedString()" />
         </div>
      </b-expand-bar-suchmodul>

      <!-- <div class="flex flex-wrap">
         <div class="flex-1">
            <b-expand-bar-suchmodul v-for="a in augen" :key="a.id" :titel="a['titel']" :selected="a['frei']">
               <div class="flex px-10">
                  <b-checkbox-clear class="w-32" :id="a['id']+`_frei`" label="frei" v-model:value="a['frei']" inline @change="createSelectedString()" />
                  <b-checkbox-clear class="w-32" :id="a['id']+`_nicht frei`" label="nicht frei" v-model:value="a['nicht_frei']" inline @change="createSelectedString()" />
                  <b-checkbox-clear class="w-32" :id="a['id']+`_zweifelhaft`" label="zweifelhaft" v-model:value="a['zweifelhaft']" inline @change="createSelectedString()" />
               </div>
            </b-expand-bar-suchmodul>
         </div>
      </div> -->
   </div>
</template>
<script setup>
import { ref, computed } from "vue";
import _ from "lodash";
import axios from "axios";
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";


import BCheckbox from "@/components/global/checkboxes/BCheckbox.vue";
import BCheckboxClear from "@/components/global/checkboxes/BCheckboxClear.vue";
import BInput from "@/components/global/inputs/BInput.vue";
import BSelectMulti from "@/components/global/select/BSelectMulti.vue";
import BNumber from "@/components/global/inputs/BNumber.vue";
import BRadiobuttons from "@/components/global/radios/BRadiobuttons.vue";
import BHinweisfeld from "@/components/global/BHinweisfeld.vue";
import BInfopanel from "@/components/global/panels/BInfopanel.vue";
import BDatepicker3 from "@/components/global/datepickers/BDatepicker3.vue";
import BThementrenner from "@/components/global/BThementrenner.vue";
import BLabel from "@/components/global/BLabel.vue";
import BRadiobutton from "@/components/global/radios/BRadiobutton.vue";

import BExpandBarSuchmodul from "@/components/global/bars/BExpandBarSuchmodul.vue";

import BButtonSetting from "@/components/global/buttons/BButtonSetting.vue";

const { options } = useGlobalStore();
const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

const {
   augen,
   summe_checked_augen,
} = storeToRefs(sucheHundeStore);

const createSelectedString = () => {
   let selected = [];
   for (const [key, value] of Object.entries(augen.value)) {
      let summe = 0;
      if (value.frei) {
         selected.push(value.titel + " frei");
         summe++
      }
      if (value.zweifelhaft) {
         selected.push(value.titel + " zweifelhaft");
         summe++;
      }
      if (value.nichtfrei) {
         selected.push(value.titel + " nicht frei");
         summe++;
      }

      augen.value[key].selected = summe;
   }
   summe_checked_augen.value = selected.join(", ");

   // augen.value.mpp.selected = (augen.value.mpp.frei) + (augen.value.mpp.zweifelhaft) + (augen.value.mpp.nichtfrei);
   // augen.value.phtvlphpv.selected = (augen.value.phtvlphpv.frei) + (augen.value.phtvlphpv.zweifelhaft) + (augen.value.phtvlphpv.nichtfrei);
   // augen.value.katarakt_kon.selected = (augen.value.katarakt_kon.frei) + (augen.value.katarakt_kon.zweifelhaft) + (augen.value.katarakt_kon.nichtfrei);
   // augen.value.rd.selected = (augen.value.rd.frei) + (augen.value.rd.zweifelhaft) + (augen.value.rd.nichtfrei);
   // augen.value.hypoplasie_mikropapille.selected = (augen.value.hypoplasie_mikropapille.frei) + (augen.value.hypoplasie_mikropapille.zweifelhaft) + (augen.value.hypoplasie_mikropapille.nichtfrei);
   // augen.value.cea.selected = (augen.value.cea.frei) + (augen.value.cea.zweifelhaft) + (augen.value.cea.nichtfrei);
   // augen.value.icaa.selected = (augen.value.icaa.frei) + (augen.value.icaa.zweifelhaft) + (augen.value.icaa.nichtfrei);
   // augen.value.entropium.selected = (augen.value.entropium.frei) + (augen.value.entropium.zweifelhaft) + (augen.value.entropium.nichtfrei);
   // augen.value.ektropium.selected = (augen.value.ektropium.frei) + (augen.value.ektropium.zweifelhaft) + (augen.value.ektropium.nichtfrei);
   // augen.value.distichiasis.selected = (augen.value.distichiasis.frei) + (augen.value.distichiasis.zweifelhaft) + (augen.value.distichiasis.nichtfrei);
   // augen.value.korneadystrophie.selected = (augen.value.korneadystrophie.frei) + (augen.value.korneadystrophie.zweifelhaft) + (augen.value.korneadystrophie.nichtfrei);
   // augen.value.katarakt_nonkon.selected = (augen.value.katarakt_nonkon.frei) + (augen.value.katarakt_nonkon.zweifelhaft) + (augen.value.katarakt_nonkon.nichtfrei);
   // augen.value.linsenluxation.selected = (augen.value.linsenluxation.frei) + (augen.value.linsenluxation.zweifelhaft) + (augen.value.linsenluxation.nichtfrei);
   // augen.value.pra.selected = (augen.value.pra.frei) + (augen.value.pra.zweifelhaft) + (augen.value.pra.nichtfrei);

};



function openAll() {
   for (const [key, value] of Object.entries(augen.value)) {
         value.open = true;
   }
}

function closeAll() {
   for (const [key, value] of Object.entries(augen.value)) {
         value.open = false;
   }
}

function setAllFrei() {
   for (const [key, value] of Object.entries(augen.value)) {
         value.frei = true;
   }
   createSelectedString();
}  
function setAllNichtFrei() {
   for (const [key, value] of Object.entries(augen.value)) {
         value.nichtfrei = true;
   }
   createSelectedString();
}
function setAllZweifelhaft() {
   for (const [key, value] of Object.entries(augen.value)) {
         value.zweifelhaft = true;
   }
   createSelectedString();
}
function unsetAll() {
   for (const [key, value] of Object.entries(augen.value)) {
         value.frei = false;
         value.nichtfrei = false;
         value.zweifelhaft = false;
   }
   createSelectedString();
}  
function unsetAllFrei() {
   for (const [key, value] of Object.entries(augen.value)) {
         value.frei = false;
   }
}
function unsetAllNichtFrei() {
   for (const [key, value] of Object.entries(augen.value)) {
         value.nichtfrei = false;
   }
   createSelectedString();
}
function unsetAllZweifelhaft() {
   for (const [key, value] of Object.entries(augen.value)) {
         value.zweifelhaft = false;
   }
   createSelectedString();
}

</script>
<style></style>
