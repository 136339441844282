<template>
   <div class="px-2">
      <div class="flex justify-start flex-wrap bg-drc-marker-rot bg-opacity-0 pt-2">
         <b-button-setting label="Alle öffnen" bgcolor="drc-marker-rot" icon="svg-plus" @buttonclick="openAll()" />
         <b-button-setting label="Alle schliessen" bgcolor="drc-marker-rot" icon="svg-minus" @buttonclick="closeAll()" />
         <b-button-setting label="Alle auswählen" bgcolor="drc-marker-rot" icon="svg-checkbox-checked" @buttonclick="setAll()" />
         <b-button-setting label="Alle abwählen" bgcolor="drc-marker-rot" icon="svg-checkbox-unchecked" @buttonclick="unsetAll()" />
      </div>
   </div>
   <div class="flex flex-wrap">
      <div class="flex-1">
         <b-expand-bar-suchmodul-checkboxes :daten="auflagen.gelenke" titel="Gelenke" :open="expands.auflagen.gelenke" color="drc-marker-rot" />
         <b-expand-bar-suchmodul-checkboxes :daten="auflagen.augen" titel="Augen" :open="expands.auflagen.augen" color="drc-marker-rot" />
         <b-expand-bar-suchmodul-checkboxes :daten="auflagen.genetik" titel="Genetik" :open="expands.auflagen.genetik" color="drc-marker-rot" />
         <b-expand-bar-suchmodul-checkboxes :daten="auflagen.pruefungen" titel="Prüfungen" :open="expands.auflagen.pruefungen" color="drc-marker-rot" />
         <b-expand-bar-suchmodul-checkboxes :daten="auflagen.zahnstatus" titel="Zähne & Gebiss" :open="expands.auflagen.zahnstatus" color="drc-marker-rot" />
         <b-expand-bar-suchmodul-checkboxes :daten="auflagen.sonstige" titel="Sonstige" :open="expands.auflagen.sonstige" color="drc-marker-rot" />
      </div>
   
   </div>
</template>
<script setup>
import { ref, computed } from "vue";
import _ from "lodash";
import axios from "axios";
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";


import BCheckbox from "@/components/global/checkboxes/BCheckbox.vue";
import BCheckboxClear from "@/components/global/checkboxes/BCheckboxClear.vue";
import BInput from "@/components/global/inputs/BInput.vue";
import BSelectMulti from "@/components/global/select/BSelectMulti.vue";
import BNumber from "@/components/global/inputs/BNumber.vue";
import BRadiobuttons from "@/components/global/radios/BRadiobuttons.vue";
import BHinweisfeld from "@/components/global/BHinweisfeld.vue";
import BInfopanel from "@/components/global/panels/BInfopanel.vue";
import BDatepicker3 from "@/components/global/datepickers/BDatepicker3.vue";
import BThementrenner from "@/components/global/BThementrenner.vue";
import BLabel from "@/components/global/BLabel.vue";
import BRadiobutton from "@/components/global/radios/BRadiobutton.vue";
import BButtonSetting from "@/components/global/buttons/BButtonSetting.vue";

import BExpandBarSuchmodulCheckboxes from "@/components/global/bars/BExpandBarSuchmodulCheckboxes.vue";
import BExpandBarSuchmodul from "@/components/global/bars/BExpandBarSuchmodul.vue";


const { options } = useGlobalStore();
const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

const {
   auflagen,
   summe_checked_auflagen,
   expands
} = storeToRefs(sucheHundeStore);


function openAll() {
   for (const [key, value] of Object.entries(expands.value.auflagen)) {
      expands.value.auflagen.gelenke = true;
      expands.value.auflagen[key] = true;
   }
}

function closeAll() {
   for (const [key, value] of Object.entries(expands.value.auflagen)) {
      expands.value.auflagen[key] = false;
   }
}

function setAll() {
   for (const [key, value] of Object.entries(auflagen.value)) {
      for (const [key, ivalue] of Object.entries(value)) {
         ivalue.checked = true;
      }
   }
}

function unsetAll() {
   for (const [key, value] of Object.entries(auflagen.value)) {
      for (const [key, ivalue] of Object.entries(value)) {
         ivalue.checked = false;
      }
   }
}


</script>
<style></style>
