<template>
   <div class="flex flex-col justify-start" :class="{ 'w-90 flex-row ml-5 desktop:ml-10': horizontal, 'w-full mx-5 mb-4 desktop:mx-10': !horizontal }">
      <label class="block font-medium text-left text-drc-gruen-300">
         {{ label }}
         <b-tooltip :tooltip="tooltip"/>
      </label>
   </div>

</template>

<script>
import BTooltip from "@/components/global/BTooltip.vue";

export default {
   components: { BTooltip },
   props: {
      label: {
         type: String,
         default: ""
      },
      tooltip:  {
         type: Object,
         default: {
            title: '',
            text: ''
         }
      },
      horizontal: {
         type: Boolean,
         default: false,
      },
   },
   data() {
      return {};
   }
};
</script>

<style lang="scss">
</style>
