import axios from 'axios'
import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { fi, ka, sv } from 'date-fns/locale'


export const useSucheHundeStore = defineStore('sucheHunde', () => {

   // INITALE DATENANLAGE:
   // 
   // Wurf, Züchter, Zwinger, Zuchthündin, Deckrüden, Welpen, Zuchtwart, Wurfabnahmebericht

   const ergebnislisteHunde = ref({})
   const ergebnisText = ref()


   const selectedHunde = ref([])

   const filterHunde = ref({
      hundegruppe: null,
      name: '',
      zuchtbuchnummer: '',
      typ: '',
      geschlecht_id: 0,
      wuerfe: null,
      nachkommen: null,
      zuchttauglichkeit_id: null,
      rasse_id: 0,
      fellfarbe: { id: 0, name: 'Alle Farben' },
      alter_max: null,
      alter_min: null,
      plz: '',
      umkreis: '',
   })

   const expands = ref({
      auflagen: { gelenke: true, augen: true, genetik: true, pruefungen: true, zahnstatus: true, sonstige: true },
      gelenke: { hd_scoring: true, ed_scoring: true },
      erweitertesuche: true,
      einfachesuche: true,
      detailsuche: true,
      zahnstatus: { zaehne: true, gebiss: true },
      augen: false,
      genetik: false,
      leistung: true,
      pruefungen: { jagdliche_pruefungen: true, nicht_jagdliche_pruefungen: true },
      titel: true,
      bar: {
         auflagen: true,
         gelenke: true,
         augen: true,
         genetik: true,
         pruefungen: true,
         zahnstatus: true,
         sonstige: true,
         titel: true,
      }
   })


   const auflagen = ref({
      gelenke: {
         hd: { checked: 0, mit: 0, ohne: 0, id: 'hd', titel: 'Hüftdysplasie', name: 'Hüftdysplasie', short: 'HD' },
         ed: { checked: 0, mit: 0, ohne: 0, id: 'ed', titel: 'Ellenbogendysplasie', name: 'Ellenbogendysplasie', short: 'ED' },
      },
      augen: {
         hc: { checked: 0, mit: 0, ohne: 0, id: 'hc', titel: 'Heritärer Katarakt', name: 'Heritärer Katarakt', short: 'HC' },
         pra: { checked: 0, mit: 0, ohne: 0, id: 'pra', titel: 'Progressive Retina Atrophie', name: 'Progressive Retina Atrophie', short: 'PRA' },
         gonio: { checked: 0, mit: 0, ohne: 0, id: 'gonio', titel: 'Gonioskopie', name: 'Gonioskopie', short: 'Gonio' },
         rd: { checked: 0, mit: 0, ohne: 0, id: 'rd', titel: 'Retinadysplasie', name: 'Entwicklungsstörung der Retina', short: 'RD' },
      },
      genetik: {
         prcd_pra: { checked: 0, mit: 0, ohne: 0, id: 'prcd_pra', titel: 'Progressive Rod-Cone Degeneration', name: 'Progressive Stäbchen-Zapfen Degeneration', short: 'PRA' },
         cnm_gentest: { checked: 0, mit: 0, ohne: 0, open: false, id: 'cnm_gentest', titel: 'Centronuclear Myopathy', name: 'Erbliche Zentronukleäre Myopathie', short: 'CNM', selected: '' },
         eic_gentest: { checked: 0, mit: 0, ohne: 0, open: false, id: 'eic_gentest', titel: 'Exercise Induced Collapse', name: 'Anstrengungs-induzierter Kollaps', short: 'EIC', selected: '' },
         dm_gentest: { checked: 0, mit: 0, ohne: 0, open: false, id: 'dm_gentest', titel: 'Degenerative Myelopathy', name: 'Degenerative Myleopathie', short: 'DM', selected: '' },
         sd2_gentest: { checked: 0, mit: 0, ohne: 0, open: false, id: 'sd2_gentest', titel: 'Skeletal Dysplasia 2', name: 'Skeletale Dysplasie 2', short: 'SD2', selected: '' },
         gr_pra1: { checked: 0, mit: 0, ohne: 0, open: false, id: 'gr_pra1_gentest', titel: 'Progressive retinal atrophy type 1 - Golden Retriever', name: 'Progressiven Retinaatrophie Typ I - Golden Retriever', short: 'GR-PRA1', selected: '' },
         gr_pra2: { checked: 0, mit: 0, ohne: 0, open: false, id: 'gr_pra2_gentest', titel: 'Progressive retinal atrophy type 2 - Golden Retriever', name: 'Progressiven Retinaatrophie Typ II  - Golden Retriever', short: 'GR-PRA2', selected: '' },
         hnpk: { checked: 0, mit: 0, ohne: 0, open: false, id: 'hnpk_gentest', titel: 'HHereditary Nasal Parakeratosis', name: 'Hereditäre Nasale Parakeratose', short: 'HNPK', selected: '' },
      },
      pruefungen: {
         pruefungen: { checked: 0, mit: 0, ohne: 0, id: 'pruefungen', titel: 'Prüfungen', name: 'Prüfungen', short: 'Prüfungen' },
         leistung: { checked: 0, mit: 0, ohne: 0, id: 'leistung', titel: 'Leistung', name: 'Leistung', short: 'Leistung' },
      },
      zahnstatus: {
         zaehne: { checked: 0, mit: 0, ohne: 0, id: 'gebiss', titel: 'Zähne', name: 'Zähne', short: 'Zähne' },
         gebiss: { checked: 0, mit: 0, ohne: 0, id: 'gebiss', titel: 'Gebiss', name: 'Gebiss', short: 'Gebiss' },
      },
      // Sonstige 
      sonstige: { 
         sonstige: { checked: 0, mit: 0, ohne: 0, id: 'sonst', titel: 'Sonstige', name: 'Sonstige', short: 'Sonst' },
       },
   })

   const summe_checked_auflagen = computed(() => {
      let checked = 0

      for (const [key, value] of Object.entries(auflagen.value)) {
         for (const [key, value] of Object.entries(value)) {
            checked += value.checked
         }
      }

      return checked
   })

   const gelenke = ref({
      hd_scoring: {
         meta: { titel: '', name: 'Hüftdysplasie', short: 'HD', selected: '', open: false },
         drcfci: {
            a: 0, b: 0, c: 0, d: 0, e: 0, id: 'drcfci', titel: 'DRC/FCI-Scoring', name: 'Hüftdysplasie', short: 'HD', selected: '', open: false
         },
         ofa: {
            keine: 0, id: 'ofa', titel: 'OFA-Scoring', name: 'Hüftdysplasie', short: 'HD', selected: '', open: false
         },
         hs: {
            keine: 0, id: 'hs', titel: 'HS-Scoring', name: 'Hüftdysplasie', short: 'HD', selected: '', open: false
         },
         ch: {
            keine: 0, id: 'ch', titel: 'CH-Scoring', name: 'Hüftdysplasie', short: 'HD', selected: '', open: false
         },
         nl: {
            keine: 0, id: 'nl', titel: 'NL-Scoring', name: 'Hüftdysplasie', short: 'HD', selected: '', open: false
         },
         sw: {
            keine: 0, id: 'sw', titel: 'SW-Scoring', name: 'Hüftdysplasie', short: 'HD', selected: '', open: false
         },
         fi: {
            keine: 0, id: 'fi', titel: 'FI-Scoring', name: 'Hüftdysplasie', short: 'HD', selected: '', open: false
         },
         sv: {
            keine: 0, id: 'sv', titel: 'SV-Scoring', name: 'Hüftdysplasie', short: 'HD', selected: '', open: false
         },
      },
      ed_scoring: {
         meta: { titel: '', name: 'Ellenbogendysplasie', short: 'ED', selected: '', open: false },
         drc: {
            frei: 0, grenzfall: 0, grad1: 0, grad2: 0, grad3: 0, id: 'drc', titel: 'DRC-Scoring', name: 'Ellenbogendysplasie', short: 'ED', selected: '', open: false
            // frei: { checked: 0, id: 'ed_frei', titel: 'frei' },
            // grenzfall: { checked: 0, id: 'ed_grenzfall', titel: 'Grenzfall' },
            // grad1: { checked: 0, id: 'ed_grad1', titel: 'Grad 1' },
            // grad2: { checked: 0, id: 'ed_grad2', titel: 'Grad 2' },
            // grad3: { checked: 0, id: 'ed_grad3', titel: 'Grad 3' },
         },
         fci: {
            null: 0, eins: 0, zwei: 0, drei: 0, id: 'fci', titel: 'FCI-Scoring', name: 'Ellenbogendysplasie', short: 'ED', selected: '', open: false
         },
         ofel: {
            keine: 0, id: 'ofel', titel: 'OFEL-Scoring', name: 'Ellenbogendysplasie', short: 'ED', selected: '', open: false
         },
         lang: {
            keine: 0, id: 'lang', titel: 'LANG-Scoring', name: 'Ellenbogendysplasie', short: 'ED', selected: '', open: false
         },
      }
   })

   const summe_checked_gelenke = computed(() => {
      let hd_checked = 0
      let ed_checked = 0

      for (const [key, value] of Object.entries(gelenke.value.hd_scoring.drcfci)) {
         if (value.checked > 0) {
            hd_checked = 1
            break
         }
      }

      for (const [key, value] of Object.entries(gelenke.value.ed_scoring.drc)) {
         if (value.checked > 0) {
            ed_checked = 1
            break
         }
      }

      for (const [key, value] of Object.entries(gelenke.value.ed_scoring.fci)) {
         if (value.checked > 0) {
            ed_checked = 1
            break
         }
      }

      return hd_checked + ed_checked
   })


   const augen = ref({
      mpp: { frei: 0, nichtfrei: 0, zweifelhaft: 0, iris: 0, linse: 0, kornea: 0, vorderkammer: 0, id: 'mpp', titel: 'Membrana pupillaris persistens', name: 'Persistierende Pupillarmembran', short: 'MPP', selected: '', open: false },
      hc: { frei: 0, nichtfrei: 0, zweifelhaft: 0, id: 'hc', titel: 'Hereditäre Katarakt', name: 'Hereditäre Katarakt', short: 'HC', selected: '', open: false },
      phtvlphpv: { frei: 0, nichtfrei: 0, zweifelhaft: 0, grad1: 0, grad2: 0, grad3: 0, grad4: 0, grad5: 0, grad6: 0, id: 'phtvlphpv', titel: 'Persistierende Hyperplastische Tunica Vasculosa Lentis/Persistierende Hyperplastische primäre Vitreus', name: 'Persistierende Hyperplastische Tunica Vasculosa Lentis/Persistierende Hyperplastische primäre Vitreus', short: 'PHTVL/PHPV', selected: '', open: false },
      katarakt_kon: { frei: 0, nichtfrei: 0, zweifelhaft: 0, id: 'katarakt_kongenital', titel: 'Kongenitale Katarakt', name: 'Kongenitale Katarakt', short: 'Katarakt', selected: '', open: false },
      rd: { frei: 0, nichtfrei: 0, zweifelhaft: 0, multifokal: 0, geo: 0, total: 0, id: 'rd', titel: 'Retinadysplasie', name: 'Entwicklungsstörung der Retina', short: 'RD', selected: '', open: false },
      hypoplasie_mikropapille: { frei: 0, nichtfrei: 0, zweifelhaft: 0, id: 'hypoplasie_mikropapille', titel: 'Hypoplasie/Mikropapille', name: 'Hypoplasie/Mikropapille', short: 'HP/MP', selected: '', open: false },
      cea: { frei: 0, nichtfrei: 0, zweifelhaft: 0, choroidhypo: 0, kolobom: 0, sonstige: 0, id: 'cea', titel: 'Collie Eye Anomaly', name: 'Collie Eye Anomaly', short: 'CEA', selected: '', open: false },
      icaa: { frei: 0, nichtfrei: 0, zweifelhaft: 0, kurztrabekel: 0, gewebebruecken: 0, totaldyspl: 0, id: 'icaa', titel: 'Iris-Ciliary-Abnormalität', name: 'Iris-Ciliary-Abnormalität', short: 'ICAA', selected: '', open: false },
      entropium: { frei: 0, nichtfrei: 0, zweifelhaft: 0, id: 'entropium', titel: 'Entropium', name: 'Augenlidfehlstellung', short: 'Entropium', selected: '', open: false },
      ektropium: { frei: 0, nichtfrei: 0, zweifelhaft: 0, id: 'ektropium', titel: 'Ektropium', name: 'Hängelid', short: 'Ektropium', selected: '', open: false },
      distichiasis: { frei: 0, nichtfrei: 0, zweifelhaft: 0, id: 'distichiasis', titel: 'Distichiasis', name: 'Distichiasis', short: 'Distichiasis', selected: '', open: false },
      korneadystrophie: { frei: 0, nichtfrei: 0, zweifelhaft: 0, id: 'korndystrophie', titel: 'Korneadystrophie', name: 'Korneadystrophie', short: 'KD', selected: '', open: false },
      katarakt_nonkon: { frei: 0, nichtfrei: 0, zweifelhaft: 0, cortikalis: 0, polpost: 0, sutura_ant: 0, punctata: 0, nuklearis: 0, sonstige: 0, id: 'katarakt_nonkon', titel: 'Nicht-kongenitale Katarakt', name: 'Nicht-kongenitale Katarakt', short: 'Katarakt', selected: '', open: false },
      linsenluxation: { frei: 0, nichtfrei: 0, zweifelhaft: 0, id: 'linsenluxation', titel: 'Linsenluxation', name: 'Linsenluxation', short: 'Linsenluxation', selected: '', open: false },
      pra_rd: { frei: 0, nichtfrei: 0, zweifelhaft: 0, id: 'pra_rd', titel: 'Progressive Retina-Atrophie', name: 'Progressive Retina-Atrophie', short: 'PRA', selected: '', open: false },

   })

   const summe_checked_augen = computed(() => {
      let summe = 0
      for (const [key, value] of Object.entries(augen.value)) {
         summe += (value.frei || value.nichtfrei || value.zweifelhaft)
      }
      return summe
   })

   const pruefungen = ref({
      jagdliche_pruefungen: {
         jas: { checked: 0, id: 'jas', titel: 'Jagdsachverständigenprüfung', short: 'JAS' },
         blp: { checked: 0, id: 'blp', titel: 'Bringleistungsprüfung', short: 'BLP' },
         rgp: { checked: 0, id: 'rgp', titel: 'Retrievergebrauchsprüfung', short: 'RGP' },
         pns: { checked: 0, id: 'pns', titel: 'Prüfung nach dem Schuss', short: 'PNS' },
         hpr: { checked: 0, id: 'hpr', titel: 'Dr. Heraeus-Gedächtnis-Prüfung', short: 'HP/R' },
         hzp: { checked: 0, id: 'hzp', titel: 'Herbstzuchtprüfung', short: 'HZP' },
         jep: { checked: 0, id: 'jep', titel: 'Jagdeignungsprüfung', short: 'JEP' },
         vjp: { checked: 0, id: 'vjp', titel: 'Verbandsjugendprüfung', short: 'VJP' },
         vswp: { checked: 0, id: 'vswp', titel: 'Verbandsschweißprüfung', short: 'VSwP' },
         vgp: { checked: 0, id: 'vgp', titel: 'Verbandsgebrauchsprüfung', short: 'VGP' },
         btr: { checked: 0, id: 'btr', titel: 'Bringtreueprüfung', short: 'Btr' },
      },
      nicht_jagdliche_pruefungen: {
         wesenstest: { checked: 0, id: 'wesenstest', titel: 'Wesenstest', short: 'Wesenstest' },
         bhp: { checked: 0, id: 'bhp', titel: 'Begleithundeprüfung', short: 'BHP' },
         working: { checked: 0, id: 'working', titel: 'Workingtest', short: 'WT' },
         apd: { checked: 0, id: 'apd', titel: 'Arbeitsprüfung für Dummies', short: 'APD/R' },
      },
   })

   const summe_checked_pruefungen = computed(() => {

      let summe = 0

      for (const [key, value] of Object.entries(pruefungen.value.nicht_jagdliche_pruefungen)) {
         summe += value.checked
      }

      for (const [key, value] of Object.entries(pruefungen.value.jagdliche_pruefungen)) {
         summe += value.checked
      }

      return summe
   })


   const titels = ref({
      dt_champion: { checked: 0, id: 'dt_champion', titel: 'Deutscher Champion' },
      vdh_champion: { checked: 0, id: 'vdh_champion', titel: 'VDH-Champion' },
      jugend_champion: { checked: 0, id: 'jugend_champion', titel: 'Jugend-Champion' },
      dt_arbeitschampion: { checked: 0, id: 'dt_arbeitschampion', titel: 'Deutscher Arbeitschampion' },
      dt_jagdchampion: { checked: 0, id: 'dt_jagdchampion', titel: 'Deutscher Jagdchampion' },
      auslaendische: { checked: 0, id: 'auslaendische', titel: 'Ausländische Championate' },

   })

   const summe_checked_titels = computed(() => {
      let summe = 0
      for (const [key, value] of Object.entries(titels.value)) {
         summe += value.checked
      }
      return summe
   })



   const gentests = ref({
      pra_test_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'pra_test', titel: 'Progressive Rod-Cone Degeneration', name: 'Progressive Stäbchen-Zapfen Degeneration', short: 'PRA-PRCD', selected: '' },
      cnm_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'cnm_gentest', titel: 'Centronuclear Myopathy', name: 'Erbliche Zentronukleäre Myopathie', short: 'CNM', selected: '' },
      eic_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'eic_gentest', titel: 'Exercise Induced Collapse', name: 'Anstrengungs-induzierter Kollaps', short: 'EIC', selected: '' },
      dm_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'dm_gentest', titel: 'Degenerative Myelopathy', name: 'Degenerative Myleopathie', short: 'DM', selected: '' },
      sd2_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'sd2_gentest', titel: 'Skeletal Dysplasia 2', name: 'Skeletale Dysplasie 2', short: 'SD2', selected: '' },
      narc_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'narc_gentest', titel: 'Narcolepsy', name: 'Narkolepsie', short: 'NARC', selected: '' },
      rd_osd_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'rd_osd_gentest', titel: 'Retinal Dysplasia', name: 'Retina Dysplasie', short: 'RD/OSD', selected: '' },
      cea_ch_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'cea_ch_gentest', titel: 'Collie Eye Anomaly', name: 'Augenanomalie bei Collies', short: 'CEA/CH', selected: '' },
      gr_pra1_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'gr_pra1_gentest', titel: 'Progressive retinal atrophy type 1 - Golden Retriever', name: 'Progressiven Retinaatrophie Typ I - Golden Retriever', short: 'GR-PRA1', selected: '' },
      gr_pra2_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'gr_pra2_gentest', titel: 'Progressive retinal atrophy type 2 - Golden Retriever', name: 'Progressiven Retinaatrophie Typ II  - Golden Retriever', short: 'GR-PRA2', selected: '' },
      haarlaenge_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'haarlaenge', titel: 'Hair length / Coat type L-Locus, FGF5', name: 'Haarlänge', short: 'L-Lokus', selected: '' },
      gsdiiia_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'gsdiiia_gentest', titel: 'Glycogen Storage Disease IIIa', name: 'Glykogenspeicherkrankheit Typ 3a', short: 'GSDIIIA', selected: '' },
      grmd_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'grmd_gentest', titel: 'Golden Retriever Muscle Dystrophy', name: 'Golden Retriever Muskelschwund', short: 'GRMD', selected: '' },
      ict_a_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'ict_a_gentest', titel: 'Ichthyose', name: 'Fischschuppenkrankheit', short: 'ICT-A', selected: '' },
      ed_sfs_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'ed_sfs_gentest', titel: 'Ectodermal Dysplasia / Skin Fragility Syndrome', name: 'Ektodermale Dysplasie', short: 'ED/SFS', selected: '' },
      hnpk_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'hnpk_gentest', titel: 'HHereditary Nasal Parakeratosis', name: 'Hereditäre Nasale Parakeratose', short: 'HNPK', selected: '' },
      ncl5_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'ncl5_gentest', titel: 'Neuronale Ceroid Lipofuszinose 5', name: 'Neuronale Zeroidlipofuszinose 5', short: 'NCL5', selected: '' },
      farbtest_gelb_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'farbtest_gelb', titel: 'Extension', name: 'Fellfarbe Gelb', short: 'E-Lokus', selected: '' },
      farbtest_braun_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'farbtest_braun', titel: 'Brown', name: 'Fellfarbe Braun', short: 'B-Lokus', selected: '' },
      farbverduennung_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'farbverduennung', titel: 'Dilution', name: 'Farbverdünnung', short: 'DIL', selected: '' },
      den_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'den_gentest', titel: 'Degenerative Encephalopathy', name: 'Degenerative Encephalopathie', short: 'DEN', selected: '' },
      jadd_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'jadd_gentest', titel: 'Juvenile Addisons Disease', name: 'Juvenile Addison Krankheit', short: 'JADD', selected: '' },
      cp1_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'cp1_gentest', titel: 'Cleft Palate 1', name: 'Gaumenspalte', short: 'CP1', selected: '' },
      clps_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'clps_gentest', titel: 'Cleft Lip Palate and Syndactyly ', name: 'Lippen-Kiefer-Gaumenspalte und Syndaktylie', short: 'CLPS', selected: '' },
      cms_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'cms_gentest', titel: 'Kongenitale Myastheniesyndrome', name: 'Kongenitale Myasthenie', short: 'CMS', selected: '' },
      mh_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'mh_gentest', titel: 'Malignant Hyperthermia', name: 'Maligne Hyperthermie', short: 'MH', selected: '' },
      cddy_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'cddy_gentest', titel: 'Chondrodystrophy', name: 'Kurzbeinigkeit mit Bandscheibendegeneration', short: 'CDDY', selected: '' },
      ivdd_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'ivdd_gentest', titel: 'Intervertebral Disc Disease', name: 'Bandscheibenvorfall', short: 'IVDD', selected: '' },
      cdpa_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'cdpa_gentest', titel: 'Chrondrodysplasie', name: 'Kurzbeinigkeit', short: 'CDPA', selected: '' },   // Rasse?
      huu_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'huu_gentest', titel: 'Hyperuricosuria and Hyperuricemia', name: 'Hyperurikosurie und Hyperurikämie', short: 'HUU', selected: '' },
      deb_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'deb_gentest', titel: 'Epidermolysis Bullosa Dystrophica', name: 'Dystrophische Epidermolysis Bullosa', short: 'DEB', selected: '' },
      buff_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'buff_gentest', titel: 'Buff / Dilution', name: 'Fellfarbe D-Lokus', short: 'BUFF', selected: '' },
      mdr1_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'mdr1_gentest', titel: 'Multi Drug Resistance', name: 'Multiple Medikament Resistenz', short: 'MDR1', selected: '' },
      md_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'md_gentest', titel: 'Muscle Dystrophy', name: 'Muskelschwund', short: 'MD', selected: '' },
      cord1_pra_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'cord1_pra_gentest', titel: 'Cone-Rod Dystrophy - Progressive retinal atrophy', name: 'Progressive Retinaatrophie', short: 'CORD1-PRA', selected: '' },
      glasknochen_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'glasknochen_gentest', titel: 'Osteogenesis Imperfecta', name: 'Glasknochenkrankheit', short: 'OI', selected: '' },
      stgd_gentest_id: { frei: 0, traeger: 0, betroffen: 0, erbgangfrei: 0, open: false, id: 'stgd_gentest', titel: 'Stargardt Disease', name: 'Stargardt Krankheit', short: 'STGD', selected: '' },
   })

   const summe_checked_gentests = computed(() => {
      let summe = 0
      for (const [key, value] of Object.entries(gentests.value)) {
         summe += (value.frei || value.traeger || value.betroffen || value.erbgangfrei)
      }
      return summe

   })

   const summe_checked_basisdaten = computed(() => {
      return 0;
      // let summe = 0
      // for (const [key, value] of Object.entries(filterHunde.value)) {
      //       summe += value
      // }
      // return summe
   })

   const zahnstatus = ref({
      zaehne: { 
         vollständig: { checked: 0, id: 'vollstaendig', titel: 'Zähne', name: 'Zähne vollständig', short: 'Voll' },
         fehlend1: { checked: 0, id: 'fehlend1', titel: '1 Zahn fehlt', name: '1 Zahn fehlt', short: '-1' },
         fehlend2: { checked: 0, id: 'fehlend2', titel: '2 Zähne fehlen', name: '2 Zähne fehlen', short: '-2' },
         fehlend3: { checked: 0, id: 'fehlend3', titel: '3 Zähne fehlen', name: '3 Zähne fehlen', short: '-3' },
         fehlend4: { checked: 0, id: 'fehlend4', titel: '4 Zähne fehlen', name: '4 Zähne fehlen', short: '-4' },
         fehlend5: { checked: 0, id: 'fehlend5', titel: '5 Zähne fehlen', name: '5 Zähne fehlen', short: '-5' },
         fehlend6: { checked: 0, id: 'fehlend6', titel: '6 Zähne fehlen', name: '6 Zähne fehlen', short: '-6' },
         extrahierte: { checked: 0, id: 'extrahierte', titel: 'Extrahierte Zähne', name: 'Extrahierte Zähne', short: 'Ex' },
      },
      gebiss: { 
         normal: { checked: 0, id: 'normal', titel: 'Normal', name: 'Normal', short: 'Normal' },
         vorbiss: { checked: 0, id: 'vorbiss', titel: 'Vorbiss', name: 'Vorbiss', short: 'Vorbiss' },
         rückbiss: { checked: 0, id: 'rückbiss', titel: 'Rückbiss', name: 'Rückbiss', short: 'Rückbiss' },
         kreuzbiss: { checked: 0, id: 'kreuzbiss', titel: 'Kreuzbiss', name: 'Kreuzbiss', short: 'Kreuzbiss' },
         scherengebiss: { checked: 0, id: 'scherengebiss', titel: 'Scherengebiss', name: 'Scherengebiss', short: 'Scherengebiss' },
         zangengebiss: { checked: 0, id: 'zangengebiss', titel: 'Zangengebiss', name: 'Zangengebiss', short: 'Zangengebiss' },
       },
   })

   const summe_checked_zahnstatus = computed(() => {
      let summe = 0
      for (const [key, value] of Object.entries(zahnstatus.value.zaehne)) {
         summe += value.checked
      }

      for (const [key, value] of Object.entries(zahnstatus.value.gebiss)) {
         summe += value.checked
      }
      return summe


   })



   const anzahlSelectedHunde = computed(() => {
      return selectedHunde.value.length
   })



   return { ergebnislisteHunde, selectedHunde, filterHunde, anzahlSelectedHunde, ergebnisText, zahnstatus, expands, auflagen, gelenke, augen, pruefungen, titels, gentests, summe_checked_auflagen, summe_checked_gentests, summe_checked_augen, summe_checked_pruefungen, summe_checked_basisdaten, summe_checked_titels, summe_checked_gelenke, summe_checked_zahnstatus, }
})


