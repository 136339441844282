<template>
  <div class="flex flex-col justify-start mx-5 desktop:mx-10">
    <label class="block text-left text-drc-gruen-300" :for="id">
      {{ label }} <b-tooltip :tooltip="tooltip" />
    </label>
    <VueMultiselect
      :modelValue="value"
      @update:modelValue="
        value = $event;
        updateModel();
      "
      :options="opt"
      :searchable="searchable"
      :close-on-select="true"
      :allow-empty="true"
      :multiple="multiple"
      :mode="mode"
      @search-change="getOptions"
      :disabled="disabled"
      :placeholder="placeholder"
      track-by="id"
      :name="name"
      :custom-name="getName"
      :id="id"
      :special="special"
      :exact="exact"
    >
    </VueMultiselect>
    <template v-if="hinweise">
      <p
        v-for="hinweis in hinweise"
        class="mb-4 -mt-2 text-xs italic text-left"
        :class="{
          'text-red-500': hinweis.type == 'error',
          'text-drc-gruen-300': hinweis.type == 'warning',
          'text-black': hinweis.type == 'info',
        }"
      >
        {{ hinweis.text }}
      </p>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import VueMultiselect from "@/components/global/select/Multiselect.vue";
import axios from "axios";
import BTooltip from "@/components/global/BTooltip.vue";

const emits = defineEmits(["update:value", "select"]);

const value = defineModel( 'value', {
    type: [String, Array, Object],
    default: [],
  })

const props = defineProps({
  options: {
    type: [String, Array, Object],
    default: [],
  },

  mode: {
    type: String,
    default: "list",
  },
  label: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "Bitte auswählen",
  },
  id: {
    type: String,
    default: "",
  },
  hinweise: {
    type: [String, Array],
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: "",
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  searchurl: {
    type: String,
    default: "",
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  filters: {
    type: [String, Array],
    default: "",
  },
  autoattrib: {
    type: String,
    default: "name",
  },
  special: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: Object,
    default: {
      title: "",
      text: "",
    },
  },
  exact: {
    type: Boolean,
    default: false,
  },
});

const source = ref([]);
const timeout = ref(null);

const opt = computed(() => {
  console.log;
  return props.searchurl && props.source.length !== 0 ? source.value : props.options;
});

onMounted(() => {
  if (props.options.length == 1) {
    console.log("1 Option");
    //  this.modelValue = this.options;
  }

  let findOption = props.options.find((option) => option.id === value.value.id);
});

function getOptions(query) {
  if (query.length > 2) {
    clearTimeout(timeout.value);
    // Starte nach 500ms Verzögerung nach dem letzten Tastendruck
    timeout.value = setTimeout(function () {
      // if ( !this.multiple ) vm.$emit('update:modelValue', '' )
      axios
        .post(props.searchurl, {
          suche: query,
          main: props.autoattrib,
          filters: props.filters,
        })
        .then((response) => {
          if (response.data.data.length !== 0) props.source = response.data.data; // else vm.source = vm.modelValue;
        });
    }, 500);
  } else {
    //   vm.source = vm.modelValue
  }
}

function getName({ name }) {
  return `${name}`;
}

function updateModel(e) {
  console.log("updateModel:" + value.value);
  emits("select", value.value);
}





</script>

<style lang="scss"></style>
