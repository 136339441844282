<template>
  <div
    v-if="open"
    class="fixed top-0 left-0 right-0 flex items-center justify-center h-full overflow-auto z-100000 modal bg-white"
  >
    <div class="absolute top-0 left-0 right-0 h-full bg-white z-100000">
      <div class="flex">
        <b-expand-panel-ahne-titel
          :hund="ahnen['hund']"
          @close="
            close();
            emit('close');
          "
          @reload_prev="reload_prev"
          id="0"
          group="0"
          class="bg-white tablet:hidden smartphone:hidden"
        >
          <template v-slot:vater>
            <b-expand-panel-ahne-horizontal-overlay
              :hund="ahnen['hund']['vater']"
              @reload="reload"
              id="1"
              group="1"
            >
              <template v-slot:vater>
                <b-expand-panel-ahne-horizontal-overlay
                  :hund="ahnen['hund']['vater']['vater']"
                  @reload="reload"
                  id="2"
                  group="2"
                >
                  <template v-slot:vater>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['vater']['vater']['vater']"
                      @reload="reload"
                      id="3"
                      group="3"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['vater']['vater']['vater']"
                          @reload="reload"
                          id="4"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['vater']['vater']['mutter']"
                          @reload="reload"
                          id="5"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                  <template v-slot:mutter>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['vater']['vater']['mutter']"
                      @reload="reload"
                      id="6"
                      group="3"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['vater']['mutter']['vater']"
                          @reload="reload"
                          id="7"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['vater']['mutter']['mutter']"
                          @reload="reload"
                          id="8"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                </b-expand-panel-ahne-horizontal-overlay>
              </template>
              <template v-slot:mutter>
                <b-expand-panel-ahne-horizontal-overlay
                  :hund="ahnen['hund']['vater']['mutter']"
                  @reload="reload"
                  id="9"
                  group="2"
                >
                  <template v-slot:vater>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['vater']['mutter']['vater']"
                      @reload="reload"
                      id="10"
                      group="3"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['mutter']['vater']['vater']"
                          @reload="reload"
                          id="11"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['mutter']['vater']['mutter']"
                          @reload="reload"
                          id="12"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                  <template v-slot:mutter>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['vater']['mutter']['mutter']"
                      @reload="reload"
                      id="13"
                      group="3"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['mutter']['mutter']['vater']"
                          id="14"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['mutter']['mutter']['mutter']"
                          @reload="reload"
                          id="15"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                </b-expand-panel-ahne-horizontal-overlay>
              </template>
            </b-expand-panel-ahne-horizontal-overlay>
          </template>
          <template v-slot:mutter>
            <b-expand-panel-ahne-horizontal-overlay
              :hund="ahnen['hund']['mutter']"
              @reload="reload"
              id="16"
              group="11"
            >
              <template v-slot:vater>
                <b-expand-panel-ahne-horizontal-overlay
                  :hund="ahnen['hund']['mutter']['vater']"
                  @reload="reload"
                  id="17"
                  group="12"
                >
                  <template v-slot:vater>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['mutter']['vater']['vater']"
                      @reload="reload"
                      id="18"
                      group="13"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['vater']['vater']['vater']"
                          @reload="reload"
                          id="19"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['vater']['vater']['mutter']"
                          @reload="reload"
                          id="20"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                  <template v-slot:mutter>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['mutter']['vater']['mutter']"
                      @reload="reload"
                      id="21"
                      group="13"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['vater']['mutter']['vater']"
                          @reload="reload"
                          id="22"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['vater']['mutter']['mutter']"
                          @reload="reload"
                          id="23"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                </b-expand-panel-ahne-horizontal-overlay>
              </template>
              <template v-slot:mutter>
                <b-expand-panel-ahne-horizontal-overlay
                  :hund="ahnen['hund']['mutter']['mutter']"
                  @reload="reload"
                  id="24"
                  group="12"
                >
                  <template v-slot:vater>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['mutter']['mutter']['vater']"
                      @reload="reload"
                      id="25"
                      group="13"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['mutter']['vater']['vater']"
                          @reload="reload"
                          id="26"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['mutter']['vater']['mutter']"
                          id="27"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                  <template v-slot:mutter>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['mutter']['mutter']['mutter']"
                      @reload="reload"
                      id="28"
                      group="13"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['mutter']['mutter']['vater']"
                          id="29"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['mutter']['mutter']['mutter']"
                          id="30"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                </b-expand-panel-ahne-horizontal-overlay>
              </template>
            </b-expand-panel-ahne-horizontal-overlay>
          </template>
        </b-expand-panel-ahne-titel>
        <b-expand-panel-ahne-titel
          :hund="ahnen['hund']"
          @close="
            close();
            emit('close');
          "
          @reload_prev="reload_prev"
          id="0"
          class="bg-white desktop:hidden"
        >
          <template v-slot:vater>
            <b-expand-panel-ahne-vertical-overlay
              :hund="ahnen['hund']['vater']"
              @reload="reload"
              id="1"
              group="1"
            >
              <template v-slot:vater>
                <b-expand-panel-ahne-vertical-overlay
                  :hund="ahnen['hund']['vater']['vater']"
                  @reload="reload"
                  id="2"
                  group="2"
                >
                  <template v-slot:vater>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['vater']['vater']['vater']"
                      @reload="reload"
                      id="3"
                      group="3"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['vater']['vater']['vater']"
                          @reload="reload"
                          id="4"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['vater']['vater']['mutter']"
                          @reload="reload"
                          id="5"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                  <template v-slot:mutter>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['vater']['vater']['mutter']"
                      @reload="reload"
                      id="6"
                      group="3"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['vater']['mutter']['vater']"
                          @reload="reload"
                          id="7"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['vater']['mutter']['mutter']"
                          @reload="reload"
                          id="8"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                </b-expand-panel-ahne-vertical-overlay>
              </template>
              <template v-slot:mutter>
                <b-expand-panel-ahne-vertical-overlay
                  :hund="ahnen['hund']['vater']['mutter']"
                  @reload="reload"
                  id="9"
                  group="2"
                >
                  <template v-slot:vater>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['vater']['mutter']['vater']"
                      @reload="reload"
                      id="10"
                      group="3"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['mutter']['vater']['vater']"
                          @reload="reload"
                          id="11"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['mutter']['vater']['mutter']"
                          @reload="reload"
                          id="12"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                  <template v-slot:mutter>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['vater']['mutter']['mutter']"
                      @reload="reload"
                      id="13"
                      group="3"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['mutter']['mutter']['vater']"
                          id="14"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['vater']['mutter']['mutter']['mutter']"
                          @reload="reload"
                          id="15"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                </b-expand-panel-ahne-vertical-overlay>
              </template>
            </b-expand-panel-ahne-vertical-overlay>
          </template>
          <template v-slot:mutter>
            <b-expand-panel-ahne-vertical-overlay
              :hund="ahnen['hund']['mutter']"
              @reload="reload"
              id="16"
              group="11"
            >
              <template v-slot:vater>
                <b-expand-panel-ahne-vertical-overlay
                  :hund="ahnen['hund']['mutter']['vater']"
                  @reload="reload"
                  id="17"
                  group="12"
                >
                  <template v-slot:vater>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['mutter']['vater']['vater']"
                      @reload="reload"
                      id="18"
                      group="13"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['vater']['vater']['vater']"
                          @reload="reload"
                          id="19"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['vater']['vater']['mutter']"
                          @reload="reload"
                          id="20"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                  <template v-slot:mutter>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['mutter']['vater']['mutter']"
                      @reload="reload"
                      id="21"
                      group="13"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['vater']['mutter']['vater']"
                          @reload="reload"
                          id="22"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['vater']['mutter']['mutter']"
                          @reload="reload"
                          id="23"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                </b-expand-panel-ahne-vertical-overlay>
              </template>
              <template v-slot:mutter>
                <b-expand-panel-ahne-vertical-overlay
                  :hund="ahnen['hund']['mutter']['mutter']"
                  @reload="reload"
                  id="24"
                  group="12"
                >
                  <template v-slot:vater>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['mutter']['mutter']['vater']"
                      @reload="reload"
                      id="25"
                      group="13"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['mutter']['vater']['vater']"
                          @reload="reload"
                          id="26"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['mutter']['vater']['mutter']"
                          id="27"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                  <template v-slot:mutter>
                    <b-expand-panel-ahne-vertical-overlay
                      :hund="ahnen['hund']['mutter']['mutter']['mutter']"
                      id="28"
                      group="13"
                    >
                      <template v-slot:vater>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['mutter']['mutter']['vater']"
                          id="29"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                      <template v-slot:mutter>
                        <b-expand-panel-ahne-vertical-overlay
                          :hund="ahnen['hund']['mutter']['mutter']['mutter']['mutter']"
                          id="30"
                        >
                        </b-expand-panel-ahne-vertical-overlay>
                      </template>
                    </b-expand-panel-ahne-vertical-overlay>
                  </template>
                </b-expand-panel-ahne-vertical-overlay>
              </template>
            </b-expand-panel-ahne-vertical-overlay>
          </template>
        </b-expand-panel-ahne-titel>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, reactive } from "vue";
import axios from "axios";
import { ls_open, ls_details, at_history, history_pointer } from "./localstore";
import BExpandPanelAhneTitel from "@/components/ahnentafel/BExpandPanelAhneTitel";
import BExpandPanelAhneHorizontalOverlay from "@/components/ahnentafel/BExpandPanelAhneHorizontalOverlay";
import BExpandPanelAhneVerticalOverlay from "@/components/ahnentafel/BExpandPanelAhneVerticalOverlay";

const emit = defineEmits(["close"], ["reload_prev"]);

const props = defineProps({
  ahnen: {
    type: Object,
  },
  hund: {
    type: Number,
  },
  mutter: {
    type: Number,
  },
  vater: {
    type: Number,
  },
  id: {
    type: Number,
  },
});

onMounted(() => {
  if (id > 0) {
    getAhnen(id);
    document.body.classList.add("nooverflow");
    init();
  }
});

onUnmounted(() => {
  document.body.classList.remove("nooverflow");
});

const history = at_history;
const opens = ls_open;
const details = ls_details;

function init() {
  opens.forEach((bool, idx) => {
    opens[idx] = true;
  });
  details.forEach((bool, idx) => {
    details[idx] = false;
  });
}

const open = ref(false);
const id = props.id;

function close() {
  open.value = false;
}

const ahnen = reactive({ hund: {} });

function getAhnen() {
  axios.get("/api/ahnentafel/single/" + id).then((response) => {
    ahnen.hund = response.data.data;
    open.value = true;
    history.push(id);
  });
}

function reload(e) {
  axios.get("/api/ahnentafel/single/" + e).then((response) => {
    ahnen.hund = response.data.data;
    open.value = true;
    history.push(e);
    history_pointer.value = history.length;
  });
}

function reload_prev() {
  var e = history.pop();
  e = history[history.length - 1];

  axios.get("/api/ahnentafel/single/" + e).then((response) => {
    ahnen.hund = response.data.data;
    open.value = true;
  });
}
</script>

<style>
.nooverflow {
  overflow: hidden;
}
</style>
