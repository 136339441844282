<template>
   <b-trenner />
   <b-label label="Geschlecht" />
      <b-radiobuttons id="geschlecht" v-model:value="filterHunde.geschlecht_id" :options="optgeschlecht" inline />
      <b-trenner />
      <b-label label="Rasse" />
      <div class="flex w-full flex-col desktopmenu:flex-row">
         <div class="flex desktopmenu:w-1/2 justify-around w-full my-2">
            <button class="flex flex-col justify-start items-center w-28 desktop:w-40" @click="
                  filterHunde.rasse_id == 4
                    ? (filterHunde.rasse_id = 0)
                    : (filterHunde.rasse_id = 4)
                ">
               <div :class="{
                    'border-drc-gruen-300 ring-2 ring-drc-gruen-300':
                      filterHunde.rasse_id == 4,
                  }" class="rounded-full w-28 h-28 border-4 border-drc-grau-400 smartphone:w-20 smartphone:h-20 desktop:w-40 desktop:h-40 inline-flex justify-start items-center bg-white mb-2">
                  <img src="@/assets/img/thumb_frei_golden.png" class="w-full" />
               </div>
               <span :class="{ 'text-drc-gruen-300 font-bold': filterHunde.rasse_id == 4 }" class="text-sm">Golden Retriever</span>
            </button>
            <button class="flex flex-col justify-start items-center w-28 desktop:w-40" @click="
                  filterHunde.rasse_id == 5
                    ? (filterHunde.rasse_id = 0)
                    : (filterHunde.rasse_id = 5)
                ">
               <div :class="{
                    'border-drc-gruen-300 ring-2 ring-drc-gruen-300':
                      filterHunde.rasse_id == 5,
                  }" class="rounded-full w-28 h-28 border-4 border-drc-grau-400 smartphone:w-20 smartphone:h-20 desktop:w-40 desktop:h-40 inline-flex justify-start items-center bg-white mb-2">
                  <img src="@/assets/img/thumb_frei_labrador.png" class="w-full" />
               </div>
               <span :class="{ 'text-drc-gruen-300 font-bold': filterHunde.rasse_id == 5 }" class="text-sm">Labrador Retriever</span>
            </button>
            <button class="flex flex-col justify-start items-center w-28 desktop:w-40" @click="
                  filterHunde.rasse_id == 3
                    ? (filterHunde.rasse_id = 0)
                    : (filterHunde.rasse_id = 3)
                ">
               <div :class="{
                    'border-drc-gruen-300 ring-2 ring-drc-gruen-300':
                      filterHunde.rasse_id == 3,
                  }" class="rounded-full w-28 h-28 border-4 border-drc-grau-400 smartphone:w-20 smartphone:h-20 desktop:w-40 desktop:h-40 inline-flex justify-start items-center bg-white mb-2">
                  <img src="@/assets/img/thumb_frei_flat.png" class="w-full" />
               </div>
               <span :class="{ 'text-drc-gruen-300 font-bold': filterHunde.rasse_id == 3 }" class="text-sm">Flat-Coated Retriever</span>
            </button>
         </div>
         <div class="flex desktopmenu:w-1/2 justify-around w-full my-2">
            <button class="flex flex-col justify-start items-center w-28 desktop:w-40" @click="
                  filterHunde.rasse_id == 6
                    ? (filterHunde.rasse_id = 0)
                    : (filterHunde.rasse_id = 6)
                ">
               <div :class="{
                    'border-drc-gruen-300 ring-2 ring-drc-gruen-300':
                      filterHunde.rasse_id == 6,
                  }" class="rounded-full w-28 h-28 border-4 border-drc-grau-400 smartphone:w-20 smartphone:h-20 desktop:w-40 desktop:h-40 inline-flex justify-start items-center bg-white mb-2">
                  <img src="@/assets/img/thumb_frei_nsdtr.png" class="w-full" />
               </div>
               <span :class="{ 'text-drc-gruen-300 font-bold': filterHunde.rasse_id == 6 }" class="text-sm">Nova-Scotia-Duck-Tolling Retriever</span>
            </button>
            <button class="flex flex-col justify-start items-center w-28 desktop:w-40" @click="
                  filterHunde.rasse_id == 1
                    ? (filterHunde.rasse_id = 0)
                    : (filterHunde.rasse_id = 1)
                ">
               <div :class="{
                    'border-drc-gruen-300 ring-2 ring-drc-gruen-300':
                      filterHunde.rasse_id == 1,
                  }" class="rounded-full w-28 h-28 border-4 border-drc-grau-400 smartphone:w-20 smartphone:h-20 desktop:w-40 desktop:h-40 inline-flex justify-start items-center bg-white mb-2">
                  <img src="@/assets/img/thumb_frei_chessie.png" class="w-full" />
               </div>
               <span :class="{ 'text-drc-gruen-300 font-bold': filterHunde.rasse_id == 1 }" class="text-sm">Chesapeake-Bay Retriever</span>
            </button>
            <button class="flex flex-col justify-start items-center w-28 desktop:w-40" @click="
                  filterHunde.rasse_id == 2
                    ? (filterHunde.rasse_id = 0)
                    : (filterHunde.rasse_id = 2)
                ">
               <div :class="{
                    'border-drc-gruen-300 ring-2 ring-drc-gruen-300':
                      filterHunde.rasse_id == 2,
                  }" class="rounded-full w-28 h-28 border-4 border-drc-grau-400 smartphone:w-20 smartphone:h-20 desktop:w-40 desktop:h-40 inline-flex justify-start items-center bg-white mb-2">
                  <img src="@/assets/img/thumb_frei_curly.png" class="w-full" />
               </div>
               <span :class="{ 'text-drc-gruen-300 font-bold': filterHunde.rasse_id == 2 }" class="text-sm">Curly-Coated Retriever</span>
            </button>
         </div>
      </div>
      <b-trenner />
         <div class="flex flex-wrap mb-10">
            <div class="flex-1">
               <b-select-multi label="Fellfarbe" type="text" v-model:value="filterHunde.fellfarbe" :options="
                  filterHunde.rasse_id > 0
                    ? options.farben[filterHunde.rasse_id]
                    : options.farben[0]
                " />
            </div>
            <div class="flex-1"></div>
         </div>
      
         <b-trenner />
         <div class="flex flex-wrap mb-10">
            <div class="flex-1">
               <b-number v-model="filterHunde.alter_min" label="Mindestalter (Jahre)" min="0" max="30" size="middle" center controls />
            </div>
            <div class="flex-1">
               <b-number v-model="filterHunde.alter_max" label="Höchstalter (Jahre)" min="0" max="30" size="middle" center controls />
            </div>
         </div>
     <template v-if="1">
      <b-trenner  />
      <b-label label="Zucht" />
         <b-radiobuttons id="hunde" v-model:value="filterHunde.hundegruppe" :options="opthunde"  left inline />
         <b-label label="Geeignet für" />
         <b-radiobuttons id="zuchttauglichkeiten" v-model:value="filterHunde.zuchttauglichkeit_id" :options="options.zuchttauglichkeiten" inline />
         <div class="flex flex-wrap mb-10">
            <div class="flex-1">
               <b-number v-model="filterHunde.alter_min" label="Mindestanzahl Nachkommen" min="0" max="500" size="middle" center controls />
            </div>
         </div>
      <b-trenner />
      <b-label label="Umkreissuche" />
         <div class="flex flex-wrap">
            <div class="flex-1">
               <b-input label="Postleitzahl" type="text" v-model:value="filterHunde.plz" @change="
                  if (filterHunde.plz != '' && filterHunde.umkreis != '') sucheHunde();
                " />
            </div>
            <div class="flex-1">
               <b-input label="Umkreis (km)" type="text" v-model:value="filterHunde.umkreis" @change="
                  if (filterHunde.plz != '' && filterHunde.umkreis != '') sucheHunde();
                " />
            </div>
         </div>
         </template>
</template>
<script setup>
import { ref, reactive, computed, onMounted, watch } from "vue";
import _ from "lodash";
import axios from "axios";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";

import Tab from "@/components/global/tabs/Tab.vue";
import TabsDynamic from "@/components/global/tabs/TabsDynamic.vue";
import TabsIcon from "@/components/global/tabs/TabsIcon.vue";
import TabIcon from "@/components/global/tabs/TabIcon.vue";
import BInput from "@/components/global/inputs/BInput.vue";
import BSelectMulti from "@/components/global/select/BSelectMulti.vue";
import BNumber from "@/components/global/inputs/BNumber.vue";
import BTrenner from "@/components/global/BTrenner.vue";
import BTooltip from "@/components/global/BTooltip.vue";
import BTextarea from "@/components/global/inputs/BTextarea.vue";
import BExpandBarHund from "@/components/global/bars/BExpandBarHund.vue";
import BExpandBarSuche from "@/components/global/bars/BExpandBarSuche.vue";
import BExpandBarSucheNeutral from "@/components/global/bars/BExpandBarSucheNeutral.vue";
import BExpandPanel from "@/components/global/panels/BExpandPanel.vue";
import BCheckbox from "@/components/global/checkboxes/BCheckbox.vue";
import BCheckboxClear from "@/components/global/checkboxes/BCheckboxClear.vue";
import BRadiobuttons from "@/components/global/radios/BRadiobuttons.vue";
import BHinweisfeld from "@/components/global/BHinweisfeld.vue";
import BInfopanel from "@/components/global/panels/BInfopanel.vue";
import BDatepicker3 from "@/components/global/datepickers/BDatepicker3.vue";
import BButton from "@/components/global/buttons/BButton.vue";
import BButtonSolo from "@/components/global/buttons/BButtonSolo.vue";
import BThementrenner from "@/components/global/BThementrenner.vue";
import BLabel from "@/components/global/BLabel.vue";
import BRadiobutton from "@/components/global/radios/BRadiobutton.vue";
import BAhnentafelOverlaySingle from "@/components/ahnentafel/BAhnentafelOverlaySingle.vue";
import BLoader from "@/components/global/BLoader.vue";
import BLightbox from "@/components/global/bslider/BLightbox.vue";
import BRadiobuttonsZahn from "@/components/global/radios/BRadiobuttonsZahn.vue";


import Favorite from "@/components/hunde/suche/Favorite.vue";
import Auflagen from "@/components/hunde/suche/Auflagen.vue";
import Augen from "@/components/hunde/suche/Augen.vue";
import Zahnstatus from "@/components/hunde/suche/Zahnstatus.vue";
import Gelenke from "@/components/hunde/suche/Gelenke.vue";
import Genetik from "@/components/hunde/suche/Genetik.vue";
import Pruefungen from "@/components/hunde/suche/Pruefungen.vue";
import Titel from "@/components/hunde/suche/Titel.vue";


onMounted(() => {
});

const storagepath = import.meta.env.VITE_APP_STORAGE;

const open = ref(false);
const router = useRouter();
const route = useRoute();
const { options } = useGlobalStore();
const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

// sucheStore.$reset()
const {
   filterHunde,
   expands

} = storeToRefs(sucheHundeStore);

const hundetabs = ref(null);

const opthunde = ref([
   { id: 1, name: "Alle Zuchthunde" },
   { id: 2, name: "Zuchthündinnen (DRC)" },
   { id: 3, name: "Deckrüden (DRC)" },
   { id: 4, name: "Deckrüden (freigestellt)" },
]);
const optgeschlecht = ref([
   { id: 0, name: "Hündinnen und Rüden" },
   { id: 1, name: "Hündinnen" },
   { id: 2, name: "Rüden" },
]);

watch(
   () => sucheHundeStore.filterHunde.rasse_id,
   (new_id) => {
      let index = options.farben[new_id].findIndex(
         (farbe) => farbe == filterHunde.value.fellfarbe.id
      );
      if (index === -1) {
         filterHunde.value.fellfarbe = { id: 0, name: "Alle Farben" };
      }
   }
);

</script>
<style></style>
