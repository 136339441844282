<template>
      <div v-if="contentsize > 700" class="">
         <tabs-icon ref="iTabs" :options="{ disableScrollBehavior: true }">
            <tab-icon id="auflagen" title="Auflagen" textcolor="black" icon="svg-zucht" iconcolor="drc-marker-rot" color="drc-marker-rot" :badge="summe_checked_gelenke" isActivated>
               <auflagen />
            </tab-icon>
            <tab-icon id="gelenke" title="Gelenke" textcolor="black" icon="svg-gelenk" iconcolor="drc-gruen-300" color="drc-gruen-300" :badge="summe_checked_gelenke">
               <gelenke />
            </tab-icon>
            <tab-icon id="augen" title="Augen" textcolor="black" icon="svg-augenuntersuchung" iconcolor="drc-blau-300" color="drc-blau-300" :badge="summe_checked_augen">
               <augen />
            </tab-icon>
            <tab-icon id="zahnstatus" title="Zahnstatus" textcolor="black" icon="svg-zahn" iconcolor="drc-orange" color="drc-orange" :badge="summe_checked_zahnstatus">
               <zahnstatus />
            </tab-icon>
            <tab-icon id="gentests" title="Genetik" icon="svg-gentest" iconcolor="drc-gruen-100" color="drc-gruen-100" :badge="summe_checked_gentests">
               <genetik />
            </tab-icon>
            <tab-icon id="pruefungen" title="Prüfungen" textcolor="black" icon="svg-das-leistungswesen" iconcolor="drc-gruen-500" color="drc-gruen-500" :badge="summe_checked_pruefungen">
               <pruefungen />
            </tab-icon>
            <tab-icon id="titels" title="Titel" textcolor="black" icon="svg-award" iconcolor="drc-braun-mittel" color="drc-braun-mittel" :badge="summe_checked_titels">
               <titel />
            </tab-icon>
         </tabs-icon>
      </div>
      <template v-else>
         <b-expand-bar-suche label="Auflagen" textcolor="black" icon="svg-zucht" iconcolor="drc-marker-rot" color="drc-marker-rot" v-model:open="expands.bar.auflagen" badge="0">
            <auflagen />
         </b-expand-bar-suche>
         <b-expand-bar-suche label="Gelenke" textcolor="black" icon="svg-gelenk" iconcolor="drc-gruen-300" color="drc-gruen-300" v-model:open="expands.bar.gelenke" :badge="summe_checked_gelenke">
            <gelenke />
         </b-expand-bar-suche>
         <b-expand-bar-suche label="Augen" textcolor="black" icon="svg-augenuntersuchung" iconcolor="drc-blau-300" color="drc-blau-300" v-model:open="expands.bar.augen" :badge="summe_checked_augen">
            <augen />
         </b-expand-bar-suche>
         <b-expand-bar-suche label="Zähne / Gebiss" textcolor="black" icon="svg-zahn" iconcolor="drc-orange" color="drc-orange" v-model:open="expands.bar.zahnstatus" :badge="summe_checked_zahnstatus">
            <zahnstatus />
         </b-expand-bar-suche>
         <b-expand-bar-suche label="Genetik" textcolor="black" icon="svg-gentest" iconcolor="drc-gruen-100" color="drc-gruen-100" v-model:open="expands.bar.genetik" :badge="summe_checked_gentests">
            <genetik />
         </b-expand-bar-suche>
         <b-expand-bar-suche label="Prüfungen" textcolor="black" icon="svg-gentest" iconcolor="drc-gruen-300" color="drc-gruen-300" v-model:open="expands.bar.pruefungen" :badge="summe_checked_pruefungen">
            <pruefungen />
         </b-expand-bar-suche>
         <b-expand-bar-suche label="Titel" textcolor="black" icon="svg-award" iconcolor="drc-braun-mittel" color="drc-braun-mittel" v-model:open="expands.bar.titel" :badge="summe_checked_titels">
            <titel />
         </b-expand-bar-suche>
      </template>
</template>
<script setup>
import { ref, reactive, computed, onMounted, watch } from "vue";
import _ from "lodash";
import axios from "axios";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";

import Tab from "@/components/global/tabs/Tab.vue";
import TabsDynamic from "@/components/global/tabs/TabsDynamic.vue";
import TabsIcon from "@/components/global/tabs/TabsIcon.vue";
import TabIcon from "@/components/global/tabs/TabIcon.vue";
import BInput from "@/components/global/inputs/BInput.vue";
import BSelectMulti from "@/components/global/select/BSelectMulti.vue";
import BNumber from "@/components/global/inputs/BNumber.vue";
import BTrenner from "@/components/global/BTrenner.vue";
import BTooltip from "@/components/global/BTooltip.vue";
import BTextarea from "@/components/global/inputs/BTextarea.vue";
import BExpandBarHund from "@/components/global/bars/BExpandBarHund.vue";
import BExpandBarSuche from "@/components/global/bars/BExpandBarSuche.vue";
import BExpandBarSucheNeutral from "@/components/global/bars/BExpandBarSucheNeutral.vue";
import BExpandPanel from "@/components/global/panels/BExpandPanel.vue";
import BCheckbox from "@/components/global/checkboxes/BCheckbox.vue";
import BCheckboxClear from "@/components/global/checkboxes/BCheckboxClear.vue";
import BRadiobuttons from "@/components/global/radios/BRadiobuttons.vue";
import BHinweisfeld from "@/components/global/BHinweisfeld.vue";
import BInfopanel from "@/components/global/panels/BInfopanel.vue";
import BDatepicker3 from "@/components/global/datepickers/BDatepicker3.vue";
import BButton from "@/components/global/buttons/BButton.vue";
import BButtonSolo from "@/components/global/buttons/BButtonSolo.vue";
import BThementrenner from "@/components/global/BThementrenner.vue";
import BLabel from "@/components/global/BLabel.vue";
import BRadiobutton from "@/components/global/radios/BRadiobutton.vue";
import BAhnentafelOverlaySingle from "@/components/ahnentafel/BAhnentafelOverlaySingle.vue";
import BLoader from "@/components/global/BLoader.vue";
import BLightbox from "@/components/global/bslider/BLightbox.vue";
import BRadiobuttonsZahn from "@/components/global/radios/BRadiobuttonsZahn.vue";

import BExpandBarSuchmodulGentest from "@/components/global/bars/BExpandBarSuchmodulGentest.vue";
import BExpandBarSuchmodulCheckboxes from "@/components/global/bars/BExpandBarSuchmodulCheckboxes.vue";
import BExpandBarSuchmodul from "@/components/global/bars/BExpandBarSuchmodul.vue";


import Favorite from "@/components/hunde/suche/Favorite.vue";
import Auflagen from "@/components/hunde/suche/Auflagen.vue";
import Augen from "@/components/hunde/suche/Augen.vue";
import Zahnstatus from "@/components/hunde/suche/Zahnstatus.vue";
import Gelenke from "@/components/hunde/suche/Gelenke.vue";
import Genetik from "@/components/hunde/suche/Genetik.vue";
import Pruefungen from "@/components/hunde/suche/Pruefungen.vue";
import Titel from "@/components/hunde/suche/Titel.vue";


onMounted(() => {
});

const storagepath = import.meta.env.VITE_APP_STORAGE;

const open = ref(false);
const router = useRouter();
const route = useRoute();
const { options } = useGlobalStore();
const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

// sucheStore.$reset()
const {
   filterHunde,
   expands,
   summe_checked_gelenke,
   summe_checked_augen,
   summe_checked_zahnstatus,
   summe_checked_gentests,
   summe_checked_pruefungen,
   summe_checked_titels,
   summe_checked_auflagen,
   summe_checked_sonstige,

} = storeToRefs(sucheHundeStore);

const hundetabs = ref(null);

const opthunde = ref([
   { id: 1, name: "Alle Zuchthunde" },
   { id: 2, name: "DRC Zuchthündinnen" },
   { id: 3, name: "DRC Deckrüden" },
   { id: 4, name: "Freigestellte Deckrüden" }
]);
const optgeschlecht = ref([
   { id: 0, name: "Hündinnen und Rüden" },
   { id: 1, name: "Hündinnen" },
   { id: 2, name: "Rüden" },
]);

watch(
   () => sucheHundeStore.filterHunde.rasse_id,
   (new_id) => {
      let index = options.farben[new_id].findIndex(
         (farbe) => farbe == filterHunde.value.fellfarbe.id
      );
      if (index === -1) {
         filterHunde.value.fellfarbe = { id: 0, name: "Alle Farben" };
      }
   }
);

</script>
<style></style>
