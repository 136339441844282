// store.js
import { ref, reactive } from 'vue'

export const ahnen = reactive([])
export const at_history = reactive([])
export const history_pointer = ref(0)

export const ls_details = reactive([
   true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true
]
)

export const ls_open = reactive([
   true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true
]
)

export const admin_open = reactive({
   0: false,
   1: false,
   2: false,
   3: false,
   4: false,
   5: false,
   6: false,
   7: false,
   8: false,
   9: false,
   10: false,
   11: false,
   12: false,
   13: false,
   14: false,
   15: false,
   16: false,
   17: false,
   18: false,
   19: false,
   20: false,
   21: false,
   22: false,
   23: false,
   24: false,
   25: false,
   26: false,
   27: false,
   28: false,
   29: false,
   30: false,
   31: false,
   32: false,
})

export const admin_details = reactive({
   0: false,
   1: false,
   2: false,
   3: false,
   4: false,
   5: false,
   6: false,
   7: false,
   8: false,
   9: false,
   10: false,
   11: false,
   12: false,
   13: false,
   14: false,
   15: false,
   16: false,
   17: false,
   18: false,
   19: false,
   20: false,
   21: false,
   22: false,
   23: false,
   24: false,
   25: false,
   26: false,
   27: false,
   28: false,
   29: false,
   30: false,
   31: false,
   32: false,
})

   

