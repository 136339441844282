<template>
 <div class="flex flex-col mx-5 desktop:mx-10 bg-white p-4">
               <div class="flex items-center h-10 text-left text-white justify-items bg-drc-grau-300" :class="{
              'bg-drc-ruede-mittel': hund.geschlecht.id == 2,
              'bg-drc-huendin-mittel': hund.geschlecht.id == 1,
            }">
                  <svg-vater class="w-10 p-1" v-if="hund.geschlecht.id == 2" />
                  <svg-mutter class="w-10 p-1" v-if="hund.geschlecht.id == 1" />
                  <svg-h-r class="w-10 p-1" v-if="hund.geschlecht.id != 1 && hund.geschlecht.id != 2" /> {{ hund.rasse.name }} <div class="flex-grow"></div>
                  <div class="border-l-2 border-white" :class="{
                'bg-drc-ruede-dunkel': hund.geschlecht.id == 2,
                'bg-drc-huendin-dunkel': hund.geschlecht.id == 1,
              }">
                     <svg-welpen class="w-10 p-1" />
                  </div>
                  <div class="border-l-2 border-white hover:cursor-pointer" :class="{
                'bg-drc-ruede-dunkel': hund.geschlecht.id == 2,
                'bg-drc-huendin-dunkel': hund.geschlecht.id == 1,
              }" @click="
                showAhnentafel = true;
                id_ahnentafel = hund.id;
              ">
                     <svg-ahnentafel class="w-10 p-1" />
                  </div>
                  <div class="border-l-2 border-white" :class="{
                'bg-drc-ruede-dunkel': hund.geschlecht.id == 2,
                'bg-drc-huendin-dunkel': hund.geschlecht.id == 1,
              }" @click="router.push({ name: 'hundedit', params: { id: hund.id } })">
                     <svg-hundeakte class="w-10 p-1 hover:cursor-pointer" />
                  </div>
               </div>
               <div class="grid w-full my-2 gap-2 !grid-cols-1" :class="{
              '!grid-cols-2': contentsize >= 800 && contentsize < 1200,
              '!grid-cols-3': contentsize >= 1200 && contentsize < 1600,
              '!grid-cols-hund4bild': contentsize >= 1600,
            }">
                  <div v-if="!(hund.images.length == 0 && contentsize < 800)" class="col-start-1 row-start-1 bg-drc-grau-600 max-w-58" :class="{ '!row-span-2': contentsize >= 1200 && contentsize < 1600 }">
                     <div class="flex flex-col flex-1 bg-drc-grau-600 min-w-45">
                        <div class="relative flex items-center justify-center w-full h-full p-3" :class="{ 'p-10': hund.images.length == 0 }">
                           <img v-if="hund.images.length > 0" :src="storagepath + hund.images[0].path" :alt="hund.name" class="object-cover" />
                           <svg class="text-white" v-else xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 20">
                              <path d="M25.7,3.6c-0.5-0.1-0.9-0.3-1.4-0.3c-0.7-0.1-1.3-0.2-2-0.3c-0.6-0.1-1.2-0.4-1.5-1c0-0.1-0.1-0.1-0.1-0.2
         	c-0.6-1-1.7-1.6-2.8-1.6c-1.7-0.1-3.4,0.4-4.8,1.3c-1.1,0.6-1.6,1.8-1.3,3c0.1,0.3,0.3,0.6,0.5,0.9c1,1.2,2.1,2.3,3.3,3.3
         	c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.3,0.7,0.1,0.8-0.3c0.1-0.3,0.2-0.7,0.2-1.1c0.1-1.7-0.2-3.4-0.8-5C16.1,2.3,16,2.2,15.9,2
         	c-0.1-0.3,0-0.7,0.3-0.9c0.3-0.2,0.7-0.1,0.9,0.2c0.1,0.2,0.2,0.4,0.3,0.6c0.6,1.3,0.8,2.8,0.8,4.2c0,0.7,0,1.5-0.1,2.2
         	c-0.1,0.7-0.5,1.4-1.1,1.7c-0.4,0.3-1,0.3-1.5,0.1c-0.5-0.3-1-0.6-1.4-1c-1-0.9-1.9-1.9-2.8-2.9c-0.6-0.6-0.9-1.3-1-2.1
         	c-0.5,0.7-1,1.4-1.6,2c-0.7,0.8-2.1,2-2.1,2c3,9.5,8.9,11.4,8.9,11.4c0.4-1.2,1.1-3.3,1.5-4.5c0.6-1.8,1.2-3.5,1.9-5.3
         	c0.1-0.2,0.4-0.4,0.6-0.4c0.7-0.1,1.4-0.2,2.1-0.3C22.5,9,23.3,8.8,24,8.5c0.9-0.5,1.6-1.3,1.8-2.4c0.2-0.6,0.2-1.3,0.4-1.9
         	C26.2,4,26,3.7,25.7,3.6C25.7,3.6,25.7,3.6,25.7,3.6z" />
                           </svg>
                           <button v-if="hund.images.length > 0" @click="
                      startSlide = 0;
                      lightboxHund = hund;
                      showLightbox = true;
                    " class="absolute z-10 p-0 bg-white rounded-full opacity-75 bottom-4 right-4">
                              <svg-spaltenansicht class="w-10 h-10 text-drc-grau-200" />
                           </button>
                        </div>
                        <div v-if="hund.images[0]" class="flex pb-4 text-left min-w-45">
                           <div class="w-10 px-1 mx-1 text-drc-grau-200">
                              <svg-info />
                           </div>
                           <div class="flex flex-col pt-1 pr-2 text-sm text-left text-drc-grau-200">
                              <span class="font-bold">Bildunterschrift</span>
                              <span v-if="hund.images[0].bu" class="text-sm"> {{ hund.images[0].bu }} </span>
                              <span v-else class="text-sm italic text-drc-grau-300">kein Eintrag </span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="bg-drc-grau-600 pb-4" :class="{ '!col-span-2': contentsize >= 1200 && contentsize < 1600 }">
                     <div class="flex text-left min-w-45">
                        <div class="w-10 p-1 mx-1 my-1 text-drc-grau-200">
                           <svg-hund />
                        </div>
                        <div class="flex flex-col pt-4 pr-2 text-base text-left text-drc-grau-200">
                           <span class="font-bold">{{ hund.name }}</span>
                           <span class="font-bold">ZB-Nr.: {{ hund.zuchtbuchnummer }}</span>
                           <div class="flex mt-2 text-sm">
                              <div class="w-24 font-bold">Geschlecht:</div> {{ hund.geschlecht.name }}
                           </div>
                           <div class="flex text-sm">
                              <div class="w-24 font-bold">Rasse:</div> {{ hund.rasse.name }}
                           </div>
                           <div v-if="hund.rasse_id != 4" class="flex text-sm mb-2">
                              <div class="w-24 font-bold">Fellfarbe:</div> {{ hund.farbe.name }}
                           </div>
                           <div v-if="hund.wurfdatum" class="flex text-sm">
                              <div class="w-24 font-bold">Wurfdatum:</div> {{ hund.wurfdatum }}
                           </div>
                           <div v-if="hund.wurfart" class="flex text-sm mb-2">
                              <div class="w-24 font-bold">Zucht-Art:</div> {{ hund.wurfart }}
                           </div>
                           <div v-if="hund.verstorben" class="flex text-sm mb-2">
                              <div class="w-24 font-bold">Verstorben:</div> {{ hund.verstorben }}
                           </div>
                           <div v-if="hund.zuchtbuchnummern.length > 1" class="flex font-bold text-sm mt-2"> Ehemalige Zuchtbuchnummern </div>
                           <div v-if="hund.zuchtbuchnummern.length > 1" class="flex text-sm mb-2">
                              <div class="w-24 font-bold">ZB-Nrn.:</div>
                              <div class="flex flex-col">
                                 <div v-for="(zuchtbuchnummer, index) in hund.zuchtbuchnummern.slice(
                          1,
                          hund.zuchtbuchnummern.length
                        )" :key="index"> {{ zuchtbuchnummer }} </div>
                              </div>
                           </div>
                           <div v-if="hund.gstb_nr" class="flex text-sm mb-2">
                              <div class="w-24 font-bold">Stammbuchnummer (JGHV):</div> {{ hund.gstb_nr }}
                           </div>
                           <div v-if="hund.drc_gstb_nr" class="flex text-sm mb-2">
                              <div class="w-24 font-bold">Stammbuchnummer (DRC):</div> {{ hund.drc_gstb_nr }}
                           </div>
                           <div v-if="hund.zuchthund" class="flex text-sm mb-2">
                              <div class="w-24 font-bold">Zuchtzulassung seit:</div> {{ hund.zzl_datum }}
                           </div>
                           <div v-if="hund.zuchttauglichkeit" class="flex text-sm mb-2">
                              <div class="w-24 font-bold">Zuchttauglichkeit:</div> {{ hund.zuchttauglichkeit }}
                           </div>
                           <div v-if="hund.auflagen.length" class="flex text-sm mt-5 mb-2">
                              <div class="w-24 font-bold">Zucht-Auflagen:</div>
                              <div v-for="(auflage, index) in hund.auflagen" :key="index"> {{ auflage }} </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="bg-drc-grau-600 pb-4">
                     <div class="flex text-left min-w-45">
                        <div class="w-10 p-1 mx-1 my-1 text-drc-grau-200">
                           <svg-meine-hunde />
                        </div>
                        <div class="flex flex-col pt-2 pr-2 text-base text-left text-drc-grau-200">
                           <div class="font-bold pt-2">Züchter</div>
                           <div class="flex flex-col my-2">
                              <div v-if="hund.zuechter_nachname != '' || hund.herkunftszuchtstaette_name != ''" class="text-sm">
                                 <div>{{ hund.zuechter_vorname }} {{ hund.zuechter_nachname }}</div>
                                 <div>{{ hund.herkunftszuchtstaette_name }}</div>
                                 <div>{{ hund.herkunftszuchtstaette_plz }} {{ hund.herkunftszuchtstaette_ort }}</div>
                              </div>
                              <div v-else class="text-sm italic">unbekannt</div>
                           </div>
                           <div class="font-bold pt-2">Eltern</div>
                           <div class="flex text-sm mt-2">
                              <div class="w-24 font-bold">Mutter:</div> {{ hund.mutter_name }}
                           </div>
                           <div class="flex text-sm mb-2">
                              <div class="w-24 font-bold">ZB-Nr.:</div> {{ hund.mutter_zuchtbuchnummer }}
                           </div>
                           <div class="flex text-sm mt-2">
                              <div class="w-24 font-bold">Vater:</div> {{ hund.vater_name }}
                           </div>
                           <div class="flex text-sm mb-2">
                              <div class="w-24 font-bold">ZB-Nr.:</div> {{ hund.vater_zuchtbuchnummer }}
                           </div>
                           <div class="flex text-sm mb-2">
                              <div v-if="hund.abstammungsnachweis" class="w-24 font-bold"> (Abstammungsnachweis) </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="bg-drc-grau-600 pb-4">
                     <div class="flex text-left min-w-45">
                        <div class="w-10 p-1 mx-1 my-1 text-drc-grau-200">
                           <svg-personen />
                        </div>
                        <div class="flex flex-col pt-2 pr-2 text-base text-left text-drc-grau-200">
                           <div class="font-bold pt-2">Eigentümer</div>
                           <template v-if="hund.eigentuemer.length">
                              <div v-for="(eigentuemer, index) in hund.eigentuemer" :key="index" class="flex flex-col my-2">
                                 <div class="text-sm"> {{ eigentuemer.vorname }} {{ eigentuemer.nachname }} </div>
                                 <div class="text-sm"> {{ eigentuemer.postleitzahl }} {{ eigentuemer.ort }} </div>
                                 <template v-if="hund.zuchthund">
                                    <div v-if="eigentuemer.telefon_1" class="text-sm"> Telefon: {{ eigentuemer.telefon_1 }} </div>
                                    <div v-if="eigentuemer.telefon_2" class="text-sm"> Telefon: {{ eigentuemer.telefon_2 }} </div>
                                    <div v-if="eigentuemer.email_1" class="text-sm"> E-Mail: <a :href="`mailto:` + eigentuemer.email_1"> {{ eigentuemer.email_1 }}</a>
                                    </div>
                                    <div v-if="eigentuemer.email_2" class="text-sm"> E-Mail: <a :href="`mailto:` + eigentuemer.email_2"> {{ eigentuemer.email_2 }}</a>
                                    </div>
                                    <div v-if="eigentuemer.website_1" class="text-sm">
                                       <a :href="`https://` + eigentuemer.website_1" target="_blank"> {{ eigentuemer.website_1 }}</a>
                                    </div>
                                 </template>
                              </div>
                           </template>
                           <template v-else>
                              <div class="flex flex-col my-2">
                                 <div class="text-sm italic">unbekannt</div>
                                 <div class="text-sm"></div>
                              </div>
                           </template>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="bg-drc-grau-600 pb-4">
                  <div class="flex text-left min-w-45">
                     <div class="w-10 p-2 text-drc-grau-200">
                        <svg-gesundheit class="w-10 h-10" />
                     </div>
                     <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                        <span class="font-bold">Gesundheit</span>
                        <template v-if="hund.hdeduntersuchung">
                           <span class="mt-2 font-bold">Gelenke</span>
                           <span v-if="hund.hd_score">HD: {{ hund.hd_score }}</span>
                           <span v-if="hund.hd_l_score">HD (links): {{ hund.hd_l_score }}</span>
                           <span v-if="hund.hd_r_score">HD (rechts): {{ hund.hd_r_score }}</span>
                           <span v-if="hund.ed_score">ED: {{ hund.ed_score }} </span>
                           <span v-if="hund.ed_l_score">ED (links): {{ hund.ed_l_score }} </span>
                           <span v-if="hund.ed_r_score">ED (rechts): {{ hund.ed_r_score }} </span>
                        </template>
                        <template v-if="hund.zahnstatus">
                           <span class="mt-2 font-bold">Zahnstatus</span>
                           <span v-if="hund.zahnstatus_gebiss">Gebiss: {{ hund.zahnstatus_gebiss }} </span>
                           <span v-if="
                      hund.zahnstatus_befund_schema || hund.zahnstatus_befund_anmerkungen
                    ">Zahnstatus: {{ hund.zahnstatus_befund_schema }} {{ hund.zahnstatus_befund_anmerkungen }}</span>
                        </template>
                        <template v-if="hund.augenuntersuchung">
                           <span class="mt-2 font-bold">Augenuntersuchung</span>
                           <span v-if="hund.au_datum">Datum: {{ hund.au_datum }}</span>
                           <span v-if="hund.au_rd">RD: {{ hund.au_rd }}</span>
                           <span v-if="hund.au_pra_rd">PRA-RD: {{ hund.au_pra_rd }}</span>
                           <span v-if="hund.au_katarakt_kon">Katarakt (kongential): {{ hund.au_katarakt_kon }}</span>
                           <span v-if="hund.au_katarakt_nonkon">Katarakt (nicht kongential): {{ hund.au_katarakt_nonkon }} <span v-if="hund.au_katarakt_nonkon_details">({{ hund.au_katarakt_nonkon_details }})</span></span>
                        </template>
                        <template v-if="hund.goniountersuchung">
                           <span class="mt-2 font-bold">Goniountersuchung</span>
                        </template>
                        <template v-if="gentests">
                           <span class="mt-2 font-bold">Gentests</span>
                           <span>{{ gentests }}</span>
                        </template>
                     </div>
                  </div>
               </div>
               <div class="mt-2 bg-drc-grau-600 pb-4">
                  <div class="my-1">
                     <div class="flex text-left min-w-45">
                        <div class="w-10 p-2 text-drc-grau-200">
                           <svg-award class="w-10 h-10" />
                        </div>
                        <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                           <span class="font-bold mb-4">Titel</span>
                           <span v-for="titel in hund.titel" class="text-sm">{{ titel.name }}<br />
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="mt-2 bg-drc-grau-600 pb-4">
                  <div class="my-1">
                     <div class="flex text-left min-w-45">
                        <div class="w-10 p-2 text-drc-grau-200">
                           <svg-das-leistungswesen class="w-10 h-10" />
                        </div>
                        <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                           <span class="font-bold mb-4">Prüfungen</span>
                           <span v-for="pruefung in hund.pruefungstitel" class="text-sm">{{ pruefung.name }}<br />
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div v-if="hund.wesenstest" class="mt-2 bg-drc-grau-600 pb-4">
                  <div class="my-1">
                     <div class="flex text-left min-w-45 max-w-200">
                        <div class="w-10 p-2 text-drc-grau-200">
                           <svg-das-leistungswesen class="w-10 h-10" />
                        </div>
                        <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                           <span class="font-bold">Wesenstest</span>
                           <!-- <div class="my-2">{{ hund.wesenstest[0].status }}</div> -->
                           <div v-if="hund.wesenstest_richter" class="my-2">
                              <b>Richter:</b> {{ hund.wesenstest_richter }}
                           </div>
                           <template v-for="kommentar in hund.wesenstest_zusammenfassung" :key="kommentar.titel">
                              <div v-if="kommentar.text != ''" class="my-2">
                                 <b>{{ kommentar.titel }}:</b><br />{{ kommentar.text }}
                              </div>
                           </template>
                        </div>
                     </div>
                  </div>
               </div>
               <div v-if="hund.formwert" class="mt-2 bg-drc-grau-600 pb-4">
                  <div class="my-1">
                     <div class="flex text-left min-w-45 max-w-200">
                        <div class="w-10 p-2 text-drc-grau-200">
                           <svg-das-leistungswesen class="w-10 h-10" />
                        </div>
                        <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                           <span class="font-bold">Formwert</span>
                           <div v-if="hund.formwert_richter" class="my-2">
                              <b>Richter:</b> {{ hund.formwert_richter }}
                           </div>
                           <div v-if="hund.formwert_formwert" class="my-2">
                              <b>Formwert:</b> {{ hund.formwert_formwert }}
                           </div>
                           <div v-if="hund.formwert_risthoehe" class="my-2">
                              <b>Risthöhe:</b> {{ hund.formwert_risthoehe }}
                           </div>
                           <div v-if="hund.formwert_beurteilung" class="my-2">
                              <b>Beurteilung:</b><br />{{ hund.formwert_beurteilung }}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="mt-2 bg-drc-grau-600 pb-4">
                  <div class="my-1">
                     <div class="flex text-left min-w-45 max-w-200">
                        <div class="w-10 p-2 text-drc-grau-200">
                           <svg-das-leistungswesen class="w-10 h-10" />
                        </div>
                        <div class="flex flex-col p-5 text-sm text-left text-drc-grau-200">
                           <span class="font-bold">Zucht</span>
                           <!-- <div class="my-2">{{ hund.wesenstest[0].status }}</div> -->
                        </div>
                     </div>
                  </div>
               </div>
               <div class="grid w-full my-2 gap-2 !grid-cols-1" :class="{
              '!grid-cols-2': contentsize >= 800 && contentsize < 1200,
              '!grid-cols-3': contentsize >= 1200 && contentsize < 1600,
              '!grid-cols-hund4bild': contentsize >= 1600,
            }">
                  <div v-for="(image, index) in hund.images" :key="index" class="bg-drc-grau-600 max-w-58" :class="{ '!row-span-2': contentsize >= 1200 && contentsize < 1600 }">
                     <div class="flex flex-col flex-1 bg-drc-grau-600 min-w-45">
                        <div class="relative flex items-center justify-center w-full h-full p-3">
                           <img :src="storagepath + image.path" :alt="hund.name" class="object-cover" />
                           <button @click="
                      startSlide = index;
                      lightboxHund = hund;
                      showLightbox = true;
                    " class="absolute z-10 p-0 bg-white rounded-full opacity-75 bottom-4 right-4">
                              <svg-spaltenansicht class="w-10 h-10 text-drc-grau-200" />
                           </button>
                        </div>
                        <div class="flex pb-4 text-left min-w-45">
                           <div class="w-10 px-1 mx-1 text-drc-grau-200">
                              <svg-info />
                           </div>
                           <div class="flex flex-col pt-1 pr-2 text-sm text-left text-drc-grau-200">
                              <span class="font-bold">Bildunterschrift</span>
                              <span v-if="image.bu" class="text-sm"> {{ image.bu }} </span>
                              <span v-else class="text-sm italic text-drc-grau-300">kein Eintrag </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
</template>
<script setup>
import { ref, computed } from "vue";
import _ from "lodash";
import axios from "axios";
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";

import BCheckbox from "@/components/global/checkboxes/BCheckbox.vue";
import BCheckboxClear from "@/components/global/checkboxes/BCheckboxClear.vue";
import BInput from "@/components/global/inputs/BInput.vue";
import BSelectMulti from "@/components/global/select/BSelectMulti.vue";
import BNumber from "@/components/global/inputs/BNumber.vue";
import BRadiobuttons from "@/components/global/radios/BRadiobuttons.vue";
import BHinweisfeld from "@/components/global/BHinweisfeld.vue";
import BInfopanel from "@/components/global/panels/BInfopanel.vue";
import BDatepicker3 from "@/components/global/datepickers/BDatepicker3.vue";
import BThementrenner from "@/components/global/BThementrenner.vue";
import BLabel from "@/components/global/BLabel.vue";
import BRadiobutton from "@/components/global/radios/BRadiobutton.vue";


const { options } = useGlobalStore();
const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

const props = defineProps({
  hund: Object,

});

const {
   augen, 
   summe_checked_augen,  
} = storeToRefs(sucheHundeStore);


</script>
<style></style>
