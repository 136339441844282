<template>
  <div class="px-2">
   <div class="flex justify-start flex-wrap bg-drc-gruen-300 bg-opacity-0 pt-2">
     
         <b-button-setting label="Alle öffnen" icon="svg-plus" @buttonclick="openAll()" />
         <b-button-setting label="Alle schliessen" icon="svg-minus" @buttonclick="closeAll()" />
         <b-button-setting label="Alle auswählen" icon="svg-checkbox-checked" @buttonclick="setAll()" />
         <b-button-setting label="Alle abwählen" icon="svg-checkbox-unchecked" @buttonclick="unsetAll()" />
      </div>
   </div>
   <div class="">
   <b-expand-bar-suchmodul :daten="gelenke.hd_scoring.meta" v-model:open="expands.gelenke.hd_scoring" color="drc-gruen-300">
      <b-label label="DRC / FCI - Scoring" />
      <div class="flex flex-wrap px-10">
     
         <b-checkbox-clear class="w-32" id="hd_drcfci_a" v-model:value="gelenke.hd_scoring.drcfci.a" label="A (1/2)" />
         <b-checkbox-clear class="w-32" id="hd_drcfci_b" v-model:value="gelenke.hd_scoring.drcfci.b" label="B (1/2)" />
         <b-checkbox-clear class="w-32" id="hd_drcfci_c" v-model:value="gelenke.hd_scoring.drcfci.c" label="C (1/2)" />
         <b-checkbox-clear class="w-32" id="hd_drcfci_d" v-model:value="gelenke.hd_scoring.drcfci.d" label="D (1/2)" />
         <b-checkbox-clear class="w-32" id="hd_drcfci_e" v-model:value="gelenke.hd_scoring.drcfci.e" label="E (1/2)" />
      </div>
      <br />
      <b-label label="Weitere Scorings" />
      <b-checkbox-clear class="px-10" id="hd_ofa_a" v-model:value="gelenke.hd_scoring.ofa.keine" label="OFA - Orthopedic Foundation for Animals nicht berücksichtigen" />
      <b-checkbox-clear class="px-10" id="hd_hs_0" v-model:value="gelenke.hd_scoring.hs.keine" label="HS - HipScore nicht berücksichtigen" />
      <b-checkbox-clear class="px-10" id="hd_ch_0" v-model:value="gelenke.hd_scoring.ch.keine" label="CH - Schweizer Scoring nach Flückinger nicht berücksichtigen" />
      <b-checkbox-clear class="px-10" id="hd_nl_0" v-model:value="gelenke.hd_scoring.nl.keine" label="NL - Nicht berücksichtigen" />
      <b-checkbox-clear class="px-10" id="hd_sw_0" v-model:value="gelenke.hd_scoring.sw.keine" label="SW - Nicht berücksichtigen" />
      <b-checkbox-clear class="px-10" id="hd_fi_0" v-model:value="gelenke.hd_scoring.fi.keine" label="FI - Nicht berücksichtigen" />
      <b-checkbox-clear class="px-10" id="hd_sv_0" v-model:value="gelenke.hd_scoring.sv.keine" label="SV - Nicht berücksichtigen" />
   </b-expand-bar-suchmodul>
   <b-expand-bar-suchmodul :daten="gelenke.ed_scoring.meta" v-model:open="expands.gelenke.ed_scoring" color="drc-gruen-300">
      <b-label label="IEWG - Scoring (DRC/FCI)" />
      <div class="flex flex-wrap px-10">
         <b-checkbox-clear class="w-32" id="ed_drc_frei" v-model:value="gelenke.ed_scoring.drc.frei" label="Grad 0 / frei" />
         <b-checkbox-clear class="w-40" id="ed_drc_grenzfall" v-model:value="gelenke.ed_scoring.drc.grenzfall" label="Grenzfall (DRC)" />
         <b-checkbox-clear class="w-32" id="ed_drc_grad1" v-model:value="gelenke.ed_scoring.drc.grad1" label="Grad I / 1" />
         <b-checkbox-clear class="w-32" id="ed_drc_grad2" v-model:value="gelenke.ed_scoring.drc.grad2" label="Grad II / 2" />
         <b-checkbox-clear class="w-32" id="ed_drc_grad3" v-model:value="gelenke.ed_scoring.drc.grad3" label="Grad III / 3" />
      </div>
      <div class="px-10 text-xs italic text-drc-grau-300 text-left ">International Elbow working Group</div>
      <br />
      <br />
      <b-label label="Weitere Scorings" />
      <b-checkbox-clear class="px-10" id="hd_ofe" v-model:value="gelenke.hd_scoring.ofa.keine" label="OFE - nicht berücksichtigen" />
      <b-checkbox-clear class="px-10" id="hd_lang" v-model:value="gelenke.hd_scoring.hs.keine" label="LANG - nicht berücksichtigen" />
   </b-expand-bar-suchmodul>
</div>
</template>
<script setup>
import { ref, computed } from "vue";
import _ from "lodash";
import axios from "axios";
import { useGlobalStore } from "@/stores/global";
import { useSucheHundeStore } from "@/stores/suche_hund";
import { useLayoutStore } from "@/stores/layout";
import { storeToRefs } from "pinia";


import BCheckbox from "@/components/global/checkboxes/BCheckbox.vue";
import BCheckboxClear from "@/components/global/checkboxes/BCheckboxClear.vue";
import BHinweisfeld from "@/components/global/BHinweisfeld.vue";
import BInfopanel from "@/components/global/panels/BInfopanel.vue";
import BThementrenner from "@/components/global/BThementrenner.vue";
import BLabel from "@/components/global/BLabel.vue";

import BExpandBarSuchmodul from "@/components/global/bars/BExpandBarSuchmodul.vue";
import BButtonSetting from "@/components/global/buttons/BButtonSetting.vue";


const sucheHundeStore = useSucheHundeStore();
const layoutStore = useLayoutStore();
const { contentsize } = storeToRefs(layoutStore);

const {
   gelenke,
   expands
} = storeToRefs(sucheHundeStore);

function openAll() {
   expands.value.gelenke.hd_scoring = true;
   expands.value.gelenke.ed_scoring = true;
}

function closeAll() {
   expands.value.gelenke.hd_scoring = false;
   expands.value.gelenke.ed_scoring = false;
}

function setAll() {
   gelenke.value.hd_scoring.drcfci.a = true;
   gelenke.value.hd_scoring.drcfci.b = true;
   gelenke.value.hd_scoring.drcfci.c = true;
   gelenke.value.hd_scoring.drcfci.d = true;
   gelenke.value.hd_scoring.drcfci.e = true;
   gelenke.value.hd_scoring.ofa.keine = true;
   gelenke.value.hd_scoring.hs.keine = true;
   gelenke.value.hd_scoring.ch.keine = true;
   gelenke.value.hd_scoring.nl.keine = true;
   gelenke.value.hd_scoring.sw.keine = true;
   gelenke.value.hd_scoring.fi.keine = true;
   gelenke.value.hd_scoring.sv.keine = true;
   gelenke.value.ed_scoring.drc.frei = true;
   gelenke.value.ed_scoring.drc.grenzfall = true;
   gelenke.value.ed_scoring.drc.grad1 = true;
   gelenke.value.ed_scoring.drc.grad2 = true;
   gelenke.value.ed_scoring.drc.grad3 = true;
   gelenke.value.ed_scoring.ofel.keine = true;
   gelenke.value.ed_scoring.lang.keine = true;
}

function unsetAll() {
   gelenke.value.hd_scoring.drcfci.a = false;
   gelenke.value.hd_scoring.drcfci.b = false;
   gelenke.value.hd_scoring.drcfci.c = false;
   gelenke.value.hd_scoring.drcfci.d = false;
   gelenke.value.hd_scoring.drcfci.e = false;
   gelenke.value.hd_scoring.ofa.keine = false;
   gelenke.value.hd_scoring.hs.keine = false;
   gelenke.value.hd_scoring.ch.keine = false;
   gelenke.value.hd_scoring.nl.keine = false;
   gelenke.value.hd_scoring.sw.keine = false;
   gelenke.value.hd_scoring.fi.keine = false;
   gelenke.value.hd_scoring.sv.keine = false;
   gelenke.value.ed_scoring.drc.frei = false;
   gelenke.value.ed_scoring.drc.grenzfall = false;
   gelenke.value.ed_scoring.drc.grad1 = false;
   gelenke.value.ed_scoring.drc.grad2 = false;
   gelenke.value.ed_scoring.drc.grad3 = false;
   gelenke.value.ed_scoring.ofel.keine = false;
   gelenke.value.ed_scoring.lang.keine = false;
}
</script>
<style></style>
