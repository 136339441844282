<template>
  <div
    class="flex w-full px-5 mx-auto desktop:px-10 max-w-1280"
    :class="{
      'py-6 smartphone:flex-col smartphone:items-end desktop:!px-20 tablet:!px-12': !compact,
      '!justify-center': center,
      '!justfiy-start': left,
      '!justify-end': right,
    }"
  >
    <button
      class="relative flex items-center justify-center flex-none h-10 text-white transition-all duration-150 rounded-full min-w-50 min-w-56 focus:border-none focus:ring focus:ring-drc-gelb hover:bg-drc-orange transition-ease-in-out"
      :class="[
        {
          'ml-8 my-2': !compact,
          'pl-6': icon,
          'bg-drc-grau-400 pointer-events-none': disabled,
          'border-2': bordercolor,
        },
        'border-' + bordercolor,
        'bg-' + buttoncolor,
        'text-' + textcolor,
      ]"
      @click="$emit('buttonclick')"
    >
      <div
        v-if="icon"
        class="absolute -top-0 left-0 flex items-center justify-center w-10 h-10 p-1 ml-1 rounded-full"
        :class="[{ 'ml-1': !iconcolor }, 'text-' + iconcolor]"
      >
        <component
          :is="icon"
          class="w-12 h-12 rounded-full p-1 ring-0 -ml-4"
          :class="['ring-' + buttoncolor, 'text-' + textcolor, 'bg-' + iconcolor]"
        />
      </div>
      {{ label }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: [String, Boolean],
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    click: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconcolor: {
      type: String,
      default: "white",
    },
    buttoncolor: {
      type: String,
      default: "drc-gruen-300",
    },
    bordercolor: {
      type: String,
      default: "",
    },
    textcolor: {
      type: String,
      default: "white",
    },
    action: {
      type: String,
      default: "",
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
