import { defineStore } from 'pinia'

export const useLayoutStore = defineStore( {
   id: 'layout',
  state: () => ({
   drawer: true,
   userMenu: true,
   userMenuOpen: false,
   userMenuFixed: false,
   mainMenuMini: false,
   mainMenuSlide: false,
   mainMenuFixed: false,
   mainMenuOpen: false,
   subMenuFixed: false,
   subMenuOpen: false,
   currentmainmenu: '',
   currentsubmenu: '',
   windowsize: window.innerWidth,
   focusgroup: '',
   pos: { x: -1, y: -1 },
   focus: { x: -1, y: -1 },
  }),
  getters: {
   contentsize(state)  {


      var diff = 0

   if (!state.drawer && !state.mainMenuFixed && !state.subMenuFixed) diff = 0
   if (state.drawer && !state.mainMenuFixed && !state.subMenuFixed) diff = 10
   if (state.drawer && state.mainMenuFixed && !state.subMenuFixed) diff = 50
   if (state.drawer && !state.mainMenuFixed && state.subMenuFixed) diff = 60
   if (state.drawer && state.mainMenuFixed && state.subMenuFixed) diff = 100
     
   if (state.userMenu && !state.userMenuFixed) diff += 10
   if (state.userMenu && state.userMenuFixed) diff += 40

      return state.windowsize - diff*4
   }

  },
  actions: {
  },
  persist: true,
  // persist: {
  //    storage: sessionStorage,
  //    paths: ['someState'],
  //  },

})