<template>
   <div class="flex flex-col justify-start mx-5 desktop:mx-10">
        <!-- <div
    class="vue-number-input"
    :class="{
      'vue-number-input--inline': inline,
      'vue-number-input--center': center,
      'vue-number-input--controls': controls,
      [`vue-number-input--${size}`]: size,
    }"
  > -->
      <label
         class="block font-medium text-left text-drc-gruen-300"
         :id="id"
      >
         {{ label }}
         <b-tooltip :tooltip="tooltip"/>
      </label>
      <div class="flex">
         <button
            v-if="controls"
            class="flex items-center justify-center w-8 h-8 my-4 bg-white border border-r-0 rounded-l border-drc-grau-400 text-drc-grau-200 focus:z-100 focus:border-none hover:border-2 focus:bg-white hover:border-drc-gelb focus:ring focus:ring-drc-gelb"
            :class="{  'bg-drc-grau-400 text-drc-grau-400': disabled }"
            type="button"
            :disabled="disabled || readonly || !decreasable"
            @click.prevent="decrease"
        >                      
            <svg class="w-5 h-5 fill-current" viewBox="0 0 20 20">
               <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
            </svg>
         </button>

         <input
            class="w-full h-8 px-3 py-2 my-4 text-gray-700 border appearance-textfield border-drc-grau-400 focus:z-100 focus:border-none focus:bg-white focus:ring focus:ring-drc-gelb"
            :class="{'bg-drc-grau-400 text-black': disabled ,'text-center': center, 'border-l-0 border-r-0': controls, 'w-20': size == 'small', 'w-1/2': size == 'middle' }"
            ref="input"
            type="number"
            :name="name"
            :value="isNaN(value) ? '' : value"
            :min="min"
            :max="max"
            :step="step"
            :readonly="readonly || !inputtable"
            :disabled="disabled || (!decreasable && !increasable)"
            :placeholder="placeholder"
            autocomplete="off"
            @change="change"
            @paste="paste"
         />
         <button
            v-if="controls"
            class="flex items-center justify-center w-8 h-8 my-4 bg-white border border-l-0 rounded-r border-drc-grau-400 text-drc-grau-200 focus:z-100 focus:border-none hover:border-2 focus:bg-white hover:border-drc-gelb focus:ring focus:ring-drc-gelb"
            :class="{  'bg-drc-grau-400 text-drc-grau-400': disabled }"
            type="button"
            :disabled="disabled || readonly || !increasable"
            @click.prevent="increase"
         >   
            <svg class="w-6 h-6 fill-current" viewBox="0 0 20 20">
               <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
            </svg>
         </button>
         <span v-if="min >= 0 && max >= 0" class="h-8 mt-4 px-4 text-gray-400 text-xs">Bereich:<br/>[{{ min }} - {{ max }}]</span>
      </div>
      <template v-if="hinweise">
      <p v-for="hinweis in hinweise" class="mb-4 -mt-2 text-xs italic text-left" :class="{'text-red-500': (hinweis.type == 'error'), 'text-drc-gruen-300': (hinweis.type == 'warning'), 'text-black': (hinweis.type == 'info')}">{{ hinweis.text }}</p>
      </template>
   </div>
</template>


<script>
   import { defineComponent } from 'vue';
import BTooltip from "@/components/global/BTooltip.vue";

const isNaN = Number.isNaN || window.isNaN;
const REGEXP_NUMBER = /^-?(?:\d+|\d+\.\d+|\.\d+)(?:[eE][-+]?\d+)?$/;
const REGEXP_DECIMALS = /\.\d*(?:0|9){10}\d*$/;
const normalizeDecimalNumber = (value, times = 100000000000) => (
  REGEXP_DECIMALS.test(String(value)) ? (Math.round(value * times) / times) : value
);

export default defineComponent({
   components: { BTooltip },
   props: {
      label: {
         type: String,
         default: undefined
      },
      placeholder: {
         type: String,
         default: undefined
      },
      id: {
         type: [Number, String],
         default: undefined
      },
      hinweise: {
         type: [String,Array],
         default: undefined
      },
      disabled: {
         type: Boolean,
         default: false
      },
      tooltip:  {
         type: Object,
         default: {
            title: '',
            text: ''
         }
      },
      center:  {
         type: Boolean,
         default: false
      },
      controls: {
         type: Boolean,
         default: false
      },
      inputtable: {
         type: Boolean,
         default: true,
      },
      inline: {
         type: Boolean,
         default: false
      },
      max: {
         type: [Number, String],
         default: Infinity,
      },
      min: {
         type: [Number, String],
         default: -Infinity,
      },
      name: {
         type: String,
         default: undefined,
      },
      readonly: {
         type: Boolean,
         default: false
      },
      size: {
         type: [Number, String],
         default: undefined,
      },
      step: {
         type:[Number, String],
         default: 1,
      },
      modelValue: {
         type: [Number,String],
         default: NaN,
      },
   },
   data() {
      return {
          value: NaN,
      };
   },
   computed: {

    increasable() {
      return isNaN(this.value) || this.value < this.max;
    },
    decreasable() {
      return isNaN(this.value) || this.value > this.min;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (  !(isNaN(newValue) && typeof oldValue === 'undefined') && newValue !== this.value) {
          this.setValue(newValue);
        }
      },
    },
  },
  methods: {
    isNaN,
   
    change(event) {
      this.setValue(event.target.value);
    },
    paste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      if (clipboardData && !REGEXP_NUMBER.test(clipboardData.getData('text'))) {
        event.preventDefault();
      }
    },
    decrease() {
      if (this.decreasable) {
        let { value } = this;
        if (isNaN(value)) {
          value = 0;
        }
        this.setValue(normalizeDecimalNumber(value - this.step));
      }
    },
    increase() {
      if (this.increasable) {
        let { value } = this;
        if (isNaN(value)) {
          value = 0;
        }
        this.setValue(normalizeDecimalNumber(value + this.step));
      }
    },
    setValue(value) {
      const oldValue = this.value;
      let newValue = typeof value !== 'number' ? parseFloat(value) : value;
      if (!isNaN(newValue)) {
        if (this.min <= this.max) {
          newValue = Math.min(this.max, Math.max(this.min, newValue));
        }
        if (this.rounded) {
          newValue = Math.round(value);
        }
      }
      this.value = newValue;
      if (newValue === oldValue) {
        (this.$refs.input).value = String(newValue);
      }
      this.$emit('update:modelValue', newValue, oldValue);
      if (!isNaN(oldValue) && newValue != oldValue)  this.$emit('changed')
    },
  }
})
</script>

<style lang="scss" scoped>
input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
